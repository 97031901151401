import BillingNode from "./types/billing-node";

export enum BillingRecalculateRequestBillingContributionDiscountType {
  DISCOUNT_OPTIMIZATION = "DISCOUNT_OPTIMIZATION",
  DISCOUNT_BEING_LATE = "DISCOUNT_BEING_LATE",
  DISCOUNT_INCOMPATIBLE_CAR = "DISCOUNT_INCOMPATIBLE_CAR",
  DISCOUNT_OTHER = "DISCOUNT_OTHER",
  CONTRACT_CORRECTION_PERCENTAGE = "CONTRACT_CORRECTION_PERCENTAGE",
}

export type BillingRecalculateRequestBillingContributionContractCorrectionPercentage =
  {
    type: BillingRecalculateRequestBillingContributionDiscountType.CONTRACT_CORRECTION_PERCENTAGE | null;
    percentage: number;
  };

export type BillingRecalculateRequestBillingContributionDiscountOptimization = {
  type: BillingRecalculateRequestBillingContributionDiscountType.DISCOUNT_OPTIMIZATION | null;
  comment: string;
  amount: number;
};

export type BillingRecalculateRequestBillingContributionDiscountBeingLate = {
  type: BillingRecalculateRequestBillingContributionDiscountType.DISCOUNT_BEING_LATE | null;
  comment: string;
  amount: number;
};

export type BillingRecalculateRequestBillingContributionDiscountIncompatibleCar =
  {
    type: BillingRecalculateRequestBillingContributionDiscountType.DISCOUNT_INCOMPATIBLE_CAR | null;
    comment: string;
    amount: number;
  };

export type BillingRecalculateRequestBillingContributionDiscountOther = {
  type: BillingRecalculateRequestBillingContributionDiscountType.DISCOUNT_OTHER | null;
  comment: string;
  amount: number;
};

export type BillingRecalculateRequestBillingContribution =
  | BillingRecalculateRequestBillingContributionContractCorrectionPercentage
  | BillingRecalculateRequestBillingContributionDiscountOptimization
  | BillingRecalculateRequestBillingContributionDiscountBeingLate
  | BillingRecalculateRequestBillingContributionDiscountIncompatibleCar
  | BillingRecalculateRequestBillingContributionDiscountOther;

type BillingRecalculateRequest = {
  billing_nodes: BillingNode[];
  billing_contributions: BillingRecalculateRequestBillingContribution[];
  fuel_additive_rate: number;
  discount: number;
};

export default BillingRecalculateRequest;
