import billingsTranslationsHelper from "../../../../../../languages/billings-translations.helper";
import BillingsTaxiDriverContributionBonus, {
  BillingsTaxiDriverContributionBonusType,
} from "./types/billings-taxi-driver-contributions-bonus";
import BillingsTaxiDriverContributionsBonusSelectOption from "./types/billings-taxi-driver-contributions-bonus-select-option";

const getBonusNewContribution = (): BillingsTaxiDriverContributionBonus => {
  return {
    type: null,
    distance: null,
    rate: null,
    amount: null,
    comment: "",
  };
};

const getContributionTypeTranslation = (
  contributionType: BillingsTaxiDriverContributionBonusType | null
) => {
  const translations =
    billingsTranslationsHelper.getTaxiDriverContributionsTranslations().bonus
      .type;

  switch (contributionType) {
    case BillingsTaxiDriverContributionBonusType.BONUS_FAVORABLE_DISTANCE:
      return translations.favorableDistance;
    case BillingsTaxiDriverContributionBonusType.BONUS_OTHER:
      return translations.other;
  }
};

const getContributionTypeSelectOptions =
  (): BillingsTaxiDriverContributionsBonusSelectOption[] => {
    const translations =
      billingsTranslationsHelper.getTaxiDriverContributionsTranslations().bonus
        .type;

    return [
      {
        label: translations.favorableDistance,
        value: BillingsTaxiDriverContributionBonusType.BONUS_FAVORABLE_DISTANCE,
      },
      {
        label: translations.other,
        value: BillingsTaxiDriverContributionBonusType.BONUS_OTHER,
      },
    ];
  };

const billingsTaxiDriverContributionsBonusHelper = {
  getBonusNewContribution,
  getContributionTypeTranslation,
  getContributionTypeSelectOptions,
};

export default billingsTaxiDriverContributionsBonusHelper;
