import BillingsTaxiTaxiContributionBonus, {
  BillingsTaxiTaxiContributionBonusType,
} from "../../common/contributions/bonus/types/billings-taxi-taxi-contributions-bonus";
import BillingsTaxiTaxiContributionPenalty, {
  BillingsTaxiTaxiContributionPenaltyType,
} from "../../common/contributions/penalty/types/billings-taxi-taxi-contributions-penalty";
import BillingSaveRequest, {
  BillingSaveRequestBonusContribution,
  BillingSaveRequestContribution,
  BillingSaveRequestContributionType,
  BillingSaveRequestPenaltyContribution,
} from "../api/billing-save.request";
import BillingNodeApi from "../api/types/billing-node";
import BillingFormData, {
  BillingFormDataContributions,
} from "../types/billing-form.data";

const createSaveBillingRequest = (
  data: BillingFormData
): BillingSaveRequest => {
  const result: BillingSaveRequest = {
    billing_nodes: data.billingNodes.map((specificNode) => {
      const node: BillingNodeApi = {
        id: specificNode.id,
        node_id: specificNode.nodeId,
        order_id: specificNode.orderId,
        lat: specificNode.lat,
        lon: specificNode.lon,
        distance: specificNode.distance,
        description: specificNode.description,
        checkout_date: specificNode.checkoutDate?.toJSON() ?? null,
        checkout_event_id: specificNode.checkoutEventUuid,
        planned_date: specificNode.plannedDate?.toJSON() ?? null,
        halting_amount: specificNode.haltingAmount,
        halting_time: specificNode.haltingTime,
        allow_charge: specificNode.isHighwayAllowed,
        highway_charge: specificNode.highwayCharge,
        planned_distance: specificNode.plannedDistance,
        editable_coordinates: specificNode.isEditable,
      };
      return node;
    }),
    billing_contributions: createBillingContributions(
      data.billingContributions
    ),
    bonus_type: data.bonusType,
    penalty_type: data.penaltyType,
    bonus: data.bonus,
    penalty: data.penalty,
    billing_type: "TAXI_2_DRIVER",
  };
  return result;
};

const createBillingBonusContribution = (
  contribution: BillingsTaxiTaxiContributionBonus
): BillingSaveRequestBonusContribution | undefined => {
  switch (contribution.type) {
    case BillingsTaxiTaxiContributionBonusType.BONUS_FAVORABLE_DISTANCE:
      return {
        type: BillingSaveRequestContributionType.BONUS_FAVORABLE_DISTANCE,
        comment: contribution.comment ?? "",
        distance: contribution.distance ?? 0,
        rate: contribution.rate ?? 0,
      };
    case BillingsTaxiTaxiContributionBonusType.BONUS_OTHER:
      return {
        type: BillingSaveRequestContributionType.BONUS_OTHER,
        comment: contribution.comment ?? "",
        amount: contribution.amount ?? 0,
      };
    default:
      return undefined;
  }
};

const createBillingPenaltyContribution = (
  contribution: BillingsTaxiTaxiContributionPenalty
): BillingSaveRequestPenaltyContribution | undefined => {
  switch (contribution.type) {
    case BillingsTaxiTaxiContributionPenaltyType.PENALTY_BEING_LATE:
      return {
        type: BillingSaveRequestContributionType.PENALTY_BEING_LATE,
        comment: contribution.comment ?? "",
        amount: contribution.amount ?? 0,
      };
    case BillingsTaxiTaxiContributionPenaltyType.PENALTY_INCOMPATIBLE_CAR:
      return {
        type: BillingSaveRequestContributionType.PENALTY_INCOMPATIBLE_CAR,
        comment: contribution.comment ?? "",
        amount: contribution.amount ?? 0,
      };
    case BillingsTaxiTaxiContributionPenaltyType.PENALTY_OTHER:
      return {
        type: BillingSaveRequestContributionType.PENALTY_OTHER,
        comment: contribution.comment ?? "",
        amount: contribution.amount ?? 0,
      };
    case BillingsTaxiTaxiContributionPenaltyType.PENALTY_WRONG_ROUTE:
      return {
        type: BillingSaveRequestContributionType.PENALTY_WRONG_ROUTE,
        comment: contribution.comment ?? "",
        amount: contribution.amount ?? 0,
      };
  }
};

const createBillingContributions = (
  contributions: BillingFormDataContributions
): BillingSaveRequestContribution[] => {
  const bonusContributions: BillingSaveRequestBonusContribution[] =
    contributions.bonus.map(
      (contribution) => createBillingBonusContribution(contribution)!
    );

  const penaltyContributions: BillingSaveRequestPenaltyContribution[] =
    contributions.penalty.map(
      (contribution) => createBillingPenaltyContribution(contribution)!
    );

  return [...bonusContributions, ...penaltyContributions];
};

const billingSaveRequestFactory = {
  createSaveBillingRequest,
};

export default billingSaveRequestFactory;
