import { BillingsTaxiDriverContributionBonusType } from "../../common/contributions/bonus/types/billings-taxi-driver-contributions-bonus";
import { BillingsTaxiDriverContributionContractCorrectionType } from "../../common/contributions/contract-correction/types/billings-taxi-driver-contributions-contract-correction";
import { BillingsTaxiDriverContributionPenaltyType } from "../../common/contributions/penalty/types/billings-taxi-driver-contributions-penalty";
import BillingRecalculateRequest, {
  BillingRecalculateRequestBonusContributionType,
  BillingRecalculateRequestContractCorrectionContributionType,
  BillingRecalculateRequestContribution,
  BillingRecalculateRequestPenaltyContributionType,
} from "../api/billing-recalculate.request";
import BillingNodeApi from "../api/types/billing-node";
import BillingFormData, {
  BillingFormDataContributions,
} from "../types/billing-form.data";

const createRecalculateBillingBonusContributionType = (
  contributionType: BillingsTaxiDriverContributionBonusType
): BillingRecalculateRequestBonusContributionType => {
  switch (contributionType) {
    case BillingsTaxiDriverContributionBonusType.BONUS_FAVORABLE_DISTANCE:
      return BillingRecalculateRequestBonusContributionType.BONUS_FAVORABLE_DISTANCE;
    case BillingsTaxiDriverContributionBonusType.BONUS_OTHER:
      return BillingRecalculateRequestBonusContributionType.BONUS_OTHER;
  }
};

const createRecalculateBillingPenaltyContributionType = (
  contributionType: BillingsTaxiDriverContributionPenaltyType
): BillingRecalculateRequestPenaltyContributionType => {
  switch (contributionType) {
    case BillingsTaxiDriverContributionPenaltyType.PENALTY_BEING_LATE:
      return BillingRecalculateRequestPenaltyContributionType.PENALTY_BEING_LATE;
    case BillingsTaxiDriverContributionPenaltyType.PENALTY_INCOMPATIBLE_CAR:
      return BillingRecalculateRequestPenaltyContributionType.PENALTY_INCOMPATIBLE_CAR;
    case BillingsTaxiDriverContributionPenaltyType.PENALTY_OTHER:
      return BillingRecalculateRequestPenaltyContributionType.PENALTY_OTHER;
    case BillingsTaxiDriverContributionPenaltyType.PENALTY_WRONG_ROUTE:
      return BillingRecalculateRequestPenaltyContributionType.PENALTY_WRONG_ROUTE;
  }
};

const createRecalculateBillingContractCorrectionContributionType = (
  contributionType: BillingsTaxiDriverContributionContractCorrectionType
): BillingRecalculateRequestContractCorrectionContributionType => {
  switch (contributionType) {
    case BillingsTaxiDriverContributionContractCorrectionType.BASE_AMOUNT_EXTERNAL_TAXI:
      return BillingRecalculateRequestContractCorrectionContributionType.BASE_AMOUNT_EXTERNAL_TAXI;
    case BillingsTaxiDriverContributionContractCorrectionType.CONTRACT_CORRECTION_RATE:
      return BillingRecalculateRequestContractCorrectionContributionType.CONTRACT_CORRECTION_RATE;
  }
};

const createRecalculateBillingContributions = (
  contributions: BillingFormDataContributions
): BillingRecalculateRequestContribution[] => {
  const bonusContributions = contributions.bonus
    .filter(
      (contribution) =>
        contribution.type &&
        [
          BillingsTaxiDriverContributionBonusType.BONUS_FAVORABLE_DISTANCE,
          BillingsTaxiDriverContributionBonusType.BONUS_OTHER,
        ].includes(contribution.type)
    )
    .map((contribution) => {
      switch (contribution.type) {
        case BillingsTaxiDriverContributionBonusType.BONUS_FAVORABLE_DISTANCE:
          const bonusFavorableDistance: BillingRecalculateRequestContribution =
            {
              type: createRecalculateBillingBonusContributionType(
                contribution.type!
              ),
              distance: contribution.distance ?? 0,
              rate: contribution.rate ?? 0,
            } as BillingRecalculateRequestContribution;
          return bonusFavorableDistance;

        case BillingsTaxiDriverContributionBonusType.BONUS_OTHER:
          const bonusOtherRate: BillingRecalculateRequestContribution = {
            type: createRecalculateBillingBonusContributionType(
              contribution.type!
            ),
            comment: contribution.comment ?? "",
            amount: contribution.amount ?? 0,
          } as BillingRecalculateRequestContribution;
          return bonusOtherRate;
      }
    });

  const contractCorrectionContributions = contributions.contractCorrection
    .filter(
      (contribution) =>
        contribution.type &&
        [
          BillingsTaxiDriverContributionContractCorrectionType.BASE_AMOUNT_EXTERNAL_TAXI,
          BillingsTaxiDriverContributionContractCorrectionType.CONTRACT_CORRECTION_RATE,
        ].includes(contribution.type)
    )
    .map((contribution) => {
      switch (contribution.type) {
        case BillingsTaxiDriverContributionContractCorrectionType.CONTRACT_CORRECTION_RATE:
          const contractCorrection: BillingRecalculateRequestContribution = {
            type: createRecalculateBillingContractCorrectionContributionType(
              contribution.type!
            ),
            rate: contribution.rate ?? 0,
          } as BillingRecalculateRequestContribution;
          return contractCorrection;
        case BillingsTaxiDriverContributionContractCorrectionType.BASE_AMOUNT_EXTERNAL_TAXI:
          const baseAmount: BillingRecalculateRequestContribution = {
            type: createRecalculateBillingContractCorrectionContributionType(
              contribution.type!
            ),
            amount: contribution.amount ?? 0,
            distance: contribution.distance ?? 0,
          } as BillingRecalculateRequestContribution;
          return baseAmount;
      }
    });

  const penaltyContributions = contributions.penalty
    .filter(
      (contribution) =>
        contribution.type &&
        [
          BillingsTaxiDriverContributionPenaltyType.PENALTY_BEING_LATE,
          BillingsTaxiDriverContributionPenaltyType.PENALTY_INCOMPATIBLE_CAR,
          BillingsTaxiDriverContributionPenaltyType.PENALTY_OTHER,
          BillingsTaxiDriverContributionPenaltyType.PENALTY_WRONG_ROUTE,
        ].includes(contribution.type)
    )
    .map((contribution) => {
      switch (contribution.type) {
        case BillingsTaxiDriverContributionPenaltyType.PENALTY_BEING_LATE:
        case BillingsTaxiDriverContributionPenaltyType.PENALTY_INCOMPATIBLE_CAR:
        case BillingsTaxiDriverContributionPenaltyType.PENALTY_OTHER:
        case BillingsTaxiDriverContributionPenaltyType.PENALTY_WRONG_ROUTE:
          const penalty: BillingRecalculateRequestContribution = {
            type: createRecalculateBillingPenaltyContributionType(
              contribution.type!
            ),
            comment: contribution.comment ?? "",
            amount: contribution.amount ?? 0,
          } as BillingRecalculateRequestContribution;
          return penalty;
      }
    });

  return [
    ...(bonusContributions as BillingRecalculateRequestContribution[]),
    ...(penaltyContributions as BillingRecalculateRequestContribution[]),
    ...(contractCorrectionContributions as BillingRecalculateRequestContribution[]),
  ];
};

const createRecalculateRequest = (
  data: BillingFormData
): BillingRecalculateRequest => {
  const result: BillingRecalculateRequest = {
    billing_nodes: createBilligNodes(data),
    billing_contributions: createRecalculateBillingContributions(
      data.billingContributions
    ),
    bonus: 0,
    penalty: 0,
    bonus_type: data.bonusType,
    penalty_type: data.penaltyType,
    billing_type: "TAXI_2_DRIVER",
  };
  return result;
};

const createBilligNodes = (data: BillingFormData): BillingNodeApi[] => {
  return data.billingNodes.map((specificNode) => {
    const node: BillingNodeApi = {
      id: specificNode.id,
      node_id: specificNode.nodeId,
      order_id: specificNode.orderId,
      lat: specificNode.lat,
      lon: specificNode.lon,
      distance: specificNode.distance,
      description: specificNode.description,
      checkout_date: specificNode.checkoutDate!,
      checkout_event_id: specificNode.checkoutEventUuid,
      planned_date: specificNode.plannedDate!,
      halting_amount: specificNode.haltingAmount,
      halting_time: specificNode.haltingTime,
      allow_charge: specificNode.isHighwayAllowed,
      highway_charge: specificNode.highwayCharge,
      planned_distance: specificNode.plannedDistance,
      editable_coordinates: specificNode.isEditable,
    };
    return node;
  });
};

const billingRecalculateRequestFactory = {
  createRecalculateRequest,
};

export default billingRecalculateRequestFactory;
