import MessengerChannel from "../../../../common/components/messenger/types/messenger-channel";
import MessengerChannelAvailability from "../../../../common/components/messenger/types/messenger-channel-availability";
import billingsTranslationsHelper from "../../../../languages/billings-translations.helper";
import { BillingsCargoTaxiContributionDiscountType } from "../common/contributions/discount/types/billings-cargo-taxi-contributions-discount";

const getMessengerChannelAvailability = (): MessengerChannelAvailability => {
  return {
    [MessengerChannel.BILLING]: true,
    [MessengerChannel.CARGO]: true,
    [MessengerChannel.DRIVER]: true,
    [MessengerChannel.RAILY]: true,
    [MessengerChannel.SHARED]: true,
    [MessengerChannel.TAXI]: true,
    [MessengerChannel.MILEAGE_NOTES]: false,
    [MessengerChannel.MILEAGE_PASSENGER]: false,
    [MessengerChannel.DELEGATION_NOTES]: false,
  };
};

const getContributionTypeTranslation = (
  contributionType: BillingsCargoTaxiContributionDiscountType | null
) => {
  const translations =
    billingsTranslationsHelper.getCargoTaxiViewBillingsTranslations()
      .contributions.discount.type;

  switch (contributionType) {
    case BillingsCargoTaxiContributionDiscountType.DISCOUNT_BEING_LATE:
      return translations.beingLate;
    case BillingsCargoTaxiContributionDiscountType.DISCOUNT_INCOMPATIBLE_CAR:
      return translations.incompatibleCar;
    case BillingsCargoTaxiContributionDiscountType.DISCOUNT_OPTIMIZATION:
      return translations.optimization;
    case BillingsCargoTaxiContributionDiscountType.DISCOUNT_OTHER:
      return translations.other;
    case BillingsCargoTaxiContributionDiscountType.CONTRACT_CORRECTION_PERCENTAGE:
      return translations.percentage;
  }
};

const billingsCargoTaxiHelper = {
  getMessengerChannelAvailability,
  getContributionTypeTranslation,
};

export default billingsCargoTaxiHelper;
