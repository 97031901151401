import BonusType from "../types/bonus-type";
import PenaltyType from "../types/penalty-type";
import BillingNode from "./types/billing-node";

export enum BillingSaveRequestBonusContributionType {
  BONUS_FAVORABLE_DISTANCE = "BONUS_FAVORABLE_DISTANCE",
  BONUS_OTHER = "BONUS_OTHER",
}

export enum BillingSaveRequestPenaltyContributionType {
  PENALTY_WRONG_ROUTE = "PENALTY_WRONG_ROUTE",
  PENALTY_BEING_LATE = "PENALTY_BEING_LATE",
  PENALTY_INCOMPATIBLE_CAR = "PENALTY_INCOMPATIBLE_CAR",
  PENALTY_OTHER = "PENALTY_OTHER",
}

export enum BillingSaveRequestContractCorrectionContributionType {
  BASE_AMOUNT_EXTERNAL_TAXI = "BASE_AMOUNT_EXTERNAL_TAXI",
  CONTRACT_CORRECTION_RATE = "CONTRACT_CORRECTION_RATE",
}

export type BillingSaveRequestContractCorrectionContributionBaseAmountExternalTaxi =
  {
    type: BillingSaveRequestContractCorrectionContributionType.BASE_AMOUNT_EXTERNAL_TAXI | null;
    distance: number;
    amount: number;
  };

export type BillingSaveRequestContractCorrectionContributionOtherRate = {
  type: BillingSaveRequestContractCorrectionContributionType.CONTRACT_CORRECTION_RATE | null;
  rate: number;
};

export type BillingSaveRequestBonusContributionFavorableDistance = {
  type: BillingSaveRequestBonusContributionType.BONUS_FAVORABLE_DISTANCE | null;
  distance: number;
  rate: number;
};

export type BillingSaveRequestBonusContributionOther = {
  type: BillingSaveRequestBonusContributionType.BONUS_OTHER | null;
  comment: string | null;
  amount: number;
};

export type BillingSaveRequestPenaltyContributionWrongRoute = {
  type: BillingSaveRequestPenaltyContributionType.PENALTY_WRONG_ROUTE | null;
  comment: string | null;
  amount: number;
};

export type BillingSaveRequestPenaltyContributionBeingLate = {
  type: BillingSaveRequestPenaltyContributionType.PENALTY_BEING_LATE | null;
  comment: string | null;
  amount: number;
};

export type BillingSaveRequestPenaltyContributionIncompatibleCar = {
  type: BillingSaveRequestPenaltyContributionType.PENALTY_INCOMPATIBLE_CAR | null;
  comment: string | null;
  amount: number;
};

export type BillingSaveRequestPenaltyContributionOther = {
  type: BillingSaveRequestPenaltyContributionType.PENALTY_OTHER | null;
  comment: string | null;
  amount: number;
};

export type BillingSaveRequestContractCorrectionContribution =
  | BillingSaveRequestContractCorrectionContributionBaseAmountExternalTaxi
  | BillingSaveRequestContractCorrectionContributionOtherRate;

export type BillingSaveRequestBonusContribution =
  | BillingSaveRequestBonusContributionFavorableDistance
  | BillingSaveRequestBonusContributionOther;

export type BillingSaveRequestPenaltyContribution =
  | BillingSaveRequestPenaltyContributionWrongRoute
  | BillingSaveRequestPenaltyContributionBeingLate
  | BillingSaveRequestPenaltyContributionIncompatibleCar
  | BillingSaveRequestPenaltyContributionOther;

export type BillingSaveRequestContribution =
  | BillingSaveRequestBonusContribution
  | BillingSaveRequestPenaltyContribution
  | BillingSaveRequestContractCorrectionContribution;

type BillingSaveRequest = {
  billing_nodes: BillingNode[];
  billing_contributions: BillingSaveRequestContribution[];
  billing_type: string;
  bonus: number;
  penalty: number;
  penalty_type: PenaltyType | undefined;
  bonus_type: BonusType | undefined;
};

export default BillingSaveRequest;
