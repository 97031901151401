import BillingNode from "./types/billing-node";

export enum BillingSaveRequestBillingContributionDiscountType {
  DISCOUNT_OPTIMIZATION = "DISCOUNT_OPTIMIZATION",
  DISCOUNT_BEING_LATE = "DISCOUNT_BEING_LATE",
  DISCOUNT_INCOMPATIBLE_CAR = "DISCOUNT_INCOMPATIBLE_CAR",
  DISCOUNT_OTHER = "DISCOUNT_OTHER",
  CONTRACT_CORRECTION_PERCENTAGE = "CONTRACT_CORRECTION_PERCENTAGE",
}

export type BillingSaveRequestBillingContributionContractCorrectionPercentage =
  {
    type: BillingSaveRequestBillingContributionDiscountType.CONTRACT_CORRECTION_PERCENTAGE | null;
    percentage: number;
  };

export type BillingSaveRequestBillingContributionDiscountOptimization = {
  type: BillingSaveRequestBillingContributionDiscountType.DISCOUNT_OPTIMIZATION | null;
  comment: string;
  amount: number;
};

export type BillingSaveRequestBillingContributionDiscountBeingLate = {
  type: BillingSaveRequestBillingContributionDiscountType.DISCOUNT_BEING_LATE | null;
  comment: string;
  amount: number;
};

export type BillingSaveRequestBillingContributionDiscountIncompatibleCar = {
  type: BillingSaveRequestBillingContributionDiscountType.DISCOUNT_INCOMPATIBLE_CAR | null;
  comment: string;
  amount: number;
};

export type BillingSaveRequestBillingContributionDiscountOther = {
  type: BillingSaveRequestBillingContributionDiscountType.DISCOUNT_OTHER | null;
  comment: string;
  amount: number;
};

export type BillingSaveRequestBillingContribution =
  | BillingSaveRequestBillingContributionContractCorrectionPercentage
  | BillingSaveRequestBillingContributionDiscountOptimization
  | BillingSaveRequestBillingContributionDiscountBeingLate
  | BillingSaveRequestBillingContributionDiscountIncompatibleCar
  | BillingSaveRequestBillingContributionDiscountOther;

type BillingSaveRequest = {
  billing_nodes: BillingNode[];
  discount: number;
  billing_contributions: BillingSaveRequestBillingContribution[];
};

export default BillingSaveRequest;
