import Joi from "joi";
import formValidationService from "../../../../../../common/utils/validation/form-validation.service";
import FormValidationResult from "../../../../../../common/utils/validation/types/form-validation-result";
import { BillingsTaxiDriverContributionBonusType } from "./types/billings-taxi-driver-contributions-bonus";
import billingsTranslationsHelper from "../../../../../../languages/billings-translations.helper";

const validateComment = (comment: string | null): FormValidationResult => {
  const validationSchema = Joi.string().trim().max(100);
  return formValidationService.validate(comment, validationSchema);
};

const validateType = (
  type: BillingsTaxiDriverContributionBonusType | null
): FormValidationResult => {
  const translations =
    billingsTranslationsHelper.getTaxiDriverContributionsTranslations();

  const validationSchema = Joi.object()
    .valid(...Object.values(BillingsTaxiDriverContributionBonusType))
    .only()
    .messages({
      "any.only": translations.validationTypeErrorMessage,
    });
  return formValidationService.validate(type, validationSchema);
};

const validateAmount = (amount: number | null): FormValidationResult => {
  const validationSchema = Joi.number().min(0).less(10000).precision(2);
  return formValidationService.validate(amount, validationSchema);
};

const validateRate = (rate: number | null): FormValidationResult => {
  const validationSchema = Joi.number().min(0).less(100).precision(2);
  return formValidationService.validate(rate, validationSchema);
};

const validateDistance = (distance: number | null): FormValidationResult => {
  const validationSchema = Joi.number().integer().greater(0).less(10000);
  return formValidationService.validate(distance, validationSchema);
};

const billingTaxiDriverContributionsBonusValidationService = {
  validateComment,
  validateRate,
  validateDistance,
  validateType,
  validateAmount,
};

export default billingTaxiDriverContributionsBonusValidationService;
