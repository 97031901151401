import billingsTranslationsHelper from "../../../../../languages/billings-translations.helper";
import BillingRouteDetailsTableColumn from "./types/billing-route-details-table-column";

const getTableColumns = (): BillingRouteDetailsTableColumn[] => {
  const translations =
    billingsTranslationsHelper.getCargoTaxiEditBillingsTranslations()
      .billingRouteSummary.table;

  const tableColumns: BillingRouteDetailsTableColumn[] = [
    {
      header: translations.headers.distanceLabel,
      title: translations.headers.distanceTitle,
      accessor: "distance",
      colSpan: 10,
    },
    {
      header: translations.headers.distanceRateLabel,
      title: translations.headers.distanceRateTitle,
      accessor: "distanceRate",
      colSpan: 10,
    },
    {
      header: translations.headers.distanceCostLabel,
      title: translations.headers.distanceCostTitle,
      accessor: "distanceCost",
      colSpan: 10,
    },
    {
      header: translations.headers.haltingCostLabel,
      title: translations.headers.haltingCostTitle,
      accessor: "haltingCost",
      colSpan: 10,
    },
    {
      header: translations.headers.highwayCostLabel,
      title: translations.headers.highwayCostTitle,
      accessor: "highwayCost",
      colSpan: 10,
    },
  ];

  return tableColumns;
};

const billingRouteDetailsHelper = {
  getTableColumns,
};

export default billingRouteDetailsHelper;
