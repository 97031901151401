import { FC, useMemo } from "react";
import TableComponent, {
  TableProps,
} from "../../../../common/components/table/table.component";
import dateService from "../../../../common/utils/date/date.service";
import OrderFinishedListingItem from "../common/types/order-finished-listing-item";
import OrderFinishedListingTableRow from "../common/types/order-finished-listing-table-row";
import orderFinishedListingTableHelper from "./order-finished-listing-table.helper";
import OrderFinishedListingTableStatusComponent from "../common/table/status/order-finished-listing-table-status.component";

import OrderFinishedListingTableRouteComponent from "../common/table/route/order-finished-listing-table-route.component";
import OrderDetailsComponent from "../../details/order-details.component";
import orderFinishedListingHelper from "../common/order-finished-listing.helper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCancel,
  faCar,
  faEnvelope,
  faMessage,
  faPencil,
} from "@fortawesome/free-solid-svg-icons";
import orderTranslationsHelper from "../../../../languages/order-translations.helper";

type OrderFinishedListingTableProps = Pick<
  TableProps,
  "isError" | "isLoading"
> & {
  listingItems: OrderFinishedListingItem[];
  expandedRowsOrderUuids: string[];
  onRowClick: (orderUuid: OrderFinishedListingItem["uuid"]) => void;
};

const OrderFinishedListingTableComponent: FC<OrderFinishedListingTableProps> = (
  props
) => {
  const columns = orderFinishedListingTableHelper.getColumns();
  const createTableRow = (
    listingItem: OrderFinishedListingItem
  ): OrderFinishedListingTableRow => {
    const translations =
      orderTranslationsHelper.getFinishedListingTranslations().table.headers;

    const orderDateTitle = dateService.format(
      listingItem.orderDate,
      "dd.mm.yyyy"
    );
    const orderDateLabel = dateService.format(
      listingItem.orderDate,
      "dd.mm.yyyy"
    );

    const isHiddenComponentVisible = props.expandedRowsOrderUuids.includes(
      listingItem.uuid
    );

    const publicStatusText = orderFinishedListingHelper.getPublicStatusText(
      listingItem.publicStatus
    );

    return {
      id: listingItem.uuid,
      onClick: () => props.onRowClick(listingItem.uuid),
      value: {
        status: (
          <OrderFinishedListingTableStatusComponent
            status={listingItem.status}
          />
        ),
        date: <div title={orderDateTitle}>{orderDateLabel}</div>,
        cargoCompanyOrderId: (
          <div title={String(listingItem.cargoCompanyOrderId)}>
            {listingItem.cargoCompanyOrderId}
          </div>
        ),
        client: (
          <div title={listingItem.producerName}>{listingItem.producerName}</div>
        ),
        dispatch: (
          <div title={listingItem.dispatchName}>{listingItem.dispatchName}</div>
        ),
        driver: (
          <div>
            {listingItem.drivers.map((driver, index) => (
              <div key={index} title={driver}>
                {driver}
              </div>
            ))}
          </div>
        ),
        taxi: (
          <div title={listingItem.consumerName}>{listingItem.consumerName}</div>
        ),
        newSharedPost: listingItem.attentions.newSharedPost && (
          <div title={translations.newSharedPostTitle}>
            <FontAwesomeIcon
              icon={faMessage}
              className="order_finished_listing_table_message"
              size={"sm"}
            />
          </div>
        ),
        newPlanEntryPost: listingItem.attentions.newPlanEntryPost && (
          <div title={translations.newPlanEntryPostTitle}>
            <FontAwesomeIcon
              icon={faEnvelope}
              className="order_finished_listing_table_driver_message"
              size={"sm"}
            />
          </div>
        ),
        cargoOrderEdit: listingItem.attentions.cargoOrderEdit && (
          <div title={translations.cargoOrderEditTitle}>
            <FontAwesomeIcon
              icon={faPencil}
              className="order_finished_listing_table_edit"
              size={"sm"}
            />
          </div>
        ),
        cargoOrderCancel: listingItem.attentions.cargoOrderCancel && (
          <div title={translations.cargoOrderCancelTitle}>
            <FontAwesomeIcon
              icon={faCancel}
              className="order_finished_listing_table_cancel"
              size={"sm"}
            />
          </div>
        ),
        slkStatus: listingItem.slkStatus !== null && (
          <div
            title={
              listingItem.slkStatus
                ? translations.slkStatusActiveTitle
                : translations.slkStatusNotActiveTitle
            }
          >
            <FontAwesomeIcon
              icon={faCar}
              className={`${
                listingItem.slkStatus
                  ? "order_finished_listing_table_active"
                  : "order_finished_listing_table_cancel"
              }`}
              size={"sm"}
            />
          </div>
        ),
        cargoCompanyExternalOrderId: (
          <div title={listingItem.cargoCompanyExternalOrderId ?? ""}>
            {listingItem.cargoCompanyExternalOrderId}
          </div>
        ),
        passenger: (
          <div>
            {listingItem.passengers.map((passenger, index) => (
              <div key={index} title={passenger}>
                {passenger}
              </div>
            ))}
          </div>
        ),
        publicStatus: <div title={publicStatusText}>{publicStatusText}</div>,
        route: (
          <OrderFinishedListingTableRouteComponent
            addresses={listingItem.routeAddresses}
          />
        ),
      },
      hiddenComponent: (
        <OrderDetailsComponent
          orderUuid={listingItem.uuid}
          cargoCompanyOrderId={listingItem.cargoCompanyOrderId}
          isVisible={isHiddenComponentVisible}
        />
      ),
      isHiddenComponentVisible,
    };
  };
  const rows: OrderFinishedListingTableRow[] = useMemo(() => {
    return props.listingItems.map((listingItem) => createTableRow(listingItem));
  }, [JSON.stringify(props.listingItems), props.expandedRowsOrderUuids]);

  return (
    <TableComponent
      columns={columns}
      rows={rows}
      isLoading={props.isLoading}
      isError={props.isError}
    />
  );
};

export default OrderFinishedListingTableComponent;
