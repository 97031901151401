export enum BillingsTaxiDriverContributionBonusType {
  BONUS_FAVORABLE_DISTANCE = "BONUS_FAVORABLE_DISTANCE",
  BONUS_OTHER = "BONUS_OTHER",
}

export type BillingsTaxiDriverContributionBonusFavorableDistance = {
  type: BillingsTaxiDriverContributionBonusType.BONUS_FAVORABLE_DISTANCE | null;
  amount: number | null;
  distance: number | null;
  rate: number | null;
};

export type BillingsTaxiDriverContributionBonusOther = {
  type: BillingsTaxiDriverContributionBonusType.BONUS_OTHER | null;
  comment: string;
  amount: number | null;
};

type BillingsTaxiDriverContributionBonus =
  | BillingsTaxiDriverContributionBonusFavorableDistance
  | BillingsTaxiDriverContributionBonusOther;

export default BillingsTaxiDriverContributionBonus;
