import { FC, useCallback, useEffect, useMemo, useState } from "react";
import billingDataRouteRequestsFactory from "./factory/billing-data-route-requests.factory";
import billingMapRoutesFactory from "./factory/billing-map-routes.factory";
import BillingsNode from "../../common/types/billings-node";
import billingRecalculateRequestFactory from "./factory/billing-recalculate-request.factory";
import billingDataFactory from "./factory/billing-data.factory";
import BillingDetailsComponent from "./billing-details/billing-details.component";
import ContractDetailsComponent from "./contract-details/contract-details.component";
import AdditionalOptionsComponent from "./additional-options/additional-options.component";
import billingSaveRequestFactory from "./factory/billing-save-request.factory";
import BonusType from "./types/bonus-type";
import PenaltyType from "./types/penalty-type";
import RelatedBillingsTaxiDriverComponent from "../common/related-billings/cargo-taxi/related-billings-cargo-taxi.component";
import ButtonComponent from "../../../../common/components/button/button.component";
import CardComponent from "../../../../common/components/card/card.component";
import Column from "../../../../common/components/grid/column";
import Row from "../../../../common/components/grid/row";
import MapComponent from "../../../../common/components/map/map.component";
import MapMarker from "../../../../common/components/map/types/map-marker";
import MapRoute from "../../../../common/components/map/types/map-route";
import SearchRoadRoutingResponse from "../../../../common/utils/search-road-route/search-road-routing.response";
import BillingFormData from "./types/billing-form.data";
import billingsTranslationsHelper from "../../../../languages/billings-translations.helper";
import billingTaxiDriverApiService from "./api/billings-taxi-driver-api.service";
import billingsApiService from "./api/billing-api.service";
import BillingDataResponse from "./api/billing-data.response";
import BillingGpsResponse from "./api/billing-gps-data.response";
import BillingSaveResponse from "./api/billing-save.response";
import BillingTaxiDriverEditRouteParams from "../../common/routes/types/billing-taxi-driver-edit-route-params";
import { useNavigate, useParams } from "react-router-dom";
import formValidationService from "../../../../common/utils/validation/form-validation.service";
import notificationService from "../../../../common/utils/notification/notification.service";
import settlementRoutesHelper from "../../../settlement/common/routes/settlement-routes.helper";
import { useAppContext } from "../../../../context/app.context";
import appTranslationsHelper from "../../../../languages/app-translations.helper";
import useDocumentTitle from "../../../../common/hooks/use-document-title";
import billingBreadcrumbsHelper from "../../common/breadcrumbs/billings-breadcrumbs.helper";
import HeadingComponent from "../../../../common/components/heading/heading.component";
import BillingsSummaryConfirmationComponent from "./billings-summary-confirmation.component";
import RelatedBillingsTaxiTaxiComponent from "../common/related-billings/taxi-taxi/related-billings-taxi-taxi.component";
import billingRouteDetailsHelper from "../../common/route-details/common/billings-route-details.helper";
import { NodeValidationResult } from "../../common/route-details/types/billings-route-details-form-data-validation-result";
import BillingFormDataValidationResult from "./types/billing-form-data-validation-result";
import billingRouteDetailsDataValidationService from "../../common/route-details/common/billing-route-details-form-data-validation.service";
import BillingsRouteDetailsFormComponent from "../../common/route-details/billings-route-details-form.component";
import MessengerComponent from "../../../../common/components/messenger/messenger.component";
import billingsTaxiDriverHelper from "./billings-taxi-driver.helper";
import billingsMapMarkersFactory from "../../common/map-markers/billings-map-markers.factory";
import BillingRouteDetailsComponent from "./billing-route-details/billing-route-details.component";
import TabsComponent from "../../../../common/components/tabs/tabs.component";
import TabsData from "../../../../common/components/tabs/common/types/tabs-data";
import OrderDetailsHistoryComponent from "../../../order/details/history/order-details-history.component";
import ContentWrapper from "../../../../common/components/content-wrapper/content-wrapper.component";
import { PlanEntryCargoOrder } from "./api/types/plan-entry";
import { debounce } from "lodash";
import BonusValidationResult from "../common/contributions/bonus/types/billings-taxi-driver-contributions-bonus-validation-result";
import PenaltyValidationResult from "../common/contributions/penalty/types/billings-taxi-driver-contributions-penalty-validation-result";
import ContractCorrectionValidationResult from "../common/contributions/contract-correction/types/billings-taxi-driver-contributions-contract-correction-validation-result";
import BillingsTaxiDriverContributionBonus, {
  BillingsTaxiDriverContributionBonusFavorableDistance,
  BillingsTaxiDriverContributionBonusOther,
  BillingsTaxiDriverContributionBonusType,
} from "../common/contributions/bonus/types/billings-taxi-driver-contributions-bonus";
import billingTaxiDriverContributionsBonusValidationService from "../common/contributions/bonus/billings-taxi-driver-contributions-bonus-validation.service";
import BillingsTaxiDriverContributionPenalty, {
  BillingsTaxiDriverContributionPenaltyType,
} from "../common/contributions/penalty/types/billings-taxi-driver-contributions-penalty";
import billingTaxiDriverContributionsPenaltyValidationService from "../common/contributions/penalty/billings-taxi-driver-contributions-penalty-validation.service";
import BillingsTaxiDriverContributionContractCorrection, {
  BillingsTaxiDriverContributionBaseAmountExternalTaxi,
  BillingsTaxiDriverContributionContractCorrectionRate,
  BillingsTaxiDriverContributionContractCorrectionType,
} from "../common/contributions/contract-correction/types/billings-taxi-driver-contributions-contract-correction";
import billingTaxiDriverContributionsContractCorrectionValidationService from "../common/contributions/contract-correction/billings-taxi-driver-contributions-contract-correction-validation.service";
import billingsTaxiDriverContributionsBonusHelper from "../common/contributions/bonus/billings-taxi-driver-contributions-bonus.helper";
import billingsTaxiDriverContributionsPenaltyHelper from "../common/contributions/penalty/billings-taxi-driver-contributions-penalty.helper";
import billingsTaxiDriverContributionsContractCorrectionHelper from "../common/contributions/contract-correction/billings-taxi-driver-contributions-contract-correction.helper";
import BillingsToolsComponent from "../../common/tools/billings-tools.component";

type BillingsTaxiDriverAddProps = { asCardComponent?: boolean };

const BillingsTaxiDriverEditComponent: FC<BillingsTaxiDriverAddProps> = (
  props
) => {
  const { billingUuid } = useParams<BillingTaxiDriverEditRouteParams>();

  const { setBreadcrumbs, selectedAppLanguage } = useAppContext();

  const [routeId, setRouteId] = useState("");

  const [isBillingSavePending, setIsBillingSavePending] = useState(false);

  const [billingData, setBillingData] = useState<BillingFormData>();
  const [billingFormData, setBillingFormData] = useState<BillingFormData>();

  const [mapMarkers, setMapMarkers] = useState<MapMarker[]>();
  const [plannedMapRoute, setPlannedMapRoute] = useState<MapRoute | null>(null);
  const [completedMapRoute, setCompletedMapRoute] = useState<MapRoute | null>(
    null
  );

  const [bonusValidationResult, setBonusValidationResult] = useState<
    BonusValidationResult[]
  >([]);
  const [penaltyValidationResult, setPenaltyValidationResult] = useState<
    PenaltyValidationResult[]
  >([]);

  const [
    contractCorrectionValidationResult,
    setContractCorrectionValidationResult,
  ] = useState<ContractCorrectionValidationResult[]>([]);

  const [activeTab, setActiveTab] = useState(0);
  const [selectedCargoOrderHumanId, setSelectedCargoOrderHumanId] = useState(0);

  const [isSummaryConfirmationVisible, setIsSummaryConfirmationVisible] =
    useState(false);

  const [planEntryUuid, setPlanEntryUuid] = useState("");
  const [shouldShowOrderHistory, setShouldShowOrderHistory] = useState(false);

  const [shouldRetrieveRouteData, setShouldRetrieveRouteData] = useState(false);

  const [isBillingDataLoading, setIsBillingDataLoading] = useState(false);
  const [formValidationResults, setFormValidationResults] =
    useState<BillingFormDataValidationResult>({
      discountValue: formValidationService.defaultValidationResult,
      nodeValidations: [],
      penaltyValue: formValidationService.defaultValidationResult,
    });

  useEffect(() => {
    setIsBillingDataLoading(true);
    billingTaxiDriverApiService
      .fetchBillingData(billingUuid!)
      .then(handleBillingDataResponse)
      .finally(() => {
        setIsBillingDataLoading(false);
      });
  }, [billingUuid]);

  const navigate = useNavigate();

  const documentTitle = appTranslationsHelper
    .getDocumentTitleTranslations()
    .billingsTaxiWithDriverEdit.replace("#{routeId}", routeId);

  useDocumentTitle(documentTitle);

  const onCargoOrderSelectButtonClick = (
    cargoOrderUuid: PlanEntryCargoOrder["id"],
    cargoOrderHumanId: PlanEntryCargoOrder["human_id"]
  ) => {
    setPlanEntryUuid(cargoOrderUuid);
    setSelectedCargoOrderHumanId(cargoOrderHumanId);
  };

  const hasBaseAmountExternalTaxi = useMemo(() => {
    if (!billingFormData) {
      return;
    }

    return billingFormData.billingContributions.contractCorrection.some(
      (contribution) =>
        contribution.type ===
        BillingsTaxiDriverContributionContractCorrectionType.BASE_AMOUNT_EXTERNAL_TAXI
    );
  }, [billingFormData]);

  useEffect(() => {
    const breadcrumbs =
      billingBreadcrumbsHelper.getTaxiWithDriverEditBreadcrumbs({
        billingUuid: billingUuid!,
        routeId,
      });

    setBreadcrumbs(breadcrumbs);
  }, [selectedAppLanguage, routeId]);

  useEffect(() => {
    if (!planEntryUuid) {
      return;
    }

    billingsApiService.fetchGpsData(planEntryUuid).then(handleGpsDataResponse);
  }, [planEntryUuid]);

  const onModalClose = () => {
    setIsSummaryConfirmationVisible(false);
  };

  const openModal = () => {
    setIsSummaryConfirmationVisible(true);
  };

  useEffect(() => {
    if (!billingData || !shouldRetrieveRouteData) {
      return;
    }

    const mapMarkers = billingsMapMarkersFactory.createMapMarkers(
      billingData.billingNodes
    );

    const routeRequests =
      billingDataRouteRequestsFactory.createBillingDataRouteRequests(
        billingData.billingNodes
      );

    const fetchPromises: Promise<SearchRoadRoutingResponse>[] = [];

    routeRequests.forEach((routeRequest) => {
      const fetch = billingTaxiDriverApiService.fetchRoute(routeRequest);

      fetchPromises.push(fetch);
    });

    Promise.all(fetchPromises).then((responses) => {
      const mapRouteWaypointGroups: MapRoute["waypoints"][] = [];

      responses.forEach((response, index) => {
        const mapRoute = response.routes[0]
          ? billingMapRoutesFactory.createMapRoute(
              response.routes[0].geometry.coordinates
            )
          : null;

        if (mapRoute?.waypoints) {
          mapRouteWaypointGroups.push(mapRoute.waypoints);
        }
      });

      const newMapRouteWaypoints: MapRoute["waypoints"] = [];

      mapRouteWaypointGroups.forEach((waypoint) => {
        newMapRouteWaypoints.push(...waypoint);
      });

      const newMapRoute: MapRoute = {
        waypoints: newMapRouteWaypoints,
        options: { color: "red" },
      };

      setPlannedMapRoute(newMapRoute);
      setMapMarkers(mapMarkers);
    });
  }, [shouldRetrieveRouteData]);

  const recalculateBilling = useCallback(
    debounce((formData: BillingFormData) => {
      const recalculateRequest =
        billingRecalculateRequestFactory.createRecalculateRequest(formData);

      billingTaxiDriverApiService
        .recalculateBilling(planEntryUuid, recalculateRequest)
        .then(handleRecalculateResponse);
    }, 500),
    [planEntryUuid]
  );

  const handleBillingDataResponse = (response: BillingDataResponse) => {
    if (response.status === 200) {
      onBillingDataFetchSuccess(response);
    }
  };

  const handleGpsDataResponse = (response: BillingGpsResponse) => {
    if (response.status === 200) {
      onGpsDataFetchSuccess(response);
    }
  };

  const handleRecalculateResponse = (response: BillingDataResponse) => {
    if (response.status === 200) {
      onRecalculateSuccess(response);
    }
  };

  const navigateToListing = () => {
    navigate(settlementRoutesHelper.getTaxiListingOfSettledRoutesRoute());
  };

  const handleBillingSaveResponse = (response: BillingSaveResponse) => {
    if (response.status === 200) {
      notificationService.success(translations.successNotificationText);
      navigateToListing();

      return;
    }
    notificationService.error(translations.failureNotificationText);
  };

  const onBillingDataFetchSuccess = (response: BillingDataResponse) => {
    const billingData = billingDataFactory.createBillingData(response.data);

    setBillingData(billingData);
    setBillingFormData(billingData);
    setBonusValidationResult(
      billingData.billingContributions.bonus.map((contribution, index) => {
        return {
          index: index,
          results: {
            comment: formValidationService.defaultValidationResult,
            amount: formValidationService.defaultValidationResult,
            type: formValidationService.defaultValidationResult,
            distance: formValidationService.defaultValidationResult,
            rate: formValidationService.defaultValidationResult,
          },
        };
      })
    );
    setPenaltyValidationResult(
      billingData.billingContributions.penalty.map((contribution, index) => {
        return {
          index: index,
          results: {
            comment: formValidationService.defaultValidationResult,
            amount: formValidationService.defaultValidationResult,
            type: formValidationService.defaultValidationResult,
          },
        };
      })
    );
    setContractCorrectionValidationResult(
      billingData.billingContributions.contractCorrection.map(
        (contribution, index) => {
          return {
            index: index,
            results: {
              amount: formValidationService.defaultValidationResult,
              type: formValidationService.defaultValidationResult,
              distance: formValidationService.defaultValidationResult,
              rate: formValidationService.defaultValidationResult,
            },
          };
        }
      )
    );
    setPlanEntryUuid(response.data.plan_entry.id);
    setRouteId(String(response.data.plan_entry.human_id));
    setShouldRetrieveRouteData(true);

    setFormValidationResults((current) => ({
      discountValue: current.discountValue,
      nodeValidations: billingData.billingNodes.map((x) => {
        return {
          distance: formValidationService.defaultValidationResult,
          highwayCharge: formValidationService.defaultValidationResult,
          haltingTime: formValidationService.defaultValidationResult,
          position: x.position,
        };
      }),
      penaltyValue: current.penaltyValue,
    }));
  };

  const onGpsDataFetchSuccess = (response: BillingGpsResponse) => {
    const gpsData: MapRoute = {
      waypoints: response.data.map((x) => {
        return { latitude: x.lat, longitude: x.lon };
      }),
      options: { color: "blue" },
    };

    setCompletedMapRoute(gpsData);
  };

  const onBillingDataSave = () => {
    if (!billingFormData) {
      return;
    }

    const areNodesValid =
      billingFormData?.billingNodes
        .map((node) => {
          return billingRouteDetailsHelper.haltingTimeValidation(
            formValidationResults,
            node.haltingTime,
            node.position
          );
        })
        .every((x) => x) ?? false;

    const contributionBonusValidation =
      billingFormData.billingContributions.bonus.map((contribution, index) => {
        switch (contribution.type) {
          case BillingsTaxiDriverContributionBonusType.BONUS_FAVORABLE_DISTANCE:
            return {
              index: index,
              results: {
                amount:
                  billingTaxiDriverContributionsBonusValidationService.validateAmount(
                    contribution.amount
                  ),
                type: billingTaxiDriverContributionsBonusValidationService.validateType(
                  contribution.type
                ),
                distance:
                  billingTaxiDriverContributionsBonusValidationService.validateDistance(
                    contribution.distance
                  ),
                rate: billingTaxiDriverContributionsBonusValidationService.validateRate(
                  contribution.rate
                ),
                comment: formValidationService.defaultValidationResult,
              },
            };
          case BillingsTaxiDriverContributionBonusType.BONUS_OTHER:
            return {
              index: index,
              results: {
                comment:
                  billingTaxiDriverContributionsBonusValidationService.validateComment(
                    contribution.comment
                  ),
                type: billingTaxiDriverContributionsBonusValidationService.validateType(
                  contribution.type
                ),
                distance: formValidationService.defaultValidationResult,
                rate: formValidationService.defaultValidationResult,
                amount:
                  billingTaxiDriverContributionsBonusValidationService.validateAmount(
                    contribution.amount
                  ),
              },
            };
          default:
            return {
              index: index,
              results: {
                comment: formValidationService.defaultValidationResult,
                type: formValidationService.defaultValidationResult,
                distance: formValidationService.defaultValidationResult,
                rate: formValidationService.defaultValidationResult,
                amount: formValidationService.defaultValidationResult,
              },
            };
        }
      });
    const contributionPenaltyValidation =
      billingFormData.billingContributions.penalty.map(
        (contribution, index) => {
          switch (contribution.type) {
            case BillingsTaxiDriverContributionPenaltyType.PENALTY_BEING_LATE:
            case BillingsTaxiDriverContributionPenaltyType.PENALTY_INCOMPATIBLE_CAR:
            case BillingsTaxiDriverContributionPenaltyType.PENALTY_OTHER:
            case BillingsTaxiDriverContributionPenaltyType.PENALTY_WRONG_ROUTE:
              return {
                index: index,
                results: {
                  comment:
                    billingTaxiDriverContributionsPenaltyValidationService.validateComment(
                      contribution.comment
                    ),
                  type: billingTaxiDriverContributionsPenaltyValidationService.validateType(
                    contribution.type
                  ),
                  distance: formValidationService.defaultValidationResult,
                  rate: formValidationService.defaultValidationResult,
                  amount:
                    billingTaxiDriverContributionsPenaltyValidationService.validateAmount(
                      contribution.amount
                    ),
                },
              };
            default:
              return {
                index: index,
                results: {
                  comment: formValidationService.defaultValidationResult,
                  type: formValidationService.defaultValidationResult,
                  distance: formValidationService.defaultValidationResult,
                  rate: formValidationService.defaultValidationResult,
                  amount: formValidationService.defaultValidationResult,
                },
              };
          }
        }
      );

    const contributionContractCorrectionValidation =
      billingFormData.billingContributions.contractCorrection.map(
        (contribution, index) => {
          switch (contribution.type) {
            case BillingsTaxiDriverContributionContractCorrectionType.BASE_AMOUNT_EXTERNAL_TAXI:
              return {
                index: index,
                results: {
                  comment: formValidationService.defaultValidationResult,
                  type: billingTaxiDriverContributionsContractCorrectionValidationService.validateType(
                    contribution.type
                  ),
                  distance:
                    billingTaxiDriverContributionsContractCorrectionValidationService.validateDistance(
                      contribution.distance
                    ),
                  rate: formValidationService.defaultValidationResult,
                  amount:
                    billingTaxiDriverContributionsContractCorrectionValidationService.validateAmount(
                      contribution.amount
                    ),
                },
              };
            case BillingsTaxiDriverContributionContractCorrectionType.CONTRACT_CORRECTION_RATE:
              return {
                index: index,
                results: {
                  comment: formValidationService.defaultValidationResult,
                  type: billingTaxiDriverContributionsContractCorrectionValidationService.validateType(
                    contribution.type
                  ),
                  distance: formValidationService.defaultValidationResult,
                  rate: billingTaxiDriverContributionsContractCorrectionValidationService.validateRate(
                    contribution.rate
                  ),
                  amount: formValidationService.defaultValidationResult,
                },
              };
            default:
              return {
                index: index,
                results: {
                  comment: formValidationService.defaultValidationResult,
                  type: formValidationService.defaultValidationResult,
                  distance: formValidationService.defaultValidationResult,
                  rate: formValidationService.defaultValidationResult,
                  amount: formValidationService.defaultValidationResult,
                },
              };
          }
        }
      );

    const areBonusContributionsValid: boolean = contributionBonusValidation
      .map(
        (result) =>
          result.results.amount.isValid &&
          result.results.comment.isValid &&
          result.results.type.isValid &&
          result.results.distance.isValid &&
          result.results.rate.isValid
      )
      .reduce((previous, current) => previous && current, true);

    const arePenaltyContributionsValid: boolean = contributionPenaltyValidation
      .map(
        (result) =>
          result.results.amount.isValid &&
          result.results.comment.isValid &&
          result.results.type.isValid
      )
      .reduce((previous, current) => previous && current, true);

    const areContractCorrectionContributionsValid: boolean =
      contributionContractCorrectionValidation
        .map(
          (result) =>
            result.results.amount.isValid &&
            result.results.type.isValid &&
            result.results.distance.isValid &&
            result.results.rate.isValid
        )
        .reduce((previous, current) => previous && current, true);

    setBonusValidationResult(contributionBonusValidation);
    setPenaltyValidationResult(contributionPenaltyValidation);
    setContractCorrectionValidationResult(
      contributionContractCorrectionValidation
    );

    const isFormValid =
      areNodesValid &&
      areBonusContributionsValid &&
      arePenaltyContributionsValid &&
      areContractCorrectionContributionsValid;

    if (!isFormValid) {
      notificationService.error(translations.failureValidationNotificationText);
      onModalClose();
      return;
    }

    if (billingFormData) {
      setIsBillingSavePending(true);
      const data = billingSaveRequestFactory.createSaveRequest(billingFormData);

      billingTaxiDriverApiService
        .saveBillingData(billingUuid!, data)
        .then(handleBillingSaveResponse)
        .finally(() => setIsBillingSavePending(false));
    }
  };

  const onPenaltyBlur = () => {
    const shouldRecalculate = validatePenalty();

    if (shouldRecalculate) {
      recalculateBilling(billingFormData!);
    }
  };

  const onCopyFromPlannedDistance = () => {
    const newBillingNodes = billingFormData?.billingNodes.map((node) => {
      node.distance = node.plannedDistance;

      return node;
    });

    const newBillingFormData: BillingFormData = {
      ...billingFormData!,
      billingNodes: newBillingNodes!,
    };

    setBillingFormData(newBillingFormData);

    const result = billingRouteDetailsHelper.validateAllFieldsByType(
      formValidationResults,
      newBillingFormData.billingNodes,
      "distance"
    );

    nodeValidationResults(result.nodeResult!);

    if (result.isAllFormValid) recalculateBilling(newBillingFormData);
  };

  const validatePenalty = () => {
    const maximumPenaltyValue = billingData?.total ?? 0;

    const validationResult =
      billingRouteDetailsDataValidationService.validatePenalty(
        billingFormData?.penalty ?? 0,
        maximumPenaltyValue
      );

    setFormValidationResults((curr) => ({
      ...curr,
      penaltyValue: validationResult,
    }));

    return validationResult.isValid;
  };

  const onBonusBlur = () => {
    const shouldRecalculate = validateBonus();

    if (shouldRecalculate) recalculateBilling(billingFormData!);
  };

  const validateBonus = () => {
    const validationResult =
      billingRouteDetailsDataValidationService.validateBonus(
        billingFormData?.bonus!
      );

    setFormValidationResults((curr) => ({
      ...curr,
      discountValue: validationResult,
    }));

    return validationResult.isValid;
  };

  const validationOnBlur = (position: number, fieldName: string) => {
    type Node = keyof typeof node;

    const node = billingFormData?.billingNodes.find(
      (x) => x.position === position
    );

    const fieldToValidate = node && node[fieldName as Node];

    const results = billingRouteDetailsHelper.validationOnBlur(
      fieldToValidate,
      formValidationResults,
      position,
      fieldName
    );

    nodeValidationResults(results.nodeResult!);

    if (results.isAllFormValid) recalculateBilling(billingFormData!);
  };

  const nodeValidationResults = (node: NodeValidationResult[]) => {
    setFormValidationResults((curr) => ({
      ...curr,
      nodeValidations: node,
    }));
  };

  const onBonusChanged = (bonusValue: number) => {
    setBillingFormData((current) => ({ ...current!, bonus: bonusValue }));
  };

  const onBonusTypeChanged = (bonusType: BonusType) => {
    setBillingFormData((current) => ({
      ...current!,
      bonusType: bonusType,
    }));
  };

  const onPenaltyChanged = (penaltyValue: number) => {
    setBillingFormData((current) => ({ ...current!, penalty: penaltyValue }));
  };

  const onPenaltyTypeChanged = (penaltyType: PenaltyType) => {
    setBillingFormData((current) => ({
      ...current!,
      penaltyType: penaltyType,
    }));
  };

  const onBillingNodeChanged = (node: BillingsNode) => {
    const nodes = billingFormData?.billingNodes.filter(
      (x) => x.position !== node.position
    )!;

    nodes.push(node);

    const sortedArray = nodes.sort((a, b) =>
      a.position < b.position ? -1 : 1
    );

    setBillingFormData((current) => ({
      ...current!,
      billingNodes: sortedArray,
    }));
  };

  const onInputBlur = () => {
    recalculateBilling(billingFormData!);
  };

  const onRecalculateSuccess = (response: BillingDataResponse) => {
    const billingData = billingDataFactory.createBillingData(response.data);
    setBillingData(billingData);
    setBillingFormData(billingData);
    setBonusValidationResult(
      billingData.billingContributions.bonus.map((contribution, index) => {
        return {
          index: index,
          results: {
            comment: formValidationService.defaultValidationResult,
            amount: formValidationService.defaultValidationResult,
            type: formValidationService.defaultValidationResult,
            distance: formValidationService.defaultValidationResult,
            rate: formValidationService.defaultValidationResult,
          },
        };
      })
    );
    setPenaltyValidationResult(
      billingData.billingContributions.penalty.map((contribution, index) => {
        return {
          index: index,
          results: {
            comment: formValidationService.defaultValidationResult,
            amount: formValidationService.defaultValidationResult,
            type: formValidationService.defaultValidationResult,
          },
        };
      })
    );
    setContractCorrectionValidationResult(
      billingData.billingContributions.contractCorrection.map(
        (contribution, index) => {
          return {
            index: index,
            results: {
              amount: formValidationService.defaultValidationResult,
              type: formValidationService.defaultValidationResult,
              distance: formValidationService.defaultValidationResult,
              rate: formValidationService.defaultValidationResult,
            },
          };
        }
      )
    );
  };

  const mapRoutes: MapRoute[] = useMemo(() => {
    const finalMapRoutes: MapRoute[] = [];
    if (plannedMapRoute) {
      finalMapRoutes.push(plannedMapRoute);
    }
    if (completedMapRoute) {
      finalMapRoutes.push(completedMapRoute);
    }
    return finalMapRoutes;
  }, [plannedMapRoute, completedMapRoute]);

  const translations =
    billingsTranslationsHelper.getTaxiDriverEditBillingsTranslations();

  const messengerChannelsAvailability =
    billingsTaxiDriverHelper.getMessengerChannelAvailability();

  const onContributionTypeChange = useCallback(
    debounce(
      (
        index: number,
        type:
          | BillingsTaxiDriverContributionBonusType
          | BillingsTaxiDriverContributionPenaltyType
          | BillingsTaxiDriverContributionContractCorrectionType
          | null
      ) => {
        setBillingFormData((currentValue) => {
          if (!currentValue || !billingData) return;

          let updatedFormData = {
            ...currentValue,
          };

          switch (activeTab) {
            case 0:
              const billingBonusContributions =
                currentValue.billingContributions.bonus;
              billingBonusContributions[index] = {
                ...billingBonusContributions[index],
                type: type!,
                rate:
                  type ===
                  BillingsTaxiDriverContributionBonusType.BONUS_FAVORABLE_DISTANCE
                    ? billingData.contractDetails?.distanceRate
                    : null,
              } as BillingsTaxiDriverContributionBonus;
              updatedFormData = {
                ...updatedFormData,
                billingContributions: {
                  bonus: billingBonusContributions,
                  penalty: updatedFormData.billingContributions.penalty,
                  contractCorrection:
                    updatedFormData.billingContributions.contractCorrection,
                },
              };
              break;
            case 1:
              const billingPenaltyContributions =
                currentValue.billingContributions.penalty;
              billingPenaltyContributions[index] = {
                ...billingPenaltyContributions[index],
                type: type!,
              } as BillingsTaxiDriverContributionPenalty;
              updatedFormData = {
                ...updatedFormData,
                billingContributions: {
                  bonus: updatedFormData.billingContributions.bonus,
                  penalty: billingPenaltyContributions,
                  contractCorrection:
                    updatedFormData.billingContributions.contractCorrection,
                },
              };
              break;
            case 2:
              const billingContractCorrectionContributions =
                currentValue.billingContributions.contractCorrection;
              billingContractCorrectionContributions[index] = {
                ...billingContractCorrectionContributions[index],
                type: type!,
                distance:
                  type ===
                    (BillingsTaxiDriverContributionContractCorrectionType.CONTRACT_CORRECTION_RATE
                      ? billingData.distance
                      : null) ||
                  (BillingsTaxiDriverContributionContractCorrectionType.BASE_AMOUNT_EXTERNAL_TAXI
                    ? billingData.distance
                    : null),
              } as BillingsTaxiDriverContributionContractCorrection;
              updatedFormData = {
                ...updatedFormData,
                billingContributions: {
                  bonus: updatedFormData.billingContributions.bonus,
                  penalty: updatedFormData.billingContributions.penalty,
                  contractCorrection: billingContractCorrectionContributions,
                },
              };

              if (
                updatedFormData.billingContributions.contractCorrection[index]
                  .type ===
                BillingsTaxiDriverContributionContractCorrectionType.BASE_AMOUNT_EXTERNAL_TAXI
              ) {
                updatedFormData = {
                  ...updatedFormData,
                  billingContributions: {
                    bonus: [],
                    penalty: [],
                    contractCorrection:
                      billingContractCorrectionContributions.filter(
                        (contribution) =>
                          contribution.type ===
                          BillingsTaxiDriverContributionContractCorrectionType.BASE_AMOUNT_EXTERNAL_TAXI
                      ),
                  },
                };
                recalculateBilling(updatedFormData);
              }
          }
          return updatedFormData;
        });
      },
      100
    ),
    [activeTab, billingFormData]
  );

  const onAmountChange = useCallback(
    debounce((index: number, value: number | null) => {
      if (!billingFormData) return;

      let newAmountValue = {
        ...billingFormData,
      };

      switch (activeTab) {
        case 0:
          const billingBonusContributions =
            billingFormData.billingContributions.bonus;
          billingBonusContributions[index] = {
            ...billingBonusContributions[index],
            amount: value!,
          } as BillingsTaxiDriverContributionBonus;
          newAmountValue = {
            ...newAmountValue,
            billingContributions: {
              bonus: billingBonusContributions,
              penalty: newAmountValue.billingContributions.penalty,
              contractCorrection:
                newAmountValue.billingContributions.contractCorrection,
            },
          };
          break;
        case 1:
          const billingPenaltyContributions =
            billingFormData.billingContributions.penalty;
          billingPenaltyContributions[index] = {
            ...billingPenaltyContributions[index],
            amount: value!,
          } as BillingsTaxiDriverContributionPenalty;
          newAmountValue = {
            ...newAmountValue,
            billingContributions: {
              bonus: newAmountValue.billingContributions.bonus,
              penalty: billingPenaltyContributions,
              contractCorrection:
                newAmountValue.billingContributions.contractCorrection,
            },
          };
          break;
        case 2:
          const billingContractCorrectionContributions =
            billingFormData.billingContributions.contractCorrection;
          billingContractCorrectionContributions[index] = {
            ...billingContractCorrectionContributions[index],
            amount: value!,
          } as BillingsTaxiDriverContributionContractCorrection;

          newAmountValue = {
            ...newAmountValue,
            billingContributions: {
              bonus: newAmountValue.billingContributions.bonus,
              penalty: newAmountValue.billingContributions.penalty,
              contractCorrection: billingContractCorrectionContributions,
            },
          };
      }

      setBillingFormData(newAmountValue);
    }, 300),
    [activeTab, billingFormData]
  );

  const onDistanceChange = useCallback(
    debounce((index: number, value: number | null) => {
      if (!billingFormData) return;

      let newAmountValue = {
        ...billingFormData,
      };

      switch (activeTab) {
        case 0:
          const billingBonusContributions =
            billingFormData.billingContributions.bonus;
          billingBonusContributions[index] = {
            ...billingBonusContributions[index],
            distance: value!,
          } as BillingsTaxiDriverContributionBonus;
          newAmountValue = {
            ...newAmountValue,
            billingContributions: {
              bonus: billingBonusContributions,
              penalty: newAmountValue.billingContributions.penalty,
              contractCorrection:
                newAmountValue.billingContributions.contractCorrection,
            },
          };
          break;
        case 1:
          const billingPenaltyContributions =
            billingFormData.billingContributions.penalty;
          billingPenaltyContributions[index] = {
            ...billingPenaltyContributions[index],
            distance: value!,
          } as BillingsTaxiDriverContributionPenalty;
          newAmountValue = {
            ...newAmountValue,
            billingContributions: {
              bonus: newAmountValue.billingContributions.bonus,
              penalty: billingPenaltyContributions,
              contractCorrection:
                newAmountValue.billingContributions.contractCorrection,
            },
          };
          break;
        case 2:
          const billingContractCorrectionContributions =
            billingFormData.billingContributions.contractCorrection;
          billingContractCorrectionContributions[index] = {
            ...billingContractCorrectionContributions[index],
            distance: value!,
          } as BillingsTaxiDriverContributionContractCorrection;
          newAmountValue = {
            ...newAmountValue,
            billingContributions: {
              bonus: newAmountValue.billingContributions.bonus,
              penalty: newAmountValue.billingContributions.penalty,
              contractCorrection: billingContractCorrectionContributions,
            },
          };
      }

      setBillingFormData(newAmountValue);
    }, 300),
    [activeTab, billingFormData]
  );

  const onRateChange = useCallback(
    debounce((index: number, value: number | null) => {
      if (!billingFormData) return;

      let newAmountValue = {
        ...billingFormData,
      };

      switch (activeTab) {
        case 0:
          const billingBonusContributions =
            billingFormData.billingContributions.bonus;
          billingBonusContributions[index] = {
            ...billingBonusContributions[index],
            rate: value!,
          } as BillingsTaxiDriverContributionBonus;
          newAmountValue = {
            ...newAmountValue,
            billingContributions: {
              bonus: billingBonusContributions,
              penalty: newAmountValue.billingContributions.penalty,
              contractCorrection:
                newAmountValue.billingContributions.contractCorrection,
            },
          };
          break;
        case 1:
          const billingPenaltyContributions =
            billingFormData.billingContributions.penalty;
          billingPenaltyContributions[index] = {
            ...billingPenaltyContributions[index],
            rate: value!,
          } as BillingsTaxiDriverContributionPenalty;
          newAmountValue = {
            ...newAmountValue,
            billingContributions: {
              bonus: newAmountValue.billingContributions.bonus,
              penalty: billingPenaltyContributions,
              contractCorrection:
                newAmountValue.billingContributions.contractCorrection,
            },
          };
          break;
        case 2:
          const billingContractCorrectionContributions =
            billingFormData.billingContributions.contractCorrection;
          billingContractCorrectionContributions[index] = {
            ...billingContractCorrectionContributions[index],
            rate: value!,
          } as BillingsTaxiDriverContributionContractCorrection;
          newAmountValue = {
            ...newAmountValue,
            billingContributions: {
              bonus: newAmountValue.billingContributions.bonus,
              penalty: newAmountValue.billingContributions.penalty,
              contractCorrection: billingContractCorrectionContributions,
            },
          };
      }

      setBillingFormData(newAmountValue);
    }, 300),
    [activeTab, billingFormData]
  );

  const onCommentChange = useCallback(
    debounce((index: number, value: string | null) => {
      setBillingFormData((currentValue) => {
        if (!currentValue) return;

        let newCommentValue = {
          ...currentValue,
        };

        switch (activeTab) {
          case 0:
            const billingBonusContributions =
              currentValue.billingContributions.bonus;
            billingBonusContributions[index] = {
              ...billingBonusContributions[index],
              comment: value!,
            } as BillingsTaxiDriverContributionBonus;
            newCommentValue = {
              ...currentValue,
              billingContributions: {
                bonus: billingBonusContributions,
                penalty: newCommentValue.billingContributions.penalty,
                contractCorrection:
                  newCommentValue.billingContributions.contractCorrection,
              },
            };
            break;
          case 1:
            const billingPenaltyContributions =
              currentValue.billingContributions.penalty;
            billingPenaltyContributions[index] = {
              ...billingPenaltyContributions[index],
              comment: value!,
            } as BillingsTaxiDriverContributionPenalty;
            newCommentValue = {
              ...newCommentValue,
              billingContributions: {
                bonus: newCommentValue.billingContributions.bonus,
                penalty: billingPenaltyContributions,
                contractCorrection:
                  newCommentValue.billingContributions.contractCorrection,
              },
            };
            break;
          case 2:
            return;
        }

        return newCommentValue;
      });
    }, 10),
    [activeTab]
  );

  const onCommentBlur = (index: number) => {
    if (!billingFormData) {
      return;
    }

    switch (activeTab) {
      case 0:
        const bonusComment = (
          billingFormData.billingContributions.bonus[
            index
          ] as BillingsTaxiDriverContributionBonusOther
        ).comment;
        const validationBonusResult =
          billingTaxiDriverContributionsBonusValidationService.validateComment(
            bonusComment
          );
        setBonusValidationResult((currentValue) =>
          currentValue.map((item) =>
            item.index === index
              ? {
                  ...item,
                  results: { ...item.results, comment: validationBonusResult },
                }
              : item
          )
        );
        break;
      case 1:
        const penaltyComment = (
          billingFormData.billingContributions.penalty[
            index
          ] as BillingsTaxiDriverContributionPenalty
        ).comment;

        const validationPenaltyResult =
          billingTaxiDriverContributionsPenaltyValidationService.validateComment(
            penaltyComment
          );

        setPenaltyValidationResult((currentValue) =>
          currentValue.map((item) =>
            item.index === index
              ? {
                  ...item,
                  results: {
                    ...item.results,
                    comment: validationPenaltyResult,
                  },
                }
              : item
          )
        );
        break;
      case 2:
        return;
    }
    recalculateBilling(billingFormData);
  };

  const onAmountBlur = (index: number) => {
    if (!billingFormData) {
      return;
    }

    switch (activeTab) {
      case 0:
        if (
          billingFormData.billingContributions.bonus[index].type ===
          BillingsTaxiDriverContributionBonusType.BONUS_FAVORABLE_DISTANCE
        )
          return;

        const bonusAmount = (
          billingFormData.billingContributions.bonus[
            index
          ] as BillingsTaxiDriverContributionBonusOther
        ).amount;

        const validationBonusResult =
          billingTaxiDriverContributionsBonusValidationService.validateAmount(
            bonusAmount
          );

        setBonusValidationResult((currentValue) =>
          currentValue.map((item) =>
            item.index === index
              ? {
                  ...item,
                  results: { ...item.results, amount: validationBonusResult },
                }
              : item
          )
        );
        break;
      case 1:
        const penaltyAmount = (
          billingFormData.billingContributions.penalty[
            index
          ] as BillingsTaxiDriverContributionPenalty
        ).amount;
        const validationPenaltyResult =
          billingTaxiDriverContributionsPenaltyValidationService.validateAmount(
            penaltyAmount
          );
        setPenaltyValidationResult((currentValue) =>
          currentValue.map((item) =>
            item.index === index
              ? {
                  ...item,
                  results: { ...item.results, amount: validationPenaltyResult },
                }
              : item
          )
        );
        break;
      case 2:
        const contractCorrectionAmount = (
          billingFormData.billingContributions.contractCorrection[
            index
          ] as BillingsTaxiDriverContributionBaseAmountExternalTaxi
        ).amount;

        const validationContractCorrectionResult =
          billingTaxiDriverContributionsContractCorrectionValidationService.validateAmount(
            contractCorrectionAmount
          );

        setContractCorrectionValidationResult((currentValue) =>
          currentValue.map((item) =>
            item.index === index
              ? {
                  ...item,
                  results: {
                    ...item.results,
                    amount: validationContractCorrectionResult,
                  },
                }
              : item
          )
        );
    }
    recalculateBilling(billingFormData);
  };

  const onDistanceBlur = (index: number) => {
    if (!billingFormData) {
      return;
    }

    switch (activeTab) {
      case 0:
        if (
          billingFormData.billingContributions.bonus[index].type ===
          BillingsTaxiDriverContributionBonusType.BONUS_OTHER
        )
          return;

        const bonusDistance = (
          billingFormData.billingContributions.bonus[
            index
          ] as BillingsTaxiDriverContributionBonusFavorableDistance
        ).distance;

        const validationBonusResult =
          billingTaxiDriverContributionsBonusValidationService.validateDistance(
            bonusDistance
          );

        setBonusValidationResult((currentValue) =>
          currentValue.map((item) =>
            item.index === index
              ? {
                  ...item,
                  results: { ...item.results, distance: validationBonusResult },
                }
              : item
          )
        );
        break;
      case 1:
        return;
      case 2:
        const contractCorrectionDistance = (
          billingFormData.billingContributions.contractCorrection[
            index
          ] as BillingsTaxiDriverContributionBaseAmountExternalTaxi
        ).distance;

        const validationContractCorrectionResult =
          billingTaxiDriverContributionsContractCorrectionValidationService.validateDistance(
            contractCorrectionDistance
          );

        setContractCorrectionValidationResult((currentValue) =>
          currentValue.map((item) =>
            item.index === index
              ? {
                  ...item,
                  results: {
                    ...item.results,
                    distance: validationContractCorrectionResult,
                  },
                }
              : item
          )
        );
        break;
    }
    recalculateBilling(billingFormData);
  };

  const onRateBlur = (index: number) => {
    if (!billingFormData) {
      return;
    }

    switch (activeTab) {
      case 0:
        if (
          billingFormData.billingContributions.bonus[index].type ===
          BillingsTaxiDriverContributionBonusType.BONUS_OTHER
        )
          return;

        const bonusDistance = (
          billingFormData.billingContributions.bonus[
            index
          ] as BillingsTaxiDriverContributionBonusFavorableDistance
        ).rate;

        const validationBonusResult =
          billingTaxiDriverContributionsBonusValidationService.validateDistance(
            bonusDistance
          );

        setBonusValidationResult((currentValue) =>
          currentValue.map((item) =>
            item.index === index
              ? {
                  ...item,
                  results: { ...item.results, distance: validationBonusResult },
                }
              : item
          )
        );
        break;
      case 1:
        return;
      case 2:
        const contractCorrectionDistance = (
          billingFormData.billingContributions.contractCorrection[
            index
          ] as BillingsTaxiDriverContributionContractCorrectionRate
        ).rate;

        const validationContractCorrectionResult =
          billingTaxiDriverContributionsContractCorrectionValidationService.validateDistance(
            contractCorrectionDistance
          );

        setContractCorrectionValidationResult((currentValue) =>
          currentValue.map((item) =>
            item.index === index
              ? {
                  ...item,
                  results: {
                    ...item.results,
                    distance: validationContractCorrectionResult,
                  },
                }
              : item
          )
        );
        break;
    }
    recalculateBilling(billingFormData);
  };

  const onTypeBlur = (index: number) => {
    if (!billingFormData) {
      return;
    }

    switch (activeTab) {
      case 0:
        const bonusContributionType = (
          billingFormData.billingContributions.bonus[
            index
          ] as BillingsTaxiDriverContributionBonus
        ).type;

        const validationBonusResult =
          billingTaxiDriverContributionsBonusValidationService.validateType(
            bonusContributionType
          );

        setBonusValidationResult((currentValue) =>
          currentValue.map((item) =>
            item.index === index
              ? {
                  ...item,
                  results: { ...item.results, amount: validationBonusResult },
                }
              : item
          )
        );
        recalculateBilling(billingFormData);
        break;
      case 1:
        const penaltyContributionType = (
          billingFormData.billingContributions.penalty[
            index
          ] as BillingsTaxiDriverContributionPenalty
        ).type;
        const validationPenaltyResult =
          billingTaxiDriverContributionsPenaltyValidationService.validateType(
            penaltyContributionType
          );

        setPenaltyValidationResult((currentValue) =>
          currentValue.map((item) =>
            item.index === index
              ? {
                  ...item,
                  results: { ...item.results, amount: validationPenaltyResult },
                }
              : item
          )
        );
        recalculateBilling(billingFormData);
        break;
      case 2:
        const contractCorrectionContributionType = (
          billingFormData.billingContributions.contractCorrection[
            index
          ] as BillingsTaxiDriverContributionContractCorrection
        ).type;

        const validationContractCorrectionResult =
          billingTaxiDriverContributionsContractCorrectionValidationService.validateType(
            contractCorrectionContributionType
          );

        setContractCorrectionValidationResult((currentValue) =>
          currentValue.map((item) =>
            item.index === index
              ? {
                  ...item,
                  results: {
                    ...item.results,
                    amount: validationContractCorrectionResult,
                  },
                }
              : item
          )
        );
        recalculateBilling(billingFormData);
        break;
    }
  };

  const onContributionDelete = useCallback(
    debounce((contributionIndex: number) => {
      if (!billingFormData) {
        return [];
      }

      switch (activeTab) {
        case 0:
          const newBonusContributions: BillingsTaxiDriverContributionBonus[] =
            billingFormData.billingContributions.bonus.filter(
              (contribution, index) => index !== contributionIndex
            );

          const updatedBillingFormData = {
            ...billingFormData,
            billingContributions: {
              bonus: newBonusContributions,
              penalty: billingFormData.billingContributions.penalty,
              contractCorrection:
                billingFormData.billingContributions.contractCorrection,
            },
          };

          recalculateBilling(updatedBillingFormData);
          setBillingFormData(updatedBillingFormData);

          const newBonusContributionValidation = bonusValidationResult
            .filter(
              (contributionValidation) =>
                contributionValidation.index !== contributionIndex
            )
            .map((contribution, index) => {
              return {
                index: index,
                results: contribution.results,
              };
            });

          setBonusValidationResult(newBonusContributionValidation);
          break;
        case 1:
          const newPenaltyContributions: BillingsTaxiDriverContributionPenalty[] =
            billingFormData.billingContributions.penalty.filter(
              (contribution, index) => index !== contributionIndex
            );

          const updatedPenaltyBillingFormData = {
            ...billingFormData,
            billingContributions: {
              bonus: billingFormData.billingContributions.bonus,
              penalty: newPenaltyContributions,
              contractCorrection:
                billingFormData.billingContributions.contractCorrection,
            },
          };

          recalculateBilling(updatedPenaltyBillingFormData);
          setBillingFormData(updatedPenaltyBillingFormData);

          const newPenaltyContributionValidation = penaltyValidationResult
            .filter(
              (contributionValidation) =>
                contributionValidation.index !== contributionIndex
            )
            .map((contribution, index) => {
              return {
                index: index,
                results: contribution.results,
              };
            });

          setPenaltyValidationResult(newPenaltyContributionValidation);
          break;
        case 2:
          const newContractCorrectionContributions: BillingsTaxiDriverContributionContractCorrection[] =
            billingFormData.billingContributions.contractCorrection.filter(
              (contribution, index) => index !== contributionIndex
            );

          const updatedContractCorrectionBillingFormData = {
            ...billingFormData,
            billingContributions: {
              bonus: billingFormData.billingContributions.bonus,
              penalty: billingFormData.billingContributions.penalty,
              contractCorrection: newContractCorrectionContributions,
            },
          };

          recalculateBilling(updatedContractCorrectionBillingFormData);
          setBillingFormData(updatedContractCorrectionBillingFormData);

          const newContractCorrectionContributionValidation =
            contractCorrectionValidationResult
              .filter(
                (contributionValidation) =>
                  contributionValidation.index !== contributionIndex
              )
              .map((contribution, index) => {
                return {
                  index: index,
                  results: contribution.results,
                };
              });

          setContractCorrectionValidationResult(
            newContractCorrectionContributionValidation
          );

          break;
      }
    }, 100),
    [activeTab, billingFormData]
  );

  const onContributionAdd = useCallback(
    debounce(() => {
      if (!billingFormData) {
        return [];
      }

      switch (activeTab) {
        case 0:
          const newBonusContribution =
            billingsTaxiDriverContributionsBonusHelper.getBonusNewContribution();
          const newBonusContributions = [
            ...billingFormData.billingContributions.bonus,
            newBonusContribution,
          ];

          const newBonusBillingFormData: BillingFormData = {
            ...billingFormData,
            billingContributions: {
              bonus: newBonusContributions,
              penalty: billingFormData.billingContributions.penalty,
              contractCorrection:
                billingFormData.billingContributions.contractCorrection,
            },
          };

          setBillingFormData(newBonusBillingFormData);
          const newBonusContributionValidation = {
            index: bonusValidationResult.length,
            results: {
              amount: formValidationService.defaultValidationResult,
              comment: formValidationService.defaultValidationResult,
              type: formValidationService.defaultValidationResult,
              distance: formValidationService.defaultValidationResult,
              rate: formValidationService.defaultValidationResult,
            },
          };
          const newBonusContributionValidations = [
            ...bonusValidationResult,
            newBonusContributionValidation,
          ];
          setBonusValidationResult(newBonusContributionValidations);

          break;
        case 1:
          const newPenaltyContribution =
            billingsTaxiDriverContributionsPenaltyHelper.getNewPenaltyContribution();

          const newPenaltyContributions = [
            ...billingFormData.billingContributions.penalty,
            newPenaltyContribution,
          ];

          const newPenaltyBillingFormData: BillingFormData = {
            ...billingFormData,
            billingContributions: {
              bonus: billingFormData.billingContributions.bonus,
              penalty: newPenaltyContributions,
              contractCorrection:
                billingFormData.billingContributions.contractCorrection,
            },
          };

          setBillingFormData(newPenaltyBillingFormData);
          const newPenaltyContributionValidation = {
            index: penaltyValidationResult.length,
            results: {
              amount: formValidationService.defaultValidationResult,
              comment: formValidationService.defaultValidationResult,
              type: formValidationService.defaultValidationResult,
            },
          };
          const newPenaltyContributionValidations = [
            ...penaltyValidationResult,
            newPenaltyContributionValidation,
          ];
          setPenaltyValidationResult(newPenaltyContributionValidations);

          break;
        case 2:
          const newContractCorrectionContribution =
            billingsTaxiDriverContributionsContractCorrectionHelper.getNewContractCorrectionContribution();

          const newContractCorrectionContributions = [
            ...billingFormData.billingContributions.contractCorrection,
            newContractCorrectionContribution,
          ];

          const newContractCorrectionBillingFormData: BillingFormData = {
            ...billingFormData,
            billingContributions: {
              bonus: billingFormData.billingContributions.bonus,
              penalty: billingFormData.billingContributions.penalty,
              contractCorrection: newContractCorrectionContributions,
            },
          };

          setBillingFormData(newContractCorrectionBillingFormData);
          const newContractCorrectionContributionValidation = {
            index: contractCorrectionValidationResult.length,
            results: {
              amount: formValidationService.defaultValidationResult,
              type: formValidationService.defaultValidationResult,
              distance: formValidationService.defaultValidationResult,
              rate: formValidationService.defaultValidationResult,
            },
          };
          const newContractCorrectionContributionValidations = [
            ...contractCorrectionValidationResult,
            newContractCorrectionContributionValidation,
          ];

          setContractCorrectionValidationResult(
            newContractCorrectionContributionValidations
          );
          break;
      }
    }, 100),
    [activeTab, billingFormData]
  );

  const maxOptions = (tabIndex: number) => {
    switch (tabIndex) {
      case 0:
        return Object.keys(BillingsTaxiDriverContributionBonusType).length;
      case 1:
        return Object.keys(BillingsTaxiDriverContributionPenaltyType).length;
      case 2:
        return Object.keys(BillingsTaxiDriverContributionContractCorrectionType)
          .length;
      default:
        return 0;
    }
  };

  const maxOptionsValue = useMemo(() => {
    if (!maxOptions) {
      return 0;
    }

    return maxOptions(activeTab);
  }, [activeTab]);

  const tabsData: TabsData = useMemo(() => {
    if (!billingFormData) {
      return [];
    }

    return billingDataFactory.createTabsData(
      billingFormData.billingContributions,
      isBillingDataLoading,
      billingFormData,
      onContributionTypeChange,
      onAmountChange,
      onCommentChange,
      onCommentBlur,
      onAmountBlur,
      onTypeBlur,
      onDistanceChange,
      onDistanceBlur,
      onRateChange,
      onRateBlur,
      onContributionDelete,
      bonusValidationResult,
      penaltyValidationResult,
      contractCorrectionValidationResult
    );
  }, [
    billingFormData,
    bonusValidationResult,
    penaltyValidationResult,
    contractCorrectionValidationResult,
    selectedAppLanguage,
    activeTab,
  ]);

  const onOrderHistoryButtonClick = () => {
    if (shouldShowOrderHistory) {
      setShouldShowOrderHistory(false);
      return;
    }

    if (!billingData?.solvedOrderUuids) {
      return;
    }

    if (billingData?.solvedOrderUuids?.length > 0) {
      const firstOrderUuid = billingData.solvedOrderUuids[0];
      const firstOrderHumanId = billingData.solvedOrderHumanIds[0];

      setPlanEntryUuid(firstOrderUuid);
      setSelectedCargoOrderHumanId(firstOrderHumanId);
    }

    setShouldShowOrderHistory(true);
  };

  const billingsTaxiDriverToolsProps = {
    shouldShowOrderHistory,
    onOrderHistoryButtonClick,
  };

  return (
    <>
      <div className="billings">
        <HeadingComponent
          title={translations.header.headingText.replace("#{routeId}", routeId)}
        />
        <Row>
          <Column lg={8}>
            <Row>
              <Column withPaddings>
                <div className="billings_map_wrapper">
                  <MapComponent
                    markers={mapMarkers}
                    autoFit
                    autoFitOnUpdate
                    routes={mapRoutes}
                  />
                  <BillingsToolsComponent {...billingsTaxiDriverToolsProps} />
                </div>
              </Column>
              <Column withPaddings>
                <ContractDetailsComponent
                  isLoading={isBillingDataLoading}
                  contractDetails={billingData?.contractDetails ?? null}
                />
              </Column>
              <Column withPaddings>
                <Row>
                  <Column lg={9}>
                    <BillingsRouteDetailsFormComponent
                      billingNodes={billingFormData?.billingNodes}
                      formValidationResults={
                        formValidationResults.nodeValidations
                      }
                      onBillingNodeChanged={onBillingNodeChanged}
                      onInputBlur={onInputBlur}
                      onCopyFromPlannedDistance={onCopyFromPlannedDistance}
                      validationOnBlur={validationOnBlur}
                    />
                  </Column>
                  <Column lg={3}>
                    <AdditionalOptionsComponent
                      billingData={billingFormData}
                      onBonusChanged={onBonusChanged}
                      onBonusTypeChanged={onBonusTypeChanged}
                      onPenaltyChanged={onPenaltyChanged}
                      onPenaltyTypeChanged={onPenaltyTypeChanged}
                      onPenaltyBlur={onPenaltyBlur}
                      onBonusBlur={onBonusBlur}
                      formValidationResults={formValidationResults}
                    />
                  </Column>
                </Row>
              </Column>
              <Column withPaddings>
                <BillingRouteDetailsComponent billingData={billingData} />
              </Column>
              {!!billingData && (
                <Column withPaddings>
                  <TabsComponent
                    data={tabsData}
                    onActiveTabChange={setActiveTab}
                  />
                  <div className="billings_contribution_add_button">
                    <ButtonComponent
                      onClick={onContributionAdd}
                      type="success"
                      title={translations.tabs.contributionAddButtonTitle}
                      isDisabled={
                        tabsData[activeTab].counter >= maxOptionsValue ||
                        hasBaseAmountExternalTaxi
                      }
                    >
                      {translations.tabs.contributionAddButtonLabel}
                    </ButtonComponent>
                  </div>
                </Column>
              )}
              <Column withPaddings>
                <CardComponent
                  classNames={{ root: "billing_details" }}
                  header={{ title: translations.billingSummary.summaryLabel }}
                >
                  <BillingDetailsComponent billingData={billingData} />
                </CardComponent>
              </Column>
              <Column>
                <ButtonComponent type="primary" onClick={openModal}>
                  {translations.submitLabel}
                </ButtonComponent>
              </Column>
              <Column withPaddings>
                <RelatedBillingsTaxiDriverComponent
                  planEntryId={planEntryUuid}
                />
              </Column>
              <Column withPaddings>
                <RelatedBillingsTaxiTaxiComponent planEntryId={planEntryUuid} />
              </Column>
            </Row>
          </Column>
          <Column lg={4} withPaddings>
            <CardComponent classNames={{ root: "h-50", content: "h-100" }}>
              {shouldShowOrderHistory ? (
                <ContentWrapper asCardComponent={props.asCardComponent}>
                  <div className="d-flex mb-2">
                    {billingData?.solvedOrderUuids.map(
                      (solvedOrderUuid, index) => {
                        const solvedOrderHumanId =
                          billingData.solvedOrderHumanIds[index];
                        const isSelected = planEntryUuid === solvedOrderUuid;

                        return (
                          <ButtonComponent
                            key={solvedOrderUuid}
                            onClick={() =>
                              onCargoOrderSelectButtonClick(
                                solvedOrderUuid,
                                solvedOrderHumanId
                              )
                            }
                            type={isSelected ? "success" : undefined}
                          >
                            {solvedOrderHumanId}
                          </ButtonComponent>
                        );
                      }
                    )}
                  </div>
                  <div>
                    <OrderDetailsHistoryComponent
                      orderUuid={planEntryUuid}
                      refetchFlag={false}
                    />
                  </div>
                </ContentWrapper>
              ) : (
                !!planEntryUuid.length && (
                  <MessengerComponent
                    channelsAvailability={messengerChannelsAvailability}
                    planEntryUuid={planEntryUuid}
                  />
                )
              )}
            </CardComponent>
          </Column>
        </Row>
        <BillingsSummaryConfirmationComponent
          isVisible={isSummaryConfirmationVisible}
          onSubmit={onBillingDataSave}
          onClose={onModalClose}
          billingData={billingData!}
          isLoading={isBillingSavePending}
        />
      </div>
    </>
  );
};

export default BillingsTaxiDriverEditComponent;
