import { BillingsTaxiDriverContributionBonusType } from "../../common/contributions/bonus/types/billings-taxi-driver-contributions-bonus";
import { BillingsTaxiDriverContributionContractCorrectionType } from "../../common/contributions/contract-correction/types/billings-taxi-driver-contributions-contract-correction";
import { BillingsTaxiDriverContributionPenaltyType } from "../../common/contributions/penalty/types/billings-taxi-driver-contributions-penalty";
import BillingSaveRequest, {
  BillingSaveRequestBonusContributionType,
  BillingSaveRequestContractCorrectionContributionType,
  BillingSaveRequestContribution,
  BillingSaveRequestPenaltyContributionType,
} from "../api/billing-save.request";
import BillingNodeApi from "../api/types/billing-node";
import BillingFormData, {
  BillingFormDataContributions,
} from "../types/billing-form.data";

const createSaveBillingBonusContributionType = (
  contributionType: BillingsTaxiDriverContributionBonusType
): BillingSaveRequestBonusContributionType => {
  switch (contributionType) {
    case BillingsTaxiDriverContributionBonusType.BONUS_FAVORABLE_DISTANCE:
      return BillingSaveRequestBonusContributionType.BONUS_FAVORABLE_DISTANCE;
    case BillingsTaxiDriverContributionBonusType.BONUS_OTHER:
      return BillingSaveRequestBonusContributionType.BONUS_OTHER;
  }
};

const createSaveBillingPenaltyContributionType = (
  contributionType: BillingsTaxiDriverContributionPenaltyType
): BillingSaveRequestPenaltyContributionType => {
  switch (contributionType) {
    case BillingsTaxiDriverContributionPenaltyType.PENALTY_BEING_LATE:
      return BillingSaveRequestPenaltyContributionType.PENALTY_BEING_LATE;
    case BillingsTaxiDriverContributionPenaltyType.PENALTY_INCOMPATIBLE_CAR:
      return BillingSaveRequestPenaltyContributionType.PENALTY_INCOMPATIBLE_CAR;
    case BillingsTaxiDriverContributionPenaltyType.PENALTY_OTHER:
      return BillingSaveRequestPenaltyContributionType.PENALTY_OTHER;
    case BillingsTaxiDriverContributionPenaltyType.PENALTY_WRONG_ROUTE:
      return BillingSaveRequestPenaltyContributionType.PENALTY_WRONG_ROUTE;
  }
};

const createSaveBillingContractCorrectionContributionType = (
  contributionType: BillingsTaxiDriverContributionContractCorrectionType
): BillingSaveRequestContractCorrectionContributionType => {
  switch (contributionType) {
    case BillingsTaxiDriverContributionContractCorrectionType.BASE_AMOUNT_EXTERNAL_TAXI:
      return BillingSaveRequestContractCorrectionContributionType.BASE_AMOUNT_EXTERNAL_TAXI;
    case BillingsTaxiDriverContributionContractCorrectionType.CONTRACT_CORRECTION_RATE:
      return BillingSaveRequestContractCorrectionContributionType.CONTRACT_CORRECTION_RATE;
  }
};

const createSaveBillingContributions = (
  contributions: BillingFormDataContributions
): BillingSaveRequestContribution[] => {
  const bonusContributions = contributions.bonus
    .filter(
      (contribution) =>
        contribution.type &&
        [
          BillingsTaxiDriverContributionBonusType.BONUS_FAVORABLE_DISTANCE,
          BillingsTaxiDriverContributionBonusType.BONUS_OTHER,
        ].includes(contribution.type)
    )
    .map((contribution) => {
      switch (contribution.type) {
        case BillingsTaxiDriverContributionBonusType.BONUS_FAVORABLE_DISTANCE:
          const bonusFavorableDistance: BillingSaveRequestContribution = {
            type: createSaveBillingBonusContributionType(contribution.type!),
            distance: contribution.distance ?? 0,
            rate: contribution.rate ?? 0,
          } as BillingSaveRequestContribution;
          return bonusFavorableDistance;

        case BillingsTaxiDriverContributionBonusType.BONUS_OTHER:
          const bonusOtherRate: BillingSaveRequestContribution = {
            type: createSaveBillingBonusContributionType(contribution.type!),
            comment: contribution.comment ?? "",
            amount: contribution.amount ?? 0,
          } as BillingSaveRequestContribution;
          return bonusOtherRate;
      }
    });

  const contractCorrectionContributions = contributions.contractCorrection
    .filter(
      (contribution) =>
        contribution.type &&
        [
          BillingsTaxiDriverContributionContractCorrectionType.BASE_AMOUNT_EXTERNAL_TAXI,
          BillingsTaxiDriverContributionContractCorrectionType.CONTRACT_CORRECTION_RATE,
        ].includes(contribution.type)
    )
    .map((contribution) => {
      switch (contribution.type) {
        case BillingsTaxiDriverContributionContractCorrectionType.CONTRACT_CORRECTION_RATE:
          const contractCorrection: BillingSaveRequestContribution = {
            type: createSaveBillingContractCorrectionContributionType(
              contribution.type!
            ),
            rate: contribution.rate ?? 0,
          } as BillingSaveRequestContribution;
          return contractCorrection;
        case BillingsTaxiDriverContributionContractCorrectionType.BASE_AMOUNT_EXTERNAL_TAXI:
          const baseAmount: BillingSaveRequestContribution = {
            type: createSaveBillingContractCorrectionContributionType(
              contribution.type!
            ),
            amount: contribution.amount ?? 0,
            distance: contribution.distance ?? 0,
          } as BillingSaveRequestContribution;
          return baseAmount;
      }
    });

  const penaltyContributions = contributions.penalty
    .filter(
      (contribution) =>
        contribution.type &&
        [
          BillingsTaxiDriverContributionPenaltyType.PENALTY_BEING_LATE,
          BillingsTaxiDriverContributionPenaltyType.PENALTY_INCOMPATIBLE_CAR,
          BillingsTaxiDriverContributionPenaltyType.PENALTY_OTHER,
          BillingsTaxiDriverContributionPenaltyType.PENALTY_WRONG_ROUTE,
        ].includes(contribution.type)
    )
    .map((contribution) => {
      switch (contribution.type) {
        case BillingsTaxiDriverContributionPenaltyType.PENALTY_BEING_LATE:
        case BillingsTaxiDriverContributionPenaltyType.PENALTY_INCOMPATIBLE_CAR:
        case BillingsTaxiDriverContributionPenaltyType.PENALTY_OTHER:
        case BillingsTaxiDriverContributionPenaltyType.PENALTY_WRONG_ROUTE:
          const penalty: BillingSaveRequestContribution = {
            type: createSaveBillingPenaltyContributionType(contribution.type!),
            comment: contribution.comment ?? "",
            amount: contribution.amount ?? 0,
          } as BillingSaveRequestContribution;
          return penalty;
      }
    });

  return [
    ...(bonusContributions as BillingSaveRequestContribution[]),
    ...(penaltyContributions as BillingSaveRequestContribution[]),
    ...(contractCorrectionContributions as BillingSaveRequestContribution[]),
  ];
};

const createSaveBillingRequest = (
  data: BillingFormData
): BillingSaveRequest => {
  const result: BillingSaveRequest = {
    billing_nodes: data.billingNodes.map((specificNode) => {
      const node: BillingNodeApi = {
        id: specificNode.id,
        node_id: specificNode.nodeId,
        order_id: specificNode.orderId,
        lat: specificNode.lat,
        lon: specificNode.lon,
        distance: specificNode.distance,
        description: specificNode.description,
        checkout_date: specificNode.checkoutDate?.toJSON() ?? null,
        checkout_event_id: specificNode.checkoutEventUuid,
        planned_date: specificNode.plannedDate?.toJSON() ?? null,
        halting_amount: specificNode.haltingAmount,
        halting_time: specificNode.haltingTime,
        allow_charge: specificNode.isHighwayAllowed,
        highway_charge: specificNode.highwayCharge,
        planned_distance: specificNode.plannedDistance,
        editable_coordinates: specificNode.isEditable,
      };
      return node;
    }),
    bonus_type: data.bonusType,
    penalty_type: data.penaltyType,
    bonus: 0,
    penalty: 0,
    billing_type: "TAXI_2_DRIVER",
    billing_contributions: createSaveBillingContributions(
      data.billingContributions
    ),
  };
  return result;
};

const billingSaveRequestFactory = {
  createSaveBillingRequest,
};

export default billingSaveRequestFactory;
