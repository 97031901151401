import { FC, useCallback, useEffect, useMemo, useState } from "react";
import BillingFormData from "./types/billing-form.data";
import billingDataRouteRequestsFactory from "./factory/billing-data-route-requests.factory";
import billingApiService from "./api/billings-cargo-taxi-api.service";
import billingMapRoutesFactory from "./factory/billing-map-routes.factory";
import BillingsNode from "../../common/types/billings-node";
import billingRecalculateRequestFactory from "./factory/billing-recalculate-request.factory";
import BillingDataResponse from "./api/billing-data.response";
import billingDataFactory from "./factory/billing-data.factory";
import BillingDetailsComponent from "./billing-details/billing-details.component";
import ContractDetailsComponent from "./contract-details/contract-details.component";
import AdditionalOptionsComponent from "./additional-options/additional-options.component";
import billingsApiService from "./api/billings-api.service";
import BillingGpsResponse from "./api/billing-gps-data.response";
import billingSaveRequestFactory from "./factory/billing-save-request.factory";
import BillingSaveResponse from "./api/billing-save.response";
import MapRoute from "../../../../common/components/map/types/map-route";
import MapMarker from "../../../../common/components/map/types/map-marker";
import Row from "../../../../common/components/grid/row";
import Column from "../../../../common/components/grid/column";
import MapComponent from "../../../../common/components/map/map.component";
import ButtonComponent from "../../../../common/components/button/button.component";
import CardComponent from "../../../../common/components/card/card.component";
import SearchRoadRoutingResponse from "../../../../common/utils/search-road-route/search-road-routing.response";
import billingsTranslationsHelper from "../../../../languages/billings-translations.helper";
import { useNavigate, useParams } from "react-router-dom";
import BillingCargoTaxiEditRouteParams from "../../common/routes/types/billing-cargo-taxi-edit-route-params";
import formValidationService from "../../../../common/utils/validation/form-validation.service";
import notificationService from "../../../../common/utils/notification/notification.service";
import settlementRoutesHelper from "../../../settlement/common/routes/settlement-routes.helper";
import { useAppContext } from "../../../../context/app.context";
import appTranslationsHelper from "../../../../languages/app-translations.helper";
import useDocumentTitle from "../../../../common/hooks/use-document-title";
import billingBreadcrumbsHelper from "../../common/breadcrumbs/billings-breadcrumbs.helper";
import HeadingComponent from "../../../../common/components/heading/heading.component";
import BillingsSummaryConfirmationComponent from "./billings-summary-confirmation.component";
import RelatedBillingsTaxiDriverComponent from "../common/related-billings/taxi-driver/related-billings-taxi-driver.component";
import RelatedBillingsTaxiTaxiComponent from "../common/related-billings/taxi-taxi/related-billings-taxi-taxi.component";
import BillingStatus from "../../types/billing-status";
import billingRouteDetailsHelper from "../../common/route-details/common/billings-route-details.helper";
import BillingRouteDetailsFormDataValidationResult, {
  NodeValidationResult,
} from "../../common/route-details/types/billings-route-details-form-data-validation-result";
import billingRouteDetailsDataValidationService from "../../common/route-details/common/billing-route-details-form-data-validation.service";
import BillingsRouteDetailsFormComponent from "../../common/route-details/billings-route-details-form.component";
import MessengerComponent from "../../../../common/components/messenger/messenger.component";
import MessengerBillingType from "../../../../common/components/messenger/types/messenger-billing-type";
import billingsCargoTaxiHelper from "./billings-cargo-taxi.helper";
import billingsMapMarkersFactory from "../../common/map-markers/billings-map-markers.factory";
import TabsData from "../../../../common/components/tabs/common/types/tabs-data";
import BillingRouteDetailsComponent from "./billing-route-details/billing-route-details.component";
import TabsComponent from "../../../../common/components/tabs/tabs.component";
import OrderDetailsHistoryComponent from "../../../order/details/history/order-details-history.component";
import {
  BillingsCargoTaxiContributionContractCorretionPercentage,
  BillingsCargoTaxiContributionDiscountBeingLate,
  BillingsCargoTaxiContributionDiscountIncompatibleCar,
  BillingsCargoTaxiContributionDiscountOptimization,
  BillingsCargoTaxiContributionDiscountOther,
  BillingsCargoTaxiContributionDiscountType,
} from "../common/contributions/discount/types/billings-cargo-taxi-contributions-discount";
import billingsCargoTaxiContributionsDiscountHelper from "../common/contributions/discount/billings-cargo-taxi-contributions-discount.helper";
import BillingsToolsComponent from "../../common/tools/billings-tools.component";
import DiscountValidationResult from "./types/billing-discount-validation-result";
import billingCargoTaxiContributionsDiscountValidationService from "../common/contributions/discount/billings-cargo-taxi-contributions-discount-validation.service";
import { debounce } from "lodash";
import BillingsCargoTaxiContribution from "../common/contributions/discount/types/billings-cargo-taxi-contributions-discount";

type BillingComponentProps = {};

const BillingsCargoTaxiEditComponent: FC<BillingComponentProps> = () => {
  const { billingUuid } = useParams<BillingCargoTaxiEditRouteParams>();

  const { setBreadcrumbs, selectedAppLanguage } = useAppContext();

  const [isBillingSavePending, setIsBillingSavePending] = useState(false);

  const [orderInternalId, setOrderInternalId] = useState("");

  const [discountValidationResult, setDiscountValidationResult] = useState<
    DiscountValidationResult[]
  >([]);

  const [billingData, setBillingData] = useState<BillingFormData>();
  const [billingFormData, setBillingFormData] = useState<BillingFormData>();
  const [isSummaryConfirmationVisible, setIsSummaryConfirmationVisible] =
    useState(false);

  const [orderUuid, setOrderUuid] = useState("");

  const [mapMarkers, setMapMarkers] = useState<MapMarker[]>();
  const [plannedMapRoute, setPlannedMapRoute] = useState<MapRoute | null>(null);
  const [completedMapRoute, setCompletedMapRoute] = useState<MapRoute | null>(
    null
  );
  const [activeTab, setActiveTab] = useState(0);

  const [shouldRetrieveRouteData, setShouldRetrieveRouteData] = useState(false);

  const [isBillingDataLoading, setIsBillingDataLoading] = useState(false);

  const [formValidationResults, setFormValidationResults] =
    useState<BillingRouteDetailsFormDataValidationResult>({
      discountValue: formValidationService.defaultValidationResult,
      nodeValidations: [],
    });

  const [shouldShowOrderHistory, setShouldShowOrderHistory] = useState(false);

  const navigate = useNavigate();

  const documentTitle = appTranslationsHelper
    .getDocumentTitleTranslations()
    .billingsCargoWithTaxiEdit.replace("#{orderInternalId}", orderInternalId);

  useDocumentTitle(documentTitle);

  useEffect(() => {
    const breadcrumbs =
      billingBreadcrumbsHelper.getCargoWithTaxiEditBreadcrumbs({
        billingUuid: billingUuid!,
        orderInternalId,
      });

    setBreadcrumbs(breadcrumbs);
  }, [selectedAppLanguage, orderInternalId]);

  const onModalClose = () => {
    setIsSummaryConfirmationVisible(false);
  };

  const openModal = () => {
    setIsSummaryConfirmationVisible(true);
  };

  useEffect(() => {
    if (!billingUuid) {
      return;
    }

    loadBillingContributions();
  }, [billingUuid]);

  const loadBillingContributions = () => {
    if (!billingUuid) {
      return;
    }

    setIsBillingDataLoading(true);
    billingApiService
      .fetchBillingData(billingUuid)
      .then(handleBillingDataResponse)
      .finally(() => {
        setIsBillingDataLoading(false);
      });
  };

  useEffect(() => {
    if (!orderUuid) {
      return;
    }
    billingsApiService.fetchGpsData(orderUuid).then(handleGpsDataResponse);
  }, [orderUuid, billingData]);

  useEffect(() => {
    if (!billingData || !shouldRetrieveRouteData) {
      return;
    }

    const mapMarkers = billingsMapMarkersFactory.createMapMarkers(
      billingData.billingNodes
    );

    const routeRequests =
      billingDataRouteRequestsFactory.createBillingDataRouteRequests(
        billingData.billingNodes
      );

    const fetchPromises: Promise<SearchRoadRoutingResponse>[] = [];

    routeRequests.forEach((routeRequest) => {
      const fetch = billingApiService.fetchRoute(routeRequest);

      fetchPromises.push(fetch);
    });

    Promise.all(fetchPromises).then((responses) => {
      const mapRouteWaypointGroups: MapRoute["waypoints"][] = [];

      responses.forEach((response, index) => {
        const mapRoute = response.routes[0]
          ? billingMapRoutesFactory.createMapRoute(
              response.routes[0].geometry.coordinates
            )
          : null;

        if (mapRoute?.waypoints) {
          mapRouteWaypointGroups.push(mapRoute.waypoints);
        }
      });

      const newMapRouteWaypoints: MapRoute["waypoints"] = [];

      mapRouteWaypointGroups.forEach((waypoint) => {
        newMapRouteWaypoints.push(...waypoint);
      });

      const newMapRoute: MapRoute = {
        waypoints: newMapRouteWaypoints,
        options: { color: "red" },
      };

      setPlannedMapRoute(newMapRoute);
      setMapMarkers(mapMarkers);
      setShouldRetrieveRouteData(false);
    });
  }, [shouldRetrieveRouteData]);

  const recalculateBilling = useCallback(
    debounce((formData: BillingFormData) => {
      const recalculateRequest =
        billingRecalculateRequestFactory.createRecalculateRequest(formData!);

      billingApiService
        .recalculateBilling(orderUuid, recalculateRequest)
        .then(handleRecalculateResponse);
    }, 500),
    [orderUuid]
  );

  const handleBillingDataResponse = (response: BillingDataResponse) => {
    if (response.status === 200) {
      onBillingDataFetchSuccess(response);
    }
  };

  const handleGpsDataResponse = (response: BillingGpsResponse) => {
    if (response.status === 200) {
      onGpsDataFetchSuccess(response);
    }
  };

  const handleRecalculateResponse = (response: BillingDataResponse) => {
    if (response.status === 200) {
      onRecalculateSuccess(response);
    }
  };

  const navigateToListing = () => {
    navigate(settlementRoutesHelper.getTaxiListingOfSettledOrdersRoute());
  };

  const handleBillingSaveResponse = (response: BillingSaveResponse) => {
    if (response.status === 200) {
      notificationService.success(translations.successNotificationText);
      navigateToListing();

      return;
    }
    notificationService.error(translations.failureNotificationText);
  };

  const onBillingDataFetchSuccess = (response: BillingDataResponse) => {
    const billingData = billingDataFactory.createBillingData(response.data);

    setBillingData(billingData);
    setBillingFormData(billingData);
    setDiscountValidationResult(
      billingData.billingDiscountContributions.map((contribution, index) => {
        return {
          index: index,
          results: {
            amount: formValidationService.defaultValidationResult,
            comment: formValidationService.defaultValidationResult,
            type: formValidationService.defaultValidationResult,
            percentage: formValidationService.defaultValidationResult,
          },
        };
      })
    );
    setOrderUuid(response.data.cargo_order.id);
    setOrderInternalId(String(response.data.cargo_order.human_id));
    setShouldRetrieveRouteData(true);

    setFormValidationResults((current) => ({
      discountValue: current.discountValue,
      nodeValidations: billingData.billingNodes.map((x) => {
        return {
          haltingTime: formValidationService.defaultValidationResult,
          distance: formValidationService.defaultValidationResult,
          highwayCharge: formValidationService.defaultValidationResult,
          position: x.position,
        };
      }),
    }));
  };

  const onCopyFromPlannedDistance = () => {
    const newBillingNodes = billingFormData?.billingNodes.map((node) => {
      node.distance = node.plannedDistance;

      return node;
    });

    const newBillingFormData: BillingFormData = {
      ...billingFormData!,
      billingNodes: newBillingNodes!,
    };

    setBillingFormData(newBillingFormData);

    const result = billingRouteDetailsHelper.validateAllFieldsByType(
      formValidationResults,
      newBillingFormData.billingNodes,
      "distance"
    );

    nodeValidationResults(result.nodeResult!);

    if (result.isAllFormValid) recalculateBilling(newBillingFormData);
  };

  const onGpsDataFetchSuccess = (response: BillingGpsResponse) => {
    const gpsData: MapRoute = {
      waypoints: response.data.map((x) => {
        return { latitude: x.lat, longitude: x.lon };
      }),
      options: { color: "blue" },
    };

    setCompletedMapRoute(gpsData);
  };

  const onBillingDataSave = () => {
    if (!billingFormData) {
      return;
    }

    const areNodesValid =
      billingFormData?.billingNodes
        .map((node) => {
          return billingRouteDetailsHelper.haltingTimeValidation(
            formValidationResults,
            node.haltingTime,
            node.position
          );
        })
        .every((x) => x) ?? false;

    const isDiscountValid = validateDiscount();

    const contributionValidation =
      billingFormData.billingDiscountContributions.map(
        (contribution, index) => {
          switch (contribution.type) {
            case BillingsCargoTaxiContributionDiscountType.CONTRACT_CORRECTION_PERCENTAGE:
              return {
                index: index,
                results: {
                  amount: formValidationService.defaultValidationResult,
                  comment: formValidationService.defaultValidationResult,
                  type: billingCargoTaxiContributionsDiscountValidationService.validateType(
                    contribution.type
                  ),
                  percentage:
                    billingCargoTaxiContributionsDiscountValidationService.validatePercentage(
                      contribution.percentage,
                      billingFormData.contractDetails?.discountLimit
                    ),
                },
              };
            case BillingsCargoTaxiContributionDiscountType.DISCOUNT_BEING_LATE:
            case BillingsCargoTaxiContributionDiscountType.DISCOUNT_INCOMPATIBLE_CAR:
            case BillingsCargoTaxiContributionDiscountType.DISCOUNT_OPTIMIZATION:
            case BillingsCargoTaxiContributionDiscountType.DISCOUNT_OTHER:
              return {
                index: index,
                results: {
                  amount:
                    billingCargoTaxiContributionsDiscountValidationService.validateAmount(
                      contribution.amount
                    ),
                  comment:
                    billingCargoTaxiContributionsDiscountValidationService.validateComment(
                      contribution.comment
                    ),
                  type: billingCargoTaxiContributionsDiscountValidationService.validateType(
                    contribution.type
                  ),
                  percentage: formValidationService.defaultValidationResult,
                },
              };
            default:
              return {
                index: index,
                results: {
                  amount: formValidationService.defaultValidationResult,
                  comment: formValidationService.defaultValidationResult,
                  type: formValidationService.defaultValidationResult,
                  percentage: formValidationService.defaultValidationResult,
                },
              };
          }
        }
      );

    const areContributionsValid: boolean = contributionValidation
      .map(
        (result) =>
          result.results.amount.isValid &&
          result.results.comment.isValid &&
          result.results.type.isValid &&
          result.results.percentage.isValid
      )
      .reduce((previous, current) => previous && current, true);

    setDiscountValidationResult(contributionValidation);

    const isFormValid =
      isDiscountValid && areNodesValid && areContributionsValid;

    if (!isFormValid) {
      notificationService.error(translations.failureValidationNotificationText);
      onModalClose();
      return;
    }

    if (billingFormData) {
      setIsBillingSavePending(true);
      const data = billingSaveRequestFactory.createSaveBillingRequest(
        billingFormData.billingNodes,
        billingFormData.discount,
        billingFormData.billingDiscountContributions
      );

      billingApiService
        .saveBillingData(billingUuid!, data)
        .then(handleBillingSaveResponse)
        .finally(() => setIsBillingSavePending(false));
    }
  };

  const validationOnBlur = (position: number, fieldName: string) => {
    type Node = keyof typeof node;

    const node = billingFormData?.billingNodes.find(
      (x) => x.position === position
    );

    const fieldToValidate = node && node[fieldName as Node];

    const results = billingRouteDetailsHelper.validationOnBlur(
      fieldToValidate,
      formValidationResults,
      position,
      fieldName
    );

    nodeValidationResults(results.nodeResult!);

    if (results.isAllFormValid) recalculateBilling(billingFormData!);
  };

  const nodeValidationResults = (node: NodeValidationResult[]) => {
    setFormValidationResults((curr) => ({
      ...curr,
      nodeValidations: node,
    }));
  };

  const onDiscountBlur = () => {
    const isDiscountValid = validateDiscount();

    if (isDiscountValid) {
      recalculateBilling(billingFormData!);
    }
  };

  const validateDiscount = () => {
    const maximumDiscountValue = billingData?.contractDetails?.discountLimit
      ? billingData?.contractDetails?.discountLimit
      : 0;

    const validationResult =
      billingRouteDetailsDataValidationService.validateDiscount(
        billingFormData?.discount ?? 0,
        maximumDiscountValue
      );

    setFormValidationResults((curr) => ({
      ...curr,
      discountValue: validationResult,
    }));

    return validationResult.isValid;
  };

  const onBillingNodeChanged = (node: BillingsNode) => {
    const nodes = billingData?.billingNodes.filter(
      (x) => x.position !== node.position
    )!;

    nodes.push(node);

    const sortedArray = nodes.sort((a, b) =>
      a.position < b.position ? -1 : 1
    );

    setBillingFormData((current) => ({
      ...current!,
      billingNodes: sortedArray,
    }));

    setShouldRetrieveRouteData(true);
  };

  const onDiscountChanged = (discountValue: number) => {
    setBillingFormData((current) => ({ ...current!, discount: discountValue }));
  };

  const onInputBlur = () => {
    recalculateBilling(billingFormData!);
  };

  const onRecalculateSuccess = (response: BillingDataResponse) => {
    const billingData = billingDataFactory.createBillingData(response.data);
    setBillingFormData(billingData);
    setBillingData(billingData);
    setDiscountValidationResult(
      billingData.billingDiscountContributions.map((contribution, index) => {
        return {
          index: index,
          results: {
            amount: formValidationService.defaultValidationResult,
            comment: formValidationService.defaultValidationResult,
            type: formValidationService.defaultValidationResult,
            percentage: formValidationService.defaultValidationResult,
          },
        };
      })
    );
  };

  const mapRoutes: MapRoute[] = useMemo(() => {
    const finalMapRoutes: MapRoute[] = [];
    if (plannedMapRoute) {
      finalMapRoutes.push(plannedMapRoute);
    }
    if (completedMapRoute) {
      finalMapRoutes.push(completedMapRoute);
    }
    return finalMapRoutes;
  }, [plannedMapRoute, completedMapRoute]);

  const translations =
    billingsTranslationsHelper.getCargoTaxiEditBillingsTranslations();

  const isSettleButtonVisible = billingData?.status !== BillingStatus.ACCEPTED;

  const messengerChannelsAvailability =
    billingsCargoTaxiHelper.getMessengerChannelAvailability();

  const onContributionTypeChange = (
    index: number,
    type: BillingsCargoTaxiContributionDiscountType | null
  ) => {
    setBillingFormData((currentValue) => {
      if (!currentValue) return;

      const billingDiscountContributions = [
        ...currentValue.billingDiscountContributions,
      ];
      billingDiscountContributions[index] = {
        ...billingDiscountContributions[index],
        type: type!,
      } as BillingsCargoTaxiContribution;

      const updatedFormData = {
        ...currentValue,
        billingDiscountContributions: billingDiscountContributions,
      };

      return updatedFormData;
    });
  };

  const onAmountChange = useCallback(
    debounce((index: number, value: number | null) => {
      if (!billingFormData) return;

      const billingDiscountContributions = [
        ...billingFormData.billingDiscountContributions,
      ];
      billingDiscountContributions[index] = {
        ...billingDiscountContributions[index],
        amount: value!,
      } as BillingsCargoTaxiContribution;

      const newAmountValue = {
        ...billingFormData,
        billingDiscountContributions: billingDiscountContributions,
      };

      setBillingFormData(newAmountValue);
    }, 300),
    [billingFormData]
  );

  const onPercentageChange = useCallback(
    debounce((index: number, value: number | null) => {
      if (!billingFormData) return;

      const billingDiscountContributions = [
        ...billingFormData.billingDiscountContributions,
      ];
      billingDiscountContributions[index] = {
        ...billingDiscountContributions[index],
        percentage: value!,
      } as BillingsCargoTaxiContributionContractCorretionPercentage;

      const newPercentageValue = {
        ...billingFormData,
        billingDiscountContributions: billingDiscountContributions,
      };

      setBillingFormData(newPercentageValue);
    }, 300),
    [billingFormData]
  );

  const onCommentChange = useCallback(
    debounce((index: number, value: string | null) => {
      setBillingFormData((currentValue) => {
        if (!currentValue) return;

        const billingDiscountContributions = [
          ...currentValue.billingDiscountContributions,
        ];

        billingDiscountContributions[index] = {
          ...billingDiscountContributions[index],
          comment: value!,
        } as BillingsCargoTaxiContribution;

        return {
          ...currentValue,
          billingDiscountContributions: billingDiscountContributions,
        };
      });
    }, 10),
    [billingFormData]
  );

  const onCommentBlur = (index: number) => {
    if (!billingFormData) {
      return;
    }

    if (
      billingFormData.billingDiscountContributions[index].type ===
      BillingsCargoTaxiContributionDiscountType.CONTRACT_CORRECTION_PERCENTAGE
    )
      return;

    const comment = (
      billingFormData.billingDiscountContributions[index] as
        | BillingsCargoTaxiContributionDiscountOptimization
        | BillingsCargoTaxiContributionDiscountBeingLate
        | BillingsCargoTaxiContributionDiscountIncompatibleCar
        | BillingsCargoTaxiContributionDiscountOther
    ).comment;
    const validationResult =
      billingCargoTaxiContributionsDiscountValidationService.validateComment(
        comment
      );

    setDiscountValidationResult((currentValue) =>
      currentValue.map((item) =>
        item.index === index
          ? { ...item, results: { ...item.results, comment: validationResult } }
          : item
      )
    );
    recalculateBilling(billingFormData);
  };

  const onAmountBlur = (index: number) => {
    if (!billingFormData) {
      return;
    }

    if (
      billingFormData.billingDiscountContributions[index].type ===
      BillingsCargoTaxiContributionDiscountType.CONTRACT_CORRECTION_PERCENTAGE
    )
      return;

    const amount = (
      billingFormData.billingDiscountContributions[index] as
        | BillingsCargoTaxiContributionDiscountOptimization
        | BillingsCargoTaxiContributionDiscountBeingLate
        | BillingsCargoTaxiContributionDiscountIncompatibleCar
        | BillingsCargoTaxiContributionDiscountOther
    ).amount;
    const validationResult =
      billingCargoTaxiContributionsDiscountValidationService.validateAmount(
        amount
      );

    setDiscountValidationResult((currentValue) =>
      currentValue.map((item) =>
        item.index === index
          ? { ...item, results: { ...item.results, amount: validationResult } }
          : item
      )
    );
    recalculateBilling(billingFormData);
  };

  const onPercentageBlur = (index: number) => {
    if (!billingFormData) {
      return;
    }

    if (
      billingFormData.billingDiscountContributions[index].type !==
      BillingsCargoTaxiContributionDiscountType.CONTRACT_CORRECTION_PERCENTAGE
    )
      return;

    const percentage = (
      billingFormData.billingDiscountContributions[
        index
      ] as BillingsCargoTaxiContributionContractCorretionPercentage
    ).percentage;
    const validationResult =
      billingCargoTaxiContributionsDiscountValidationService.validatePercentage(
        percentage,
        billingFormData.contractDetails?.discountLimit
      );

    setDiscountValidationResult((currentValue) =>
      currentValue.map((item) =>
        item.index === index
          ? {
              ...item,
              results: { ...item.results, percentage: validationResult },
            }
          : item
      )
    );
    recalculateBilling(billingFormData);
  };

  const onTypeBlur = (index: number) => {
    if (!billingFormData) {
      return;
    }

    const type = billingFormData.billingDiscountContributions[index].type;
    const validationResult =
      billingCargoTaxiContributionsDiscountValidationService.validateType(type);

    setDiscountValidationResult((currentValue) =>
      currentValue.map((item) =>
        item.index === index
          ? { ...item, results: { ...item.results, type: validationResult } }
          : item
      )
    );
    recalculateBilling(billingFormData);
  };

  const onContributionDelete = useCallback(
    debounce((contributionIndex: number) => {
      if (!billingFormData) {
        return [];
      }

      const newContributions: BillingsCargoTaxiContribution[] =
        billingFormData.billingDiscountContributions.filter(
          (contribution, index) => index !== contributionIndex
        );

      const updatedBillingFormData: BillingFormData = {
        ...billingFormData,
        billingDiscountContributions: newContributions,
      };

      recalculateBilling(updatedBillingFormData);
      setBillingFormData(updatedBillingFormData);

      const newContributionValidation = discountValidationResult
        .filter(
          (contributionValidation) =>
            contributionValidation.index !== contributionIndex
        )
        .map((contribution, index) => {
          return {
            index: index,
            results: contribution.results,
          };
        });

      setDiscountValidationResult(newContributionValidation);
    }, 100),
    [billingFormData]
  );

  const onContributionAdd = useCallback(
    debounce(() => {
      if (!billingFormData) {
        return [];
      }

      const newContribution =
        billingsCargoTaxiContributionsDiscountHelper.getDiscountContributionsNewContribution();
      const newContributions = [
        ...billingFormData.billingDiscountContributions,
        newContribution,
      ];
      const newBillingFormData: BillingFormData = {
        ...billingFormData,
        billingDiscountContributions: newContributions,
      };

      setBillingFormData(newBillingFormData);

      const newContributionValidation = {
        index: discountValidationResult.length,
        results: {
          amount: formValidationService.defaultValidationResult,
          comment: formValidationService.defaultValidationResult,
          type: formValidationService.defaultValidationResult,
          percentage: formValidationService.defaultValidationResult,
        },
      };
      const newContributionValidations = [
        ...discountValidationResult,
        newContributionValidation,
      ];

      setDiscountValidationResult(newContributionValidations);
    }, 100),
    [billingFormData]
  );

  const maxOptions = (tabIndex: number) => {
    switch (tabIndex) {
      case 0:
        return Object.keys(BillingsCargoTaxiContributionDiscountType).length;
      default:
        return 0;
    }
  };

  const maxOptionsValue = useMemo(() => {
    if (!maxOptions) {
      return 0;
    }

    return maxOptions(activeTab);
  }, [activeTab]);

  const tabsData: TabsData = useMemo(() => {
    if (!billingFormData) {
      return [];
    }

    return billingDataFactory.createTabsData(
      billingFormData.billingDiscountContributions,
      isBillingDataLoading,
      onContributionTypeChange,
      onAmountChange,
      onCommentChange,
      onPercentageChange,
      onCommentBlur,
      onAmountBlur,
      onTypeBlur,
      onPercentageBlur,
      onContributionDelete,
      discountValidationResult
    );
  }, [billingFormData, discountValidationResult, selectedAppLanguage]);

  const onOrderHistoryButtonClick = () => {
    if (shouldShowOrderHistory) {
      setShouldShowOrderHistory(false);
      return;
    }

    setShouldShowOrderHistory(true);
  };

  const billingsCargoTaxiToolsProps = {
    shouldShowOrderHistory,
    onOrderHistoryButtonClick,
  };

  return (
    <>
      <div className="billings">
        <HeadingComponent
          title={translations.header.headingText.replace(
            "#{orderInternalId}",
            orderInternalId
          )}
        />
        <Row>
          <Column lg={8}>
            <Row>
              <Column withPaddings>
                <div className="billings_map_wrapper">
                  <MapComponent
                    markers={mapMarkers}
                    autoFit
                    autoFitOnUpdate
                    routes={mapRoutes}
                  />
                  <BillingsToolsComponent {...billingsCargoTaxiToolsProps} />
                </div>
              </Column>
              <Column withPaddings>
                <ContractDetailsComponent
                  isLoading={isBillingDataLoading}
                  contractDetails={billingData?.contractDetails ?? null}
                />
              </Column>
              <Column withPaddings>
                <Row>
                  <Column lg={9}>
                    <BillingsRouteDetailsFormComponent
                      billingNodes={billingFormData?.billingNodes}
                      formValidationResults={
                        formValidationResults.nodeValidations
                      }
                      orderId={orderUuid}
                      onBillingNodeChanged={onBillingNodeChanged}
                      onInputBlur={onInputBlur}
                      onCopyFromPlannedDistance={onCopyFromPlannedDistance}
                      validationOnBlur={validationOnBlur}
                    />
                  </Column>
                  <Column lg={3}>
                    <AdditionalOptionsComponent
                      billingData={billingFormData}
                      onDiscountChanged={onDiscountChanged}
                      onDiscountBlur={onDiscountBlur}
                      formValidationResults={formValidationResults}
                    />
                  </Column>
                </Row>
              </Column>
              <Column withPaddings>
                <BillingRouteDetailsComponent billingData={billingData} />
              </Column>
              {!!billingData && (
                <Column withPaddings>
                  <TabsComponent
                    data={tabsData}
                    onActiveTabChange={setActiveTab}
                  />
                  <div className="billings_contribution_add_button">
                    <ButtonComponent
                      onClick={onContributionAdd}
                      type="success"
                      title={translations.tabs.contributionAddButtonTitle}
                      isDisabled={
                        tabsData[activeTab].counter >= maxOptionsValue
                      }
                    >
                      {translations.tabs.contributionAddButtonLabel}
                    </ButtonComponent>
                  </div>
                </Column>
              )}
              <Column withPaddings>
                <CardComponent
                  classNames={{ root: "billing_details" }}
                  header={{ title: translations.billingSummary.summaryLabel }}
                >
                  <BillingDetailsComponent billingData={billingData} />
                </CardComponent>
              </Column>
              {!!isSettleButtonVisible && (
                <Column>
                  <ButtonComponent type="primary" onClick={openModal}>
                    {translations.submitLabel}
                  </ButtonComponent>
                </Column>
              )}
              <Column withPaddings>
                <RelatedBillingsTaxiDriverComponent
                  planEntryId={billingData?.planEntryId}
                  orderId={orderUuid}
                />
              </Column>
              <Column withPaddings>
                <RelatedBillingsTaxiTaxiComponent orderId={orderUuid} />
              </Column>
            </Row>
          </Column>
          <Column lg={4} withPaddings>
            {!!billingData && (
              <CardComponent classNames={{ root: "h-50", content: "h-100" }}>
                {shouldShowOrderHistory ? (
                  <OrderDetailsHistoryComponent
                    orderUuid={orderUuid}
                    refetchFlag={false}
                  />
                ) : (
                  <MessengerComponent
                    channelsAvailability={messengerChannelsAvailability}
                    billingType={MessengerBillingType.CARGO}
                    billingUuid={billingUuid!}
                    orderUuid={orderUuid}
                    planEntryUuid={billingData.planEntryId}
                  />
                )}
              </CardComponent>
            )}
          </Column>
        </Row>
        <BillingsSummaryConfirmationComponent
          isVisible={isSummaryConfirmationVisible}
          onSubmit={onBillingDataSave}
          onClose={onModalClose}
          billingData={billingData!}
          isLoading={isBillingSavePending}
        />
      </div>
    </>
  );
};

export default BillingsCargoTaxiEditComponent;
