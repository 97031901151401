import billingsTranslationsHelper from "../../../../../../../languages/billings-translations.helper";
import { BillingsCargoTaxiContributionDiscountType } from "../types/billings-cargo-taxi-contributions-discount";
import BillingsCargoTaxiContributionsDiscountTableColumn from "../types/billings-cargo-taxi-contributions-discount-table-column";

const getRequiredColumnHeading = (heading: string) => (
  <>
    {heading} <span className="header_asterisk">*</span>
  </>
);

const getColumns = (): BillingsCargoTaxiContributionsDiscountTableColumn[] => {
  const translations =
    billingsTranslationsHelper.getCargoTaxiViewBillingsTranslations()
      .contributions.discount.table.headers;

  const tableColumns: BillingsCargoTaxiContributionsDiscountTableColumn[] = [
    {
      header: translations.typeLabel,
      title: translations.typeTitle,
      accessor: "type",
      colSpan: 10,
    },
    {
      header: getRequiredColumnHeading(translations.amountLabel),
      title: translations.amountTitle,
      accessor: "amount",
      colSpan: 10,
    },
    {
      header: getRequiredColumnHeading(translations.commentLabel),
      title: translations.commentTitle,
      accessor: "comment",
      colSpan: 10,
    },
    {
      header: getRequiredColumnHeading(translations.percentageLabel),
      title: translations.percentageTitle,
      accessor: "percentage",
      colSpan: 10,
    },
    {
      header: "",
      title: "",
      accessor: "actions",
      colSpan: 5,
    },
  ];
  return tableColumns;
};

const BillingsCargoTaxiContributionsDiscountTableHelper = { getColumns };

export default BillingsCargoTaxiContributionsDiscountTableHelper;
