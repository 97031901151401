import billingsTranslationsHelper from "../../../../../../languages/billings-translations.helper";
import BillingsTaxiTaxiContributionBonus, {
  BillingsTaxiTaxiContributionBonusType,
} from "./types/billings-taxi-taxi-contributions-bonus";

const getBonusNewContribution = (): BillingsTaxiTaxiContributionBonus => {
  return {
    type: null,
    distance: 0,
    rate: 0,
    amount: 0,
    comment: "",
  };
};

const getContributionTypeTranslation = (
  contributionType: BillingsTaxiTaxiContributionBonusType | null
) => {
  const translations =
    billingsTranslationsHelper.getTaxiTaxiContributionsTranslations().bonus
      .type;

  switch (contributionType) {
    case BillingsTaxiTaxiContributionBonusType.BONUS_FAVORABLE_DISTANCE:
      return translations.favorableDistance;
    case BillingsTaxiTaxiContributionBonusType.BONUS_OTHER:
      return translations.other;
  }
};

const billingsTaxiTaxiContributionsBonusHelper = {
  getBonusNewContribution,
  getContributionTypeTranslation,
};

export default billingsTaxiTaxiContributionsBonusHelper;
