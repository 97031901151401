import BillingModel from "../../../types/billing-model";
import {
  BillingData,
  BillingDataResponseBonusContribution,
  BillingDataResponseContractCorrectionContribution,
  BillingDataResponseContribution,
  BillingDataResponseContributionType,
  BillingDataResponsePenaltyContribution,
} from "../api/billing-data.response";
import BillingFormData, {
  BillingFormDataContributions,
} from "../types/billing-form.data";
import BillingsNode from "../../../common/types/billings-node";
import googleMapsRouteService from "../../../../../common/utils/google-maps-route/google-maps-route.service";
import BillingsTaxiDriverContributionBonus, {
  BillingsTaxiDriverContributionBonusType,
} from "../../common/contributions/bonus/types/billings-taxi-driver-contributions-bonus";
import BillingsTaxiDriverContributionPenalty, {
  BillingsTaxiDriverContributionPenaltyType,
} from "../../common/contributions/penalty/types/billings-taxi-driver-contributions-penalty";
import BillingsTaxiDriverContributionContractCorrection, {
  BillingsTaxiDriverContributionContractCorrectionType,
} from "../../common/contributions/contract-correction/types/billings-taxi-driver-contributions-contract-correction";
import BillingsTaxiDriverContributionsBonusTableRow from "../../common/contributions/bonus/types/billings-taxi-driver-contributions-bonus-table-row";
import billingsTaxiDriverHelper from "../billings-taxi-driver.helper";
import BillingsTaxiDriverContributionsPenaltyTableRow from "../../common/contributions/penalty/types/billings-taxi-driver-contributions-penalty-table-row";
import BillingsTaxiDriverContributionsContractCorrectionTableRow from "../../common/contributions/contract-correction/types/billings-taxi-driver-contributions-contract-correction-table-row";
import billingsTranslationsHelper from "../../../../../languages/billings-translations.helper";
import billingsTaxiDriverContributionsBonusHelper from "../../common/contributions/bonus/billings-taxi-driver-contributions-bonus.helper";
import billingsTaxiDriverContributionsPenaltyHelper from "../../common/contributions/penalty/billings-taxi-driver-contributions-penalty.helper";
import billingsTaxiDriverContributionsContractCorrectionHelper from "../../common/contributions/contract-correction/billings-taxi-driver-contributions-contract-correction.helper";
import TabsData from "../../../../../common/components/tabs/common/types/tabs-data";
import BillingsTaxiDriverContributionsBonusTableComponent from "../../common/contributions/bonus/table/billings-taxi-driver-contributions-bonus-table.component";
import BillingsTaxiDriverContributionsPenaltyTableComponent from "../../common/contributions/penalty/table/billings-taxi-driver-contributions-penalty-table.component";
import BillingsTaxiDriverContributionsContractCorrectionTableComponent from "../../common/contributions/contract-correction/table/billings-taxi-driver-contributions-contract-correction-table.component";

const createBillingNodes = (data: BillingData): BillingsNode[] => {
  return data.billing_nodes.map((billingNode, index) => {
    const previousItem = index - 1 >= 0 ? data.billing_nodes[index - 1] : null;

    const url =
      previousItem !== null
        ? googleMapsRouteService.createGoogleMapsRouteUrl([
            { latitude: previousItem.lat, longitude: previousItem.lon },
            { latitude: billingNode.lat, longitude: billingNode.lon },
          ])
        : "";

    const result: BillingsNode = {
      id: billingNode.id,
      nodeId: billingNode.node_id,
      lat: billingNode.lat,
      lon: billingNode.lon,
      description: billingNode.description,
      checkoutDate: billingNode.checkout_date,
      checkoutEventUuid: billingNode.checkout_event_id,
      plannedDate: billingNode.planned_date,
      plannedDistance: billingNode.planned_distance,
      orderId: billingNode.order_id,
      distance: billingNode.distance,
      haltingTime: billingNode.halting_time,
      haltingAmount: billingNode.halting_amount,
      highwayCharge: billingNode.highway_charge,
      isHighwayAllowed: billingNode.allow_charge,
      isEditable: billingNode.editable_coordinates,
      googleMapsUrl: url,
      position: index + 1,
    };

    return result;
  });
};

const createBillingPenaltyContributionType = (
  contributionType: BillingDataResponseContributionType
): BillingsTaxiDriverContributionPenaltyType | undefined => {
  switch (contributionType) {
    case BillingDataResponseContributionType.PENALTY_WRONG_ROUTE:
      return BillingsTaxiDriverContributionPenaltyType.PENALTY_WRONG_ROUTE;
    case BillingDataResponseContributionType.PENALTY_BEING_LATE:
      return BillingsTaxiDriverContributionPenaltyType.PENALTY_BEING_LATE;
    case BillingDataResponseContributionType.PENALTY_INCOMPATIBLE_CAR:
      return BillingsTaxiDriverContributionPenaltyType.PENALTY_INCOMPATIBLE_CAR;
    case BillingDataResponseContributionType.PENALTY_OTHER:
      return BillingsTaxiDriverContributionPenaltyType.PENALTY_OTHER;
    default:
      return undefined;
  }
};

const createBillingContributionContractCorrectionType = (
  contributionType: BillingDataResponseContributionType
): BillingsTaxiDriverContributionContractCorrectionType | undefined => {
  switch (contributionType) {
    case BillingDataResponseContributionType.BASE_AMOUNT_EXTERNAL_TAXI:
      return BillingsTaxiDriverContributionContractCorrectionType.BASE_AMOUNT_EXTERNAL_TAXI;
    case BillingDataResponseContributionType.CONTRACT_CORRECTION_RATE:
      return BillingsTaxiDriverContributionContractCorrectionType.CONTRACT_CORRECTION_RATE;
    default:
      return undefined;
  }
};

const createBillingContractCorrectionContribution = (
  contribution: BillingDataResponseContractCorrectionContribution
): BillingsTaxiDriverContributionContractCorrection => {
  return {
    type:
      createBillingContributionContractCorrectionType(contribution.type) ??
      null,
    amount: contribution.amount,
    distance: contribution.distance,
    rate: contribution.rate,
  };
};

const createBillingBonusContribution = (
  contribution: BillingDataResponseBonusContribution
): BillingsTaxiDriverContributionBonus => {
  switch (contribution.type) {
    case BillingDataResponseContributionType.BONUS_FAVORABLE_DISTANCE:
      return {
        type: BillingsTaxiDriverContributionBonusType.BONUS_FAVORABLE_DISTANCE,
        amount: contribution.amount,
        distance: contribution.distance,
        rate: contribution.rate,
      };
    case BillingDataResponseContributionType.BONUS_OTHER:
      return {
        type: BillingsTaxiDriverContributionBonusType.BONUS_OTHER,
        comment: contribution.comment ?? "",
        amount: contribution.amount,
      };
  }
};

const createBillingPenaltyContribution = (
  contribution: BillingDataResponsePenaltyContribution
): BillingsTaxiDriverContributionPenalty => {
  return {
    type: createBillingPenaltyContributionType(contribution.type) ?? null,
    comment: contribution.comment ?? "",
    amount: contribution.amount,
  };
};

const createBillingContributions = (
  contributions: BillingDataResponseContribution[]
): BillingFormDataContributions => {
  const bonusContributions: BillingsTaxiDriverContributionBonus[] =
    contributions
      .filter((contribution) =>
        [
          BillingDataResponseContributionType.BONUS_FAVORABLE_DISTANCE,
          BillingDataResponseContributionType.BONUS_OTHER,
        ].includes(contribution.type)
      )
      .map((contribution) =>
        createBillingBonusContribution(
          contribution as BillingDataResponseBonusContribution
        )
      );

  const penaltyContributions: BillingsTaxiDriverContributionPenalty[] =
    contributions
      .filter((contribution) =>
        [
          BillingDataResponseContributionType.PENALTY_BEING_LATE,
          BillingDataResponseContributionType.PENALTY_INCOMPATIBLE_CAR,
          BillingDataResponseContributionType.PENALTY_OTHER,
          BillingDataResponseContributionType.PENALTY_WRONG_ROUTE,
        ].includes(contribution.type)
      )
      .map((contribution) =>
        createBillingPenaltyContribution(
          contribution as BillingDataResponsePenaltyContribution
        )
      );

  const contractCorrectionContribution: BillingsTaxiDriverContributionContractCorrection[] =
    contributions
      .filter((contribution) =>
        [
          BillingDataResponseContributionType.BASE_AMOUNT_EXTERNAL_TAXI,
          BillingDataResponseContributionType.CONTRACT_CORRECTION_RATE,
        ].includes(contribution.type)
      )
      .map((contribution) =>
        createBillingContractCorrectionContribution(
          contribution as BillingDataResponseContractCorrectionContribution
        )
      );

  return {
    bonus: bonusContributions,
    penalty: penaltyContributions,
    contractCorrection: contractCorrectionContribution,
  };
};

const createBillingData = (data: BillingData) => {
  const billingDraft: BillingFormData = {
    billingNodes: createBillingNodes(data),
    billingContributions: createBillingContributions(
      data.billing_contributions
    ),
    contractDetails: {
      companyName: `${data.contract.taxi_driver_association.driver.user.first_name} ${data.contract.taxi_driver_association.driver.user.last_name}`,
      haltingTimeRate: data.contract.halting_time_rate,
      distanceRate: data.contract.distance_rate,
      model: data.contract.model as BillingModel,
      haltingTimeAppliedAfter: data.contract.halting_time_after_minutes,
      driverStartingAddress:
        data.contract.taxi_driver_association.driver.starting_address
          .display_name,
      fleetPartner: data.contract.taxi_driver_association.fleet_partner?.name,
      notes: data.contract.description,
    },
    baseAmount: data.base_amount,
    haltingCost: data.amount_for_charge_haltings,
    highwayCharge: data.amount_for_charge_highways,
    distance: data.distance,
    routeDistanceCost: data.amount_for_distance,
    internalOrderId: data.plan_entry.human_id,
    total: data.all_contributions_amount,
    bonus: 0,
    penalty: 0,
    penaltyType: data.penalty_type,
    bonusType: data.bonus_type,
    date: data.date,
    planEntryId: data.plan_entry.id,
    solvedOrderUuids: data.plan_entry.solved_orders.map(
      (solvedOrder) => solvedOrder.transporting_order.cargo_order.id
    ),
    solvedOrderHumanIds: data.plan_entry.solved_orders.map(
      (solvedOrder) => solvedOrder.transporting_order.cargo_order.human_id
    ),
    driver: data.contract.taxi_driver_association.driver.user,
    passengers: data.passengers,
    rateForDistance: data.rate_for_distance,
    correctedContractModel: data.corrected_contract_model,
  };

  return billingDraft;
};

const createContributionsBonusTableRow = (
  contribution: BillingsTaxiDriverContributionBonus,
  contributionIndex: number
): BillingsTaxiDriverContributionsBonusTableRow => {
  const translations =
    billingsTranslationsHelper.getTaxiDriverContributionsTranslations();

  const contributionType =
    billingsTaxiDriverContributionsBonusHelper.getContributionTypeTranslation(
      contribution.type
    );

  switch (contribution.type) {
    case BillingsTaxiDriverContributionBonusType.BONUS_FAVORABLE_DISTANCE:
      return {
        id: `${contribution.type}-${contributionIndex}`,
        value: {
          type: <div title={contributionType}>{contributionType}</div>,
          distance: (
            <div title={String(contribution.distance)}>
              {String(contribution.distance)}
            </div>
          ),
          rate: (
            <div title={String(contribution.rate)}>
              {String(contribution.rate)}
            </div>
          ),
          amount: (
            <div
              title={String(
                (contribution.distance ?? 0) * (contribution.rate ?? 0)
              )}
            >
              {
                (contribution.amount =
                  (contribution.distance ?? 0) * (contribution.rate ?? 0))
              }
            </div>
          ),
          comment: (
            <div title={translations.attributeNotApplicable}>
              {translations.attributeNotApplicable}
            </div>
          ),
          actions: <div className="d-flex"></div>,
        },
      };
    case BillingsTaxiDriverContributionBonusType.BONUS_OTHER:
      return {
        id: `${contribution.type}-${contributionIndex}`,
        value: {
          type: <div title={contributionType}>{contributionType}</div>,
          distance: (
            <div title={translations.attributeNotApplicable}>
              {translations.attributeNotApplicable}
            </div>
          ),
          rate: (
            <div title={translations.attributeNotApplicable}>
              {translations.attributeNotApplicable}
            </div>
          ),
          amount: (
            <div title={String(contribution.amount)}>
              {String(contribution.amount)}
            </div>
          ),
          comment: (
            <div title={contribution.comment}>{contribution.comment}</div>
          ),
          actions: <div className="d-flex"></div>,
        },
      };
    default:
      return {
        id: String(contributionIndex),
        value: {
          type: <div></div>,
          distance: <div></div>,
          rate: <div></div>,
          amount: <div></div>,
          comment: <div></div>,
          actions: <div className="d-flex"></div>,
        },
      };
  }
};

const createContributionsPenaltyTableRow = (
  contribution: BillingsTaxiDriverContributionPenalty,
  contributionIndex: number
): BillingsTaxiDriverContributionsPenaltyTableRow => {
  const contributionType =
    billingsTaxiDriverContributionsPenaltyHelper.getContributionTypeTranslation(
      contribution.type
    );

  return {
    id: `${contribution.type}-${contributionIndex}`,
    value: {
      type: <div title={contributionType}>{contributionType}</div>,
      amount: (
        <div title={String(contribution.amount)}>
          {String(contribution.amount)}
        </div>
      ),
      comment: <div title={contribution.comment}>{contribution.comment}</div>,
      actions: <div className="d-flex"></div>,
    },
  };
};

const createContributionsContractCorrectionTableRow = (
  contribution: BillingsTaxiDriverContributionContractCorrection,
  contributionIndex: number,
  billingData: BillingFormData
): BillingsTaxiDriverContributionsContractCorrectionTableRow => {
  const contributionType =
    billingsTaxiDriverContributionsContractCorrectionHelper.getContributionTypeTranslation(
      contribution.type
    );

  const translations =
    billingsTranslationsHelper.getTaxiDriverContributionsTranslations();

  switch (contribution.type) {
    case BillingsTaxiDriverContributionContractCorrectionType.BASE_AMOUNT_EXTERNAL_TAXI:
      return {
        id: `${contribution.type}-${contributionIndex}`,
        value: {
          type: <div title={contributionType}>{contributionType}</div>,
          distance: (
            <div title={String(contribution.distance)}>
              {String(contribution.distance)}
            </div>
          ),
          rate: (
            <div
              title={(
                (contribution.amount ?? 0) / (contribution.distance ?? 0)
              ).toFixed(2)}
            >
              {Number(
                (
                  (contribution.amount ?? 0) / (contribution.distance ?? 0)
                ).toFixed(2)
              )}
            </div>
          ),
          amount: (
            <div title={String(contribution.amount)}>
              {String(contribution.amount)}
            </div>
          ),
          actions: <div className="d-flex"></div>,
        },
      };
    case BillingsTaxiDriverContributionContractCorrectionType.CONTRACT_CORRECTION_RATE:
      return {
        id: `${contribution.type}-${contributionIndex}`,
        value: {
          type: <div title={contributionType}>{contributionType}</div>,
          distance: (
            <div title={String(billingData.distance)}>
              {billingData.distance}
            </div>
          ),
          rate: (
            <div title={String(contribution.rate)}>{contribution.rate}</div>
          ),
          amount: (
            <div
              title={String(
                (billingData.distance ?? 0) * (contribution.rate ?? 0)
              )}
            >
              {(billingData.distance ?? 0) * (contribution.rate ?? 0)}
            </div>
          ),
          actions: <div className="d-flex"></div>,
        },
      };
    default:
      return {
        id: String(contributionIndex),
        value: {
          type: <div></div>,
          distance: <div></div>,
          rate: <div></div>,
          amount: <div></div>,
          actions: <div className="d-flex"></div>,
        },
      };
  }
};

const createTabsData = (
  contributions: BillingFormDataContributions,
  isDataLoading: boolean,
  billingData: BillingFormData
): TabsData => {
  const translations =
    billingsTranslationsHelper.getTaxiDriverContributionsTranslations();

  const bonusTableRows: BillingsTaxiDriverContributionsBonusTableRow[] =
    contributions.bonus.map((contribution, index) =>
      createContributionsBonusTableRow(
        contribution as BillingsTaxiDriverContributionBonus,
        index
      )
    );

  const penaltyTableRows: BillingsTaxiDriverContributionsPenaltyTableRow[] =
    contributions.penalty.map((contribution, index) =>
      createContributionsPenaltyTableRow(
        contribution as BillingsTaxiDriverContributionPenalty,
        index
      )
    );

  const contractCorrectionTableRows: BillingsTaxiDriverContributionsContractCorrectionTableRow[] =
    contributions.contractCorrection.map((contribution, index) =>
      createContributionsContractCorrectionTableRow(
        contribution as BillingsTaxiDriverContributionContractCorrection,
        index,
        billingData
      )
    );

  const BonusTableContent = (
    <BillingsTaxiDriverContributionsBonusTableComponent
      isLoading={isDataLoading}
      rows={bonusTableRows}
    />
  );

  const PenaltyTableContent = (
    <BillingsTaxiDriverContributionsPenaltyTableComponent
      isLoading={isDataLoading}
      rows={penaltyTableRows}
    />
  );

  const ContractCorrectionTableContent = (
    <BillingsTaxiDriverContributionsContractCorrectionTableComponent
      isLoading={isDataLoading}
      rows={contractCorrectionTableRows}
    />
  );

  const tabsData: TabsData = [
    {
      label: translations.bonus.title,
      content: BonusTableContent,
      counter: bonusTableRows.length,
    },
    {
      label: translations.penalty.title,
      content: PenaltyTableContent,
      counter: penaltyTableRows.length,
    },
    {
      label: translations.contractCorrection.title,
      content: ContractCorrectionTableContent,
      counter: contractCorrectionTableRows.length,
    },
  ];

  return tabsData;
};

const billingDataFactory = {
  createBillingData,
  createTabsData,
};

export default billingDataFactory;
