export enum CargoOrderActiveListItemExecutionStatus {
  CANDIDATURE = "CANDIDATURE",
  IN_PROGRESS = "IN_PROGRESS",
  PENDING = "PENDING",
  PLANNED = "PLANNED",
}

export enum CargoOrderActiveListItemPublicStatus {
  PUBLIC = "PUBLIC",
  PUBLISHED = "PUBLISHED",
  PRIVATE = "PRIVATE",
}

type CargoOrderActiveListItemClient = {
  id: string;
  name: string;
};

type CargoOrderActiveListItemConsumer = {
  id: string;
  name: string;
};

type CargoOrderActiveListItemProducer = {
  id: string;
  name: string;
};

type CargoOrderActiveListItemAttentions = {
  newSharedPost: boolean;
  newPlanEntryPost: boolean;
  cargoOrderEdit: boolean;
  cargoOrderCancel: boolean;
};

export type CargoOrderActiveListItem = {
  uuid: string;
  estimatedStartDate: Date;
  internalOrderId: number;
  routeAddresses: (string | string[])[];
  passengers: string[];
  dispatch: string;
  client: CargoOrderActiveListItemClient | null;
  consumer: CargoOrderActiveListItemConsumer | null;
  producer: CargoOrderActiveListItemProducer | null;
  taxiCorporations: string[];
  drivers: string[];
  isMobileAppUser: boolean;
  executionStatus: CargoOrderActiveListItemExecutionStatus;
  externalOrderId: string | null;
  publicStatus: CargoOrderActiveListItemPublicStatus;
  isCancelRequested: boolean;
  attentions: CargoOrderActiveListItemAttentions;
  slkStatus: boolean | null;
};

type CargoOrderActiveList = {
  data: CargoOrderActiveListItem[];
};

export default CargoOrderActiveList;
