import { FC, useMemo } from "react";
import billingDetailsHelper from "./billing-details.helper";
import BillingFormData from "../types/billing-form.data";
import BillingDetailsTableRow from "./types/billing-details-table-row";
import dateService from "../../../../../common/utils/date/date.service";
import CardComponent from "../../../../../common/components/card/card.component";
import TableComponent from "../../../../../common/components/table/table.component";
import billingsTranslationsHelper from "../../../../../languages/billings-translations.helper";

type OrderBillingDetailsProps = {
  billingData: BillingFormData | undefined;
};

const BillingDetailsComponent: FC<OrderBillingDetailsProps> = (props) => {
  const createTableRow = (
    billingData: BillingFormData
  ): BillingDetailsTableRow => {
    return {
      id: billingData.internalOrderId.toString(),
      value: {
        date: (
          <div title={dateService.format(billingData.date, "dd/mm/yyyy")}>
            {dateService.format(billingData.date, "dd/mm/yyyy")}
          </div>
        ),
        internalOrderId: (
          <div title={billingData.internalOrderId.toString()}>
            {billingData.internalOrderId}
          </div>
        ),
        driver: (
          <div
            title={`${billingData.driver.first_name} ${billingData.driver.last_name}`}
          >
            {`${billingData.driver.first_name} ${billingData.driver.last_name}`}
          </div>
        ),
        passengers: (
          <div>
            {billingData.passengers.map((passenger, index) => (
              <div key={index} title={passenger}>
                {passenger}
              </div>
            ))}
          </div>
        ),
        model: (
          <div title={billingData.correctedContractModel}>
            {billingData.correctedContractModel}
          </div>
        ),
        rate: (
          <div title={billingData.rateForDistance.toFixed(2)}>
            {billingData.rateForDistance.toFixed(2)} PLN
          </div>
        ),
        distance: (
          <div title={billingData.distance.toString()}>
            {billingData.distance}
          </div>
        ),
        distanceCost: (
          <div title={billingData.routeDistanceCost.toString()}>
            {billingData.routeDistanceCost.toFixed(2)} PLN
          </div>
        ),
        haltingCost: (
          <div title={billingData.haltingCost.toString()}>
            {billingData.haltingCost.toFixed(2)} PLN
          </div>
        ),
        highwayCost: (
          <div title={billingData.highwayCharge.toString()}>
            {billingData.highwayCharge.toFixed(2)} PLN
          </div>
        ),
        total: (
          <div title={billingData.total?.toString()}>
            {billingData.total.toFixed(2)} PLN
          </div>
        ),
      },
    };
  };

  const tableColumns = billingDetailsHelper.getTableColumns();

  const tableRows: BillingDetailsTableRow[] = useMemo(() => {
    if (props.billingData) {
      var tableRow = createTableRow(props.billingData);
      return [tableRow];
    }

    return [];
  }, [props.billingData]);

  const translations =
    billingsTranslationsHelper.getTaxiDriverViewBillingsTranslations()
      .billingSummary;

  return (
    <CardComponent
      classNames={{ root: "billing_details" }}
      header={{ title: translations.summaryLabel }}
    >
      <TableComponent columns={tableColumns} rows={tableRows} />
    </CardComponent>
  );
};

export default BillingDetailsComponent;
