import BillingModel from "../../../types/billing-model";
import {
  BillingData,
  BillingDataResponseBonusContribution,
  BillingDataResponseContractCorrectionContribution,
  BillingDataResponseContribution,
  BillingDataResponseContributionType,
  BillingDataResponsePenaltyContribution,
} from "../api/billing-data.response";
import BillingFormData, {
  BillingFormDataContributions,
} from "../types/billing-form.data";
import BillingsNode from "../../../common/types/billings-node";
import googleMapsRouteService from "../../../../../common/utils/google-maps-route/google-maps-route.service";
import BillingsTaxiDriverContributionContractCorrection, {
  BillingsTaxiDriverContributionContractCorrectionType,
} from "../../common/contributions/contract-correction/types/billings-taxi-driver-contributions-contract-correction";
import BillingsTaxiDriverContributionPenalty, {
  BillingsTaxiDriverContributionPenaltyType,
} from "../../common/contributions/penalty/types/billings-taxi-driver-contributions-penalty";
import BillingsTaxiDriverContributionBonus, {
  BillingsTaxiDriverContributionBonusType,
} from "../../common/contributions/bonus/types/billings-taxi-driver-contributions-bonus";
import BillingsTaxiDriverContributionsBonusTableRow from "../../common/contributions/bonus/types/billings-taxi-driver-contributions-bonus-table-row";
import billingsTranslationsHelper from "../../../../../languages/billings-translations.helper";
import billingsTaxiDriverContributionsBonusHelper from "../../common/contributions/bonus/billings-taxi-driver-contributions-bonus.helper";
import BillingsTaxiDriverContributionsPenaltyTableRow from "../../common/contributions/penalty/types/billings-taxi-driver-contributions-penalty-table-row";
import billingsTaxiDriverContributionsPenaltyHelper from "../../common/contributions/penalty/billings-taxi-driver-contributions-penalty.helper";
import BillingsTaxiDriverContributionsContractCorrectionTableRow from "../../common/contributions/contract-correction/types/billings-taxi-driver-contributions-contract-correction-table-row";
import billingsTaxiDriverContributionsContractCorrectionHelper from "../../common/contributions/contract-correction/billings-taxi-driver-contributions-contract-correction.helper";
import TabsData from "../../../../../common/components/tabs/common/types/tabs-data";
import BillingsTaxiDriverContributionsBonusTableComponent from "../../common/contributions/bonus/table/billings-taxi-driver-contributions-bonus-table.component";
import BillingsTaxiDriverContributionsPenaltyTableComponent from "../../common/contributions/penalty/table/billings-taxi-driver-contributions-penalty-table.component";
import BillingsTaxiDriverContributionsContractCorrectionTableComponent from "../../common/contributions/contract-correction/table/billings-taxi-driver-contributions-contract-correction-table.component";
import FormValidationResult from "../../../../../common/utils/validation/types/form-validation-result";
import SingleSelectComponent from "../../../../../common/components/form/select/single-select/single-select.component";
import FormErrorComponent from "../../../../../common/components/form/error/form-error.component";
import NumericInputComponent from "../../../../../common/components/form/input/numeric-input/numeric-input.component";
import InputComponent from "../../../../../common/components/form/input/input.component";
import TableButtonComponent from "../../../../../common/components/table/button/table-button.component";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

const createBillingNodes = (data: BillingData): BillingsNode[] => {
  return data.billing_nodes.map((billingNode, index) => {
    const previousItem = index - 1 >= 0 ? data.billing_nodes[index - 1] : null;

    const url =
      previousItem !== null
        ? googleMapsRouteService.createGoogleMapsRouteUrl([
            { latitude: previousItem.lat, longitude: previousItem.lon },
            { latitude: billingNode.lat, longitude: billingNode.lon },
          ])
        : "";

    const result: BillingsNode = {
      id: billingNode.id,
      nodeId: billingNode.node_id,
      lat: billingNode.lat,
      lon: billingNode.lon,
      description: billingNode.description,
      checkoutDate: billingNode.checkout_date,
      checkoutEventUuid: billingNode.checkout_event_id,
      plannedDate: billingNode.planned_date,
      plannedDistance: billingNode.planned_distance,
      orderId: billingNode.order_id,
      distance: billingNode.distance,
      haltingTime: billingNode.halting_time,
      haltingAmount: billingNode.halting_amount,
      highwayCharge: billingNode.highway_charge,
      isHighwayAllowed: billingNode.allow_charge,
      isEditable: billingNode.editable_coordinates,
      googleMapsUrl: url,
      position: index + 1,
    };

    return result;
  });
};

const createBillingPenaltyContributionType = (
  contributionType: BillingDataResponseContributionType
): BillingsTaxiDriverContributionPenaltyType | undefined => {
  switch (contributionType) {
    case BillingDataResponseContributionType.PENALTY_WRONG_ROUTE:
      return BillingsTaxiDriverContributionPenaltyType.PENALTY_WRONG_ROUTE;
    case BillingDataResponseContributionType.PENALTY_BEING_LATE:
      return BillingsTaxiDriverContributionPenaltyType.PENALTY_BEING_LATE;
    case BillingDataResponseContributionType.PENALTY_INCOMPATIBLE_CAR:
      return BillingsTaxiDriverContributionPenaltyType.PENALTY_INCOMPATIBLE_CAR;
    case BillingDataResponseContributionType.PENALTY_OTHER:
      return BillingsTaxiDriverContributionPenaltyType.PENALTY_OTHER;
    default:
      return undefined;
  }
};

const createBillingContractCorrectionContribution = (
  contribution: BillingDataResponseContractCorrectionContribution
): BillingsTaxiDriverContributionContractCorrection => {
  switch (contribution.type) {
    case BillingDataResponseContributionType.CONTRACT_CORRECTION_RATE:
      return {
        type: BillingsTaxiDriverContributionContractCorrectionType.CONTRACT_CORRECTION_RATE,
        rate: contribution.rate,
      };
    case BillingDataResponseContributionType.BASE_AMOUNT_EXTERNAL_TAXI:
      return {
        type: BillingsTaxiDriverContributionContractCorrectionType.BASE_AMOUNT_EXTERNAL_TAXI,
        distance: contribution.distance,
        amount: contribution.amount,
      };
  }
};

const createBillingBonusContribution = (
  contribution: BillingDataResponseBonusContribution
): BillingsTaxiDriverContributionBonus => {
  switch (contribution.type) {
    case BillingDataResponseContributionType.BONUS_FAVORABLE_DISTANCE:
      return {
        type: BillingsTaxiDriverContributionBonusType.BONUS_FAVORABLE_DISTANCE,
        amount: contribution.amount,
        distance: contribution.distance,
        rate: contribution.rate,
      };
    case BillingDataResponseContributionType.BONUS_OTHER:
      return {
        type: BillingsTaxiDriverContributionBonusType.BONUS_OTHER,
        comment: contribution.comment ?? "",
        amount: contribution.amount,
      };
  }
};

const createBillingPenaltyContribution = (
  contribution: BillingDataResponsePenaltyContribution
): BillingsTaxiDriverContributionPenalty => {
  return {
    type: createBillingPenaltyContributionType(contribution.type) ?? null,
    comment: contribution.comment ?? "",
    amount: contribution.amount,
  };
};

const createBillingContributions = (
  contributions: BillingDataResponseContribution[]
): BillingFormDataContributions => {
  const bonusContributions: BillingsTaxiDriverContributionBonus[] =
    contributions
      .filter((contribution) =>
        [
          BillingDataResponseContributionType.BONUS_FAVORABLE_DISTANCE,
          BillingDataResponseContributionType.BONUS_OTHER,
        ].includes(contribution.type)
      )
      .map((contribution) =>
        createBillingBonusContribution(
          contribution as BillingDataResponseBonusContribution
        )
      );

  const penaltyContributions: BillingsTaxiDriverContributionPenalty[] =
    contributions
      .filter((contribution) =>
        [
          BillingDataResponseContributionType.PENALTY_BEING_LATE,
          BillingDataResponseContributionType.PENALTY_INCOMPATIBLE_CAR,
          BillingDataResponseContributionType.PENALTY_OTHER,
          BillingDataResponseContributionType.PENALTY_WRONG_ROUTE,
        ].includes(contribution.type)
      )
      .map((contribution) =>
        createBillingPenaltyContribution(
          contribution as BillingDataResponsePenaltyContribution
        )
      );

  const contractCorrectionContribution: BillingsTaxiDriverContributionContractCorrection[] =
    contributions
      .filter((contribution) =>
        [
          BillingDataResponseContributionType.BASE_AMOUNT_EXTERNAL_TAXI,
          BillingDataResponseContributionType.CONTRACT_CORRECTION_RATE,
        ].includes(contribution.type)
      )
      .map((contribution) =>
        createBillingContractCorrectionContribution(
          contribution as BillingDataResponseContractCorrectionContribution
        )
      );

  return {
    bonus: bonusContributions,
    penalty: penaltyContributions,
    contractCorrection: contractCorrectionContribution,
  };
};

const createBillingData = (data: BillingData) => {
  const billingDraft: BillingFormData = {
    billingNodes: createBillingNodes(data),
    billingContributions: createBillingContributions(
      data.billing_contributions
    ),
    contractDetails: {
      companyName: `${data.contract.taxi_driver_association.driver.user.first_name} ${data.contract.taxi_driver_association.driver.user.last_name}`,
      haltingTimeRate: data.contract.halting_time_rate,
      distanceRate: data.contract.distance_rate,
      model: data.contract.model as BillingModel,
      haltingTimeAppliedAfter: data.contract.halting_time_after_minutes,
      driverStartingAddress:
        data.contract.taxi_driver_association.driver.starting_address
          .display_name,
      fleetPartner: data.contract.taxi_driver_association.fleet_partner?.name,
      notes: data.contract.description,
    },
    baseAmount: data.base_amount,
    haltingCost: data.amount_for_charge_haltings,
    highwayCharge: data.amount_for_charge_highways,
    distance: data.distance,
    routeDistanceCost: data.amount_for_distance,
    internalOrderId: data.plan_entry.human_id,
    total: data.all_contributions_amount,
    planEntryId: data.plan_entry.id,
    bonus: 0,
    penalty: 0,
    penaltyType: data.penalty_type,
    bonusType: data.bonus_type,
    date: data.date,
    solvedOrderUuids: data.plan_entry.solved_orders.map(
      (solvedOrder) => solvedOrder.transporting_order.cargo_order.id
    ),
    solvedOrderHumanIds: data.plan_entry.solved_orders.map(
      (solvedOrder) => solvedOrder.transporting_order.cargo_order.human_id
    ),
    driver: data.contract.taxi_driver_association.driver.user,
    passengers: data.passengers,
    rateForDistance: data.rate_for_distance,
    correctedContractModel: data.corrected_contract_model,
  };

  return billingDraft;
};

const createContributionsBonusTableRow = (
  contribution: BillingsTaxiDriverContributionBonus,
  contributions: BillingsTaxiDriverContributionBonus[],
  contributionIndex: number,
  onContributionTypeChange: (
    index: number,
    value: BillingsTaxiDriverContributionBonusType | null
  ) => void,
  onAmountChange: (index: number, value: number | null) => void,
  onCommentChange: (index: number, value: string | null) => void,
  onCommentBlur: (index: number) => void,
  onAmountBlur: (index: number) => void,
  onTypeBlur: (index: number) => void,
  onDistanceChange: (index: number, value: number | null) => void,
  onDistanceBlur: (index: number) => void,
  onRateChange: (index: number, value: number | null) => void,
  onRateBlur: (index: number) => void,
  onContributionDelete: (index: number) => void,
  bonusValidationResult:
    | {
        index: number;
        results: {
          comment: FormValidationResult;
          amount: FormValidationResult;
          type: FormValidationResult;
          distance: FormValidationResult;
          rate: FormValidationResult;
        };
      }
    | undefined
): BillingsTaxiDriverContributionsBonusTableRow => {
  const translations =
    billingsTranslationsHelper.getTaxiDriverContributionsTranslations();

  const contributionBonusTypeSelectOptions =
    billingsTaxiDriverContributionsBonusHelper.getContributionTypeSelectOptions();

  const contributionBonusTypeSelectedOption =
    contributionBonusTypeSelectOptions.find(
      (option) => option.value === contribution.type
    ) ?? null;

  switch (contribution.type) {
    case BillingsTaxiDriverContributionBonusType.BONUS_FAVORABLE_DISTANCE:
      return {
        id: `${contribution.type}-${contributionIndex}`,
        value: {
          type: (
            <div className="contributions_row">
              <SingleSelectComponent
                value={contributionBonusTypeSelectedOption}
                onChange={(value) =>
                  onContributionTypeChange(
                    contributionIndex,
                    value?.value ?? null
                  )
                }
                onBlur={() => onTypeBlur(contributionIndex)}
                options={contributionBonusTypeSelectOptions}
                placeholder={
                  translations.bonus.form.contributionTypeSelectPlaceholder
                }
                idForTesting={`taxi-driver-billings-edit-contribution-bonus-type-select-${contributionIndex}`}
                filterFunction={(option) =>
                  !contributions.find(
                    (contribution) => option.value == contribution.type
                  )
                }
                hasError={!!bonusValidationResult?.results.type.errorMessage}
              />
              <FormErrorComponent
                message={bonusValidationResult?.results.type.errorMessage}
              />
            </div>
          ),
          distance: (
            <div
              className="contributions_row"
              title={String(contribution.distance)}
            >
              <NumericInputComponent
                value={contribution.distance}
                onChange={(value) => {
                  onDistanceChange(contributionIndex, value);
                }}
                onBlur={() => {
                  onDistanceBlur(contributionIndex);
                }}
                placeholder={
                  translations.bonus.form.contributionDistancePlaceholder
                }
                isIntegerOnly
                hasError={
                  !!bonusValidationResult?.results.distance.errorMessage
                }
              />
              <FormErrorComponent
                message={bonusValidationResult?.results.distance.errorMessage}
              />
            </div>
          ),
          rate: (
            <div
              className="contributions_row"
              title={String(contribution.rate)}
            >
              <NumericInputComponent
                value={contribution.rate}
                onChange={(value) => {
                  onRateChange(contributionIndex, value);
                }}
                onBlur={() => {
                  onRateBlur(contributionIndex);
                }}
                placeholder={
                  translations.bonus.form.contributionRatePlaceholder
                }
                decimalPrecision={2}
                hasError={!!bonusValidationResult?.results.rate.errorMessage}
              />
              <FormErrorComponent
                message={bonusValidationResult?.results.rate.errorMessage}
              />
            </div>
          ),
          amount: (
            <div
              className="contributions_not_required_row"
              title={String(
                (contribution.distance ?? 0) * (contribution.rate ?? 0)
              )}
            >
              {
                (contribution.amount =
                  (contribution.distance ?? 0) * (contribution.rate ?? 0))
              }
              <FormErrorComponent
                message={bonusValidationResult?.results.amount.errorMessage}
              />
            </div>
          ),
          comment: (
            <div
              className="contributions_not_required_row"
              title={translations.attributeNotApplicable}
            >
              {translations.attributeNotApplicable}
            </div>
          ),
          actions: (
            <div className="contributions_delete_button">
              <TableButtonComponent
                icon={faTrash}
                onClick={() => {
                  onContributionDelete(contributionIndex);
                }}
                type="danger"
                title={
                  translations.bonus.table.actions.deleteContributionButtonTitle
                }
              />
            </div>
          ),
        },
      };
    case BillingsTaxiDriverContributionBonusType.BONUS_OTHER:
      return {
        id: `${contribution.type}-${contributionIndex}`,
        value: {
          type: (
            <div className="contributions_row">
              <SingleSelectComponent
                value={contributionBonusTypeSelectedOption}
                onChange={(value) =>
                  onContributionTypeChange(
                    contributionIndex,
                    value?.value ?? null
                  )
                }
                onBlur={() => onTypeBlur(contributionIndex)}
                options={contributionBonusTypeSelectOptions}
                placeholder={
                  translations.bonus.form.contributionTypeSelectPlaceholder
                }
                idForTesting={`taxi-driver-billings-edit-contribution-bonus-type-select-${contributionIndex}`}
                filterFunction={(option) =>
                  !contributions.find(
                    (contribution) => option.value == contribution.type
                  )
                }
                hasError={!!bonusValidationResult?.results.type.errorMessage}
              />
              <FormErrorComponent
                message={bonusValidationResult?.results.type.errorMessage}
              />
            </div>
          ),
          distance: (
            <div
              className="contributions_not_required_row"
              title={translations.attributeNotApplicable}
            >
              {translations.attributeNotApplicable}
            </div>
          ),
          rate: (
            <div
              className="contributions_not_required_row"
              title={translations.attributeNotApplicable}
            >
              {translations.attributeNotApplicable}
            </div>
          ),
          amount: (
            <div
              className="contributions_row"
              title={String(contribution.amount)}
            >
              <NumericInputComponent
                value={contribution.amount}
                onChange={(value) => {
                  onAmountChange(contributionIndex, value);
                }}
                onBlur={() => {
                  onAmountBlur(contributionIndex);
                }}
                placeholder={
                  translations.bonus.form.contributionAmountPlaceholder
                }
                decimalPrecision={2}
                hasError={!!bonusValidationResult?.results.amount.errorMessage}
              />
              <FormErrorComponent
                message={bonusValidationResult?.results.amount.errorMessage}
              />
            </div>
          ),
          comment: (
            <div
              className="contributions_row"
              title={String(contribution.comment)}
            >
              <InputComponent
                value={String(contribution.comment)}
                onChange={(value) => {
                  onCommentChange(contributionIndex, value);
                }}
                onBlur={() => {
                  onCommentBlur(contributionIndex);
                }}
                placeholder={
                  translations.bonus.form.contributionCommentPlaceholder
                }
                hasError={!!bonusValidationResult?.results.comment.errorMessage}
              />
              <FormErrorComponent
                message={bonusValidationResult?.results.comment.errorMessage}
              />
            </div>
          ),
          actions: (
            <div className="contributions_delete_button">
              <TableButtonComponent
                icon={faTrash}
                onClick={() => {
                  onContributionDelete(contributionIndex);
                }}
                type="danger"
                title={
                  translations.bonus.table.actions.deleteContributionButtonTitle
                }
              />
            </div>
          ),
        },
      };
    default:
      return {
        id: String(contributionIndex),
        value: {
          type: (
            <div className="contributions_row">
              <SingleSelectComponent
                value={contributionBonusTypeSelectedOption}
                onChange={(value) =>
                  onContributionTypeChange(
                    contributionIndex,
                    value?.value ?? null
                  )
                }
                onBlur={() => onTypeBlur(contributionIndex)}
                options={contributionBonusTypeSelectOptions}
                placeholder={
                  translations.bonus.form.contributionTypeSelectPlaceholder
                }
                idForTesting={`taxi-driver-billings-edit-contribution-bonus-type-select-${contributionIndex}`}
                filterFunction={(option) =>
                  !contributions.find(
                    (contribution) => option.value == contribution.type
                  )
                }
                hasError={!!bonusValidationResult?.results.type.errorMessage}
              />
              <FormErrorComponent
                message={bonusValidationResult?.results.type.errorMessage}
              />
            </div>
          ),
          distance: <div></div>,
          rate: <div></div>,
          amount: <div></div>,
          comment: <div></div>,
          actions: (
            <div className="contributions_delete_button">
              <TableButtonComponent
                icon={faTrash}
                onClick={() => {
                  onContributionDelete(contributionIndex);
                }}
                type="danger"
                title={
                  translations.bonus.table.actions.deleteContributionButtonTitle
                }
              />
            </div>
          ),
        },
      };
  }
};

const createContributionsPenaltyTableRow = (
  contribution: BillingsTaxiDriverContributionPenalty,
  contributions: BillingsTaxiDriverContributionPenalty[],
  contributionIndex: number,
  onContributionTypeChange: (
    index: number,
    value: BillingsTaxiDriverContributionPenaltyType | null
  ) => void,
  onAmountChange: (index: number, value: number | null) => void,
  onCommentChange: (index: number, value: string | null) => void,
  onCommentBlur: (index: number) => void,
  onAmountBlur: (index: number) => void,
  onTypeBlur: (index: number) => void,
  onContributionDelete: (index: number) => void,
  penaltyValidationResult:
    | {
        index: number;
        results: {
          comment: FormValidationResult;
          amount: FormValidationResult;
          type: FormValidationResult;
        };
      }
    | undefined
): BillingsTaxiDriverContributionsPenaltyTableRow => {
  const translations =
    billingsTranslationsHelper.getTaxiDriverContributionsTranslations();

  const contributionPenaltyTypeSelectOptions =
    billingsTaxiDriverContributionsPenaltyHelper.getContributionTypeSelectOptions();

  const contributionPenaltyTypeSelectedOption =
    contributionPenaltyTypeSelectOptions.find(
      (option) => option.value === contribution.type
    ) ?? null;

  switch (contribution.type) {
    case BillingsTaxiDriverContributionPenaltyType.PENALTY_BEING_LATE:
    case BillingsTaxiDriverContributionPenaltyType.PENALTY_INCOMPATIBLE_CAR:
    case BillingsTaxiDriverContributionPenaltyType.PENALTY_OTHER:
    case BillingsTaxiDriverContributionPenaltyType.PENALTY_WRONG_ROUTE:
      return {
        id: `${contribution.type}-${contributionIndex}`,
        value: {
          type: (
            <div className="contributions_row">
              <SingleSelectComponent
                value={contributionPenaltyTypeSelectedOption}
                onChange={(value) =>
                  onContributionTypeChange(
                    contributionIndex,
                    value?.value ?? null
                  )
                }
                onBlur={() => onTypeBlur(contributionIndex)}
                options={contributionPenaltyTypeSelectOptions}
                placeholder={
                  translations.penalty.form.contributionTypeSelectPlaceholder
                }
                idForTesting={`taxi-driver-billings-edit-contribution-penalty-type-select-${contributionIndex}`}
                filterFunction={(option) =>
                  !contributions.find(
                    (contribution) => option.value == contribution.type
                  )
                }
                hasError={!!penaltyValidationResult?.results.type.errorMessage}
              />
              <FormErrorComponent
                message={penaltyValidationResult?.results.type.errorMessage}
              />
            </div>
          ),
          amount: (
            <div
              className="contributions_row"
              title={String(contribution.amount)}
            >
              <NumericInputComponent
                value={contribution.amount}
                onChange={(value) => {
                  onAmountChange(contributionIndex, value);
                }}
                onBlur={() => {
                  onAmountBlur(contributionIndex);
                }}
                placeholder={
                  translations.penalty.form.contributionAmountPlaceholder
                }
                decimalPrecision={2}
                hasError={
                  !!penaltyValidationResult?.results.amount.errorMessage
                }
              />
              <FormErrorComponent
                message={penaltyValidationResult?.results.amount.errorMessage}
              />
            </div>
          ),
          comment: (
            <div className="contributions_row" title={contribution.comment}>
              <InputComponent
                value={contribution.comment}
                onChange={(value) => {
                  onCommentChange(contributionIndex, value);
                }}
                onBlur={() => {
                  onCommentBlur(contributionIndex);
                }}
                placeholder={
                  translations.penalty.form.contributionCommentPlaceholder
                }
                hasError={
                  !!penaltyValidationResult?.results.comment.errorMessage
                }
              />
              <FormErrorComponent
                message={penaltyValidationResult?.results.comment.errorMessage}
              />
            </div>
          ),
          actions: (
            <div className="contributions_delete_button">
              <TableButtonComponent
                icon={faTrash}
                onClick={() => {
                  onContributionDelete(contributionIndex);
                }}
                type="danger"
                title={
                  translations.penalty.table.actions
                    .deleteContributionButtonTitle
                }
              />
            </div>
          ),
        },
      };
    default:
      return {
        id: String(contributionIndex),
        value: {
          type: (
            <div className="contributions_row">
              <SingleSelectComponent
                value={contributionPenaltyTypeSelectedOption}
                onChange={(value) =>
                  onContributionTypeChange(
                    contributionIndex,
                    value?.value ?? null
                  )
                }
                onBlur={() => onTypeBlur(contributionIndex)}
                options={contributionPenaltyTypeSelectOptions}
                placeholder={
                  translations.penalty.form.contributionTypeSelectPlaceholder
                }
                idForTesting={`taxi-driver-billings-edit-contribution-penalty-type-select-${contributionIndex}`}
                filterFunction={(option) =>
                  !contributions.find(
                    (contribution) => option.value == contribution.type
                  )
                }
                hasError={!!penaltyValidationResult?.results.type.errorMessage}
              />
              <FormErrorComponent
                message={penaltyValidationResult?.results.type.errorMessage}
              />
            </div>
          ),
          amount: <div></div>,
          comment: <div></div>,
          actions: (
            <div className="contributions_delete_button">
              <TableButtonComponent
                icon={faTrash}
                onClick={() => {
                  onContributionDelete(contributionIndex);
                }}
                type="danger"
                title={
                  translations.penalty.table.actions
                    .deleteContributionButtonTitle
                }
              />
            </div>
          ),
        },
      };
  }
};

const createContributionsContractCorrectionTableRow = (
  contribution: BillingsTaxiDriverContributionContractCorrection,
  contributions: BillingsTaxiDriverContributionContractCorrection[],
  billingFormData: BillingFormData,
  contributionIndex: number,
  onContributionTypeChange: (
    index: number,
    value: BillingsTaxiDriverContributionContractCorrectionType | null
  ) => void,
  onAmountChange: (index: number, value: number | null) => void,
  onAmountBlur: (index: number) => void,
  onTypeBlur: (index: number) => void,
  onRateChange: (index: number, value: number | null) => void,
  onRateBlur: (index: number) => void,
  onContributionDelete: (index: number) => void,
  contractCorrectionValidationResult:
    | {
        index: number;
        results: {
          amount: FormValidationResult;
          type: FormValidationResult;
          distance: FormValidationResult;
          rate: FormValidationResult;
        };
      }
    | undefined
): BillingsTaxiDriverContributionsContractCorrectionTableRow => {
  const translations =
    billingsTranslationsHelper.getTaxiDriverContributionsTranslations();

  const contributionContractCorrectionTypeSelectOptions =
    billingsTaxiDriverContributionsContractCorrectionHelper.getContributionTypeSelectOptions();

  const contributionContractCorrectionTypeSelectedOption =
    contributionContractCorrectionTypeSelectOptions.find(
      (option) => option.value === contribution.type
    ) ?? null;

  switch (contribution.type) {
    case BillingsTaxiDriverContributionContractCorrectionType.BASE_AMOUNT_EXTERNAL_TAXI:
      return {
        id: `${contribution.type}-${contributionIndex}`,
        value: {
          type: (
            <div className="contributions_row">
              <SingleSelectComponent
                value={contributionContractCorrectionTypeSelectedOption}
                onChange={(value) =>
                  onContributionTypeChange(
                    contributionIndex,
                    value?.value ?? null
                  )
                }
                onBlur={() => onTypeBlur(contributionIndex)}
                options={contributionContractCorrectionTypeSelectOptions}
                placeholder={
                  translations.contractCorrection.form
                    .contributionTypeSelectPlaceholder
                }
                idForTesting={`taxi-driver-billings-edit-contribution-contract-correction-type-select-${contributionIndex}`}
                filterFunction={(option) =>
                  !contributions.find(
                    (contribution) => option.value == contribution.type
                  )
                }
                hasError={
                  !!contractCorrectionValidationResult?.results.type
                    .errorMessage
                }
              />
              <FormErrorComponent
                message={
                  contractCorrectionValidationResult?.results.type.errorMessage
                }
              />
            </div>
          ),
          distance: (
            <div
              className="contributions_not_required_row"
              title={String(contribution.distance)}
            >
              {contribution.distance}
              <FormErrorComponent
                message={
                  contractCorrectionValidationResult?.results.distance
                    .errorMessage
                }
              />
            </div>
          ),
          rate: (
            <div
              className="contributions_not_required_row"
              title={(
                (contribution.amount ?? 0) / (contribution.distance ?? 0)
              ).toFixed(2)}
            >
              {Number(
                (
                  (contribution.amount ?? 0) / (contribution.distance ?? 0)
                ).toFixed(2)
              )}
              <FormErrorComponent
                message={
                  contractCorrectionValidationResult?.results.rate.errorMessage
                }
              />
            </div>
          ),
          amount: (
            <div
              className="contributions_row"
              title={String(contribution.amount)}
            >
              <NumericInputComponent
                value={contribution.amount}
                onChange={(value) => {
                  onAmountChange(contributionIndex, value);
                }}
                onBlur={() => {
                  onAmountBlur(contributionIndex);
                }}
                placeholder={
                  translations.contractCorrection.form
                    .contributionAmountPlaceholder
                }
                decimalPrecision={2}
                hasError={
                  !!contractCorrectionValidationResult?.results.amount
                    .errorMessage
                }
              />
              <FormErrorComponent
                message={
                  contractCorrectionValidationResult?.results.amount
                    .errorMessage
                }
              />
            </div>
          ),
          actions: (
            <div className="contributions_delete_button">
              <TableButtonComponent
                icon={faTrash}
                onClick={() => {
                  onContributionDelete(contributionIndex);
                }}
                type="danger"
                title={
                  translations.contractCorrection.table.actions
                    .deleteContributionButtonTitle
                }
              />
            </div>
          ),
        },
      };
    case BillingsTaxiDriverContributionContractCorrectionType.CONTRACT_CORRECTION_RATE:
      return {
        id: `${contribution.type}-${contributionIndex}`,
        value: {
          type: (
            <div className="contributions_row">
              <SingleSelectComponent
                value={contributionContractCorrectionTypeSelectedOption}
                onChange={(value) =>
                  onContributionTypeChange(
                    contributionIndex,
                    value?.value ?? null
                  )
                }
                onBlur={() => onTypeBlur(contributionIndex)}
                options={contributionContractCorrectionTypeSelectOptions}
                placeholder={
                  translations.contractCorrection.form
                    .contributionTypeSelectPlaceholder
                }
                idForTesting={`taxi-driver-billings-edit-contribution-contract-correction-type-select-${contributionIndex}`}
                filterFunction={(option) =>
                  !contributions.find(
                    (contribution) => option.value == contribution.type
                  )
                }
                hasError={
                  !!contractCorrectionValidationResult?.results.type
                    .errorMessage
                }
              />
              <FormErrorComponent
                message={
                  contractCorrectionValidationResult?.results.type.errorMessage
                }
              />
            </div>
          ),
          distance: (
            <div
              className="contributions_not_required_row"
              title={String(billingFormData.distance)}
            >
              {billingFormData.distance}
              <FormErrorComponent
                message={
                  contractCorrectionValidationResult?.results.distance
                    .errorMessage
                }
              />
            </div>
          ),
          rate: (
            <div
              className="contributions_row"
              title={String(contribution.rate)}
            >
              <NumericInputComponent
                value={contribution.rate}
                onChange={(value) => {
                  onRateChange(contributionIndex, value);
                }}
                onBlur={() => {
                  onRateBlur(contributionIndex);
                }}
                placeholder={
                  translations.contractCorrection.form
                    .contributionAmountPlaceholder
                }
                decimalPrecision={2}
                hasError={
                  !!contractCorrectionValidationResult?.results.rate
                    .errorMessage
                }
              />
              <FormErrorComponent
                message={
                  contractCorrectionValidationResult?.results.rate.errorMessage
                }
              />
            </div>
          ),
          amount: (
            <div
              className="contributions_not_required_row"
              title={String(
                (billingFormData.distance ?? 0) * (contribution.rate ?? 0)
              )}
            >
              {(billingFormData.distance ?? 0) * (contribution.rate ?? 0)}
              <FormErrorComponent
                message={
                  contractCorrectionValidationResult?.results.amount
                    .errorMessage
                }
              />
            </div>
          ),
          actions: (
            <div className="contributions_delete_button">
              <TableButtonComponent
                icon={faTrash}
                onClick={() => {
                  onContributionDelete(contributionIndex);
                }}
                type="danger"
                title={
                  translations.contractCorrection.table.actions
                    .deleteContributionButtonTitle
                }
              />
            </div>
          ),
        },
      };
    default:
      return {
        id: String(contributionIndex),
        value: {
          type: (
            <div className="contributions_row">
              <SingleSelectComponent
                value={contributionContractCorrectionTypeSelectedOption}
                onChange={(value) =>
                  onContributionTypeChange(
                    contributionIndex,
                    value?.value ?? null
                  )
                }
                onBlur={() => onTypeBlur(contributionIndex)}
                options={contributionContractCorrectionTypeSelectOptions}
                placeholder={
                  translations.contractCorrection.form
                    .contributionTypeSelectPlaceholder
                }
                idForTesting={`taxi-driver-billings-edit-contribution-contract-correction-type-select-${contributionIndex}`}
                filterFunction={(option) =>
                  !contributions.find(
                    (contribution) => option.value == contribution.type
                  )
                }
                hasError={
                  !!contractCorrectionValidationResult?.results.type
                    .errorMessage
                }
              />
              <FormErrorComponent
                message={
                  contractCorrectionValidationResult?.results.type.errorMessage
                }
              />
            </div>
          ),
          amount: <div></div>,
          distance: <div></div>,
          rate: <div></div>,
          actions: (
            <div className="contributions_delete_button">
              <TableButtonComponent
                icon={faTrash}
                onClick={() => {
                  onContributionDelete(contributionIndex);
                }}
                type="danger"
                title={
                  translations.contractCorrection.table.actions
                    .deleteContributionButtonTitle
                }
              />
            </div>
          ),
        },
      };
  }
};

const createTabsData = (
  contributions: BillingFormDataContributions,
  isDataLoading: boolean,
  billingFormData: BillingFormData,
  onContributionTypeChange: (
    index: number,
    value:
      | BillingsTaxiDriverContributionBonusType
      | BillingsTaxiDriverContributionPenaltyType
      | BillingsTaxiDriverContributionContractCorrectionType
      | null
  ) => void,
  onAmountChange: (index: number, value: number | null) => void,
  onCommentChange: (index: number, value: string | null) => void,
  onCommentBlur: (index: number) => void,
  onAmountBlur: (index: number) => void,
  onTypeBlur: (index: number) => void,
  onDistanceChange: (index: number, value: number | null) => void,
  onDistanceBlur: (index: number) => void,
  onRateChange: (index: number, value: number | null) => void,
  onRateBlur: (index: number) => void,
  onContributionDelete: (index: number) => void,
  bonusValidationResult: {
    index: number;
    results: {
      comment: FormValidationResult;
      amount: FormValidationResult;
      type: FormValidationResult;
      distance: FormValidationResult;
      rate: FormValidationResult;
    };
  }[],
  penaltyValidationResult: {
    index: number;
    results: {
      comment: FormValidationResult;
      amount: FormValidationResult;
      type: FormValidationResult;
    };
  }[],
  contractCorrectionValidationResult: {
    index: number;
    results: {
      amount: FormValidationResult;
      type: FormValidationResult;
      distance: FormValidationResult;
      rate: FormValidationResult;
    };
  }[]
): TabsData => {
  const translations =
    billingsTranslationsHelper.getTaxiDriverContributionsTranslations();

  const bonusTableRows: BillingsTaxiDriverContributionsBonusTableRow[] =
    contributions.bonus.map((contribution, index) =>
      createContributionsBonusTableRow(
        contribution,
        contributions.bonus,
        index,
        onContributionTypeChange,
        onAmountChange,
        onCommentChange,
        onCommentBlur,
        onAmountBlur,
        onTypeBlur,
        onDistanceChange,
        onDistanceBlur,
        onRateChange,
        onRateBlur,
        onContributionDelete,
        bonusValidationResult[index]
      )
    );

  const penaltyTableRows: BillingsTaxiDriverContributionsPenaltyTableRow[] =
    contributions.penalty.map((contribution, index) =>
      createContributionsPenaltyTableRow(
        contribution,
        contributions.penalty,
        index,
        onContributionTypeChange,
        onAmountChange,
        onCommentChange,
        onCommentBlur,
        onAmountBlur,
        onTypeBlur,
        onContributionDelete,
        penaltyValidationResult[index]
      )
    );

  const contractCorrectionTableRows: BillingsTaxiDriverContributionsContractCorrectionTableRow[] =
    contributions.contractCorrection.map((contribution, index) =>
      createContributionsContractCorrectionTableRow(
        contribution,
        contributions.contractCorrection,
        billingFormData,
        index,
        onContributionTypeChange,
        onAmountChange,
        onAmountBlur,
        onTypeBlur,
        onRateChange,
        onRateBlur,
        onContributionDelete,
        contractCorrectionValidationResult[index]
      )
    );

  const BonusTableContent = (
    <BillingsTaxiDriverContributionsBonusTableComponent
      isLoading={isDataLoading}
      rows={bonusTableRows}
    />
  );

  const PenaltyTableContent = (
    <BillingsTaxiDriverContributionsPenaltyTableComponent
      isLoading={isDataLoading}
      rows={penaltyTableRows}
    />
  );

  const ContractCorrectionTableContent = (
    <BillingsTaxiDriverContributionsContractCorrectionTableComponent
      isLoading={isDataLoading}
      rows={contractCorrectionTableRows}
    />
  );

  const tabsData: TabsData = [
    {
      label: translations.bonus.title,
      content: BonusTableContent,
      counter: bonusTableRows.length,
    },
    {
      label: translations.penalty.title,
      content: PenaltyTableContent,
      counter: penaltyTableRows.length,
    },
    {
      label: translations.contractCorrection.title,
      content: ContractCorrectionTableContent,
      counter: contractCorrectionTableRows.length,
    },
  ];

  return tabsData;
};

const billingDataFactory = {
  createBillingData,
  createTabsData,
};

export default billingDataFactory;
