import BillingsNode from "../../../common/types/billings-node";
import BillingsCargoTaxiContribution from "../../common/contributions/discount/types/billings-cargo-taxi-contributions-discount";
import { BillingsCargoTaxiContributionDiscountType } from "../../common/contributions/discount/types/billings-cargo-taxi-contributions-discount";
import BillingSaveRequest, {
  BillingSaveRequestBillingContribution,
  BillingSaveRequestBillingContributionDiscountType,
} from "../api/billing-save.request";
import BillingNodeApi from "../api/types/billing-node";

const createContributionType = (
  type: BillingsCargoTaxiContributionDiscountType
): BillingSaveRequestBillingContributionDiscountType => {
  switch (type) {
    case BillingsCargoTaxiContributionDiscountType.DISCOUNT_BEING_LATE:
      return BillingSaveRequestBillingContributionDiscountType.DISCOUNT_BEING_LATE;
    case BillingsCargoTaxiContributionDiscountType.DISCOUNT_INCOMPATIBLE_CAR:
      return BillingSaveRequestBillingContributionDiscountType.DISCOUNT_INCOMPATIBLE_CAR;
    case BillingsCargoTaxiContributionDiscountType.DISCOUNT_OPTIMIZATION:
      return BillingSaveRequestBillingContributionDiscountType.DISCOUNT_OPTIMIZATION;
    case BillingsCargoTaxiContributionDiscountType.DISCOUNT_OTHER:
      return BillingSaveRequestBillingContributionDiscountType.DISCOUNT_OTHER;
    case BillingsCargoTaxiContributionDiscountType.CONTRACT_CORRECTION_PERCENTAGE:
      return BillingSaveRequestBillingContributionDiscountType.CONTRACT_CORRECTION_PERCENTAGE;
  }
};

const createBillingContributions = (
  contributions: BillingsCargoTaxiContribution[]
): BillingSaveRequestBillingContribution[] => {
  const discountContributions = contributions
    .filter((contribution) => contribution.type !== null)
    .map((contribution) => {
      switch (contribution.type) {
        case BillingsCargoTaxiContributionDiscountType.CONTRACT_CORRECTION_PERCENTAGE:
          const percentage: BillingSaveRequestBillingContribution = {
            type: createContributionType(contribution.type!),
            percentage: contribution.percentage ?? 0,
          } as BillingSaveRequestBillingContribution;
          return percentage;

        case BillingsCargoTaxiContributionDiscountType.DISCOUNT_BEING_LATE:
        case BillingsCargoTaxiContributionDiscountType.DISCOUNT_INCOMPATIBLE_CAR:
        case BillingsCargoTaxiContributionDiscountType.DISCOUNT_OPTIMIZATION:
        case BillingsCargoTaxiContributionDiscountType.DISCOUNT_OTHER:
          const discount: BillingSaveRequestBillingContribution = {
            type: createContributionType(contribution.type!),
            comment: contribution.comment ?? "",
            amount: contribution.amount ?? 0,
          } as BillingSaveRequestBillingContribution;
          return discount;

        default:
          const empty: BillingSaveRequestBillingContribution = {
            type: null,
            comment: "",
            amount: 0,
            percentage: 0,
          };
          return empty;
      }
    });

  return [...discountContributions];
};

const createSaveBillingRequest = (
  nodes: BillingsNode[],
  discountValue: number,
  contributions: BillingsCargoTaxiContribution[]
): BillingSaveRequest => {
  const result: BillingSaveRequest = {
    billing_nodes: nodes.map((specificNode) => {
      const node: BillingNodeApi = {
        id: specificNode.id,
        node_id: specificNode.nodeId,
        order_id: specificNode.orderId,
        lat: specificNode.lat,
        lon: specificNode.lon,
        distance: specificNode.distance,
        description: specificNode.description,
        checkout_date: specificNode.checkoutDate!,
        checkout_event_id: specificNode.checkoutEventUuid,
        planned_date: specificNode.plannedDate!,
        halting_amount: specificNode.haltingAmount,
        halting_time: specificNode.haltingTime,
        allow_charge: specificNode.isHighwayAllowed,
        highway_charge: specificNode.highwayCharge,
        planned_distance: specificNode.plannedDistance,
        editable_coordinates: specificNode.isEditable,
      };
      return node;
    }),
    billing_contributions: createBillingContributions(contributions),
    discount: 0,
  };
  return result;
};

const billingSaveRequestFactory = {
  createSaveBillingRequest,
};

export default billingSaveRequestFactory;
