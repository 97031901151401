import isEqual from "lodash/isEqual";
import TaxiOrderSettlementListingFilterSelectOption, {
  TaxiOrderSettlementListingBillingModelFilterSelectOption,
  TaxiOrderSettlementListingBillingStatusFilterSelectOption,
  TaxiOrderSettlementListingBillingTypeFilterSelectOption,
  TaxiOrderSettlementListingInternalOrderIdFilterSelectOption,
} from "./types/taxi-order-settlement-listing-filter-select-option";
import settlementTranslationsHelper from "../../../../../languages/settlement-translations.helper";
import TaxiOrderSettlementListingFilterType from "./types/taxi-order-settlement-listing-filter-type";
import TaxiOrderSettlementListingFilter, {
  TaxiOrderSettlementListingOrderStartDateFilter,
} from "./types/taxi-order-settlement-listing-filter";
import taxiOrderSettlementListingHelper from "./taxi-order-settlement-listing.helper";
import TaxiOrderSettlementListingItemBillingType from "./types/taxi-order-settlement-listing-item-billing-type";
import BillingModel from "../../../common/types/billing-model";
import TaxiOrderSettlementListingItemBillingStatus from "./types/taxi-order-settlement-listing-item-billing-status";

const getSearchQueryOptions = (
  query: string
): TaxiOrderSettlementListingFilterSelectOption[] => {
  if (!query) {
    return [];
  }

  const searchFilterTranslations =
    settlementTranslationsHelper.getTaxiOrderSettlementListingTranslations()
      .filters.search;

  const parseStringToNumber = (string: string): number | undefined => {
    const stringAsNumber = Number(string);

    if (Number.isNaN(stringAsNumber)) {
      return undefined;
    }

    return stringAsNumber;
  };

  const getSearchByInternalOrderIdLabel = (query: string) => {
    return searchFilterTranslations.searchByInternalOrderIdTemplateLabel.replace(
      `#{query}`,
      query
    );
  };

  const getSearchByCargoCompanyLabel = (query: string) => {
    return searchFilterTranslations.searchByCargoCompanyTemplateLabel.replace(
      `#{query}`,
      query
    );
  };

  const getSearchByDestinationTaxiLabel = (query: string) => {
    return searchFilterTranslations.searchByDestinationTaxiTemplateLabel.replace(
      `#{query}`,
      query
    );
  };

  const stringOptions = [
    {
      label: getSearchByCargoCompanyLabel(query),
      value: {
        type: TaxiOrderSettlementListingFilterType.CARGO_COMPANY,
        value: query,
      },
    },
    {
      label: getSearchByDestinationTaxiLabel(query),
      value: {
        type: TaxiOrderSettlementListingFilterType.DESTINATION_TAXI,
        value: query,
      },
    },
  ];

  const options: TaxiOrderSettlementListingFilterSelectOption[] = [
    ...stringOptions,
  ] as TaxiOrderSettlementListingFilterSelectOption[];

  if (parseStringToNumber(query) !== undefined) {
    const routeIdFilterOption: TaxiOrderSettlementListingInternalOrderIdFilterSelectOption =
      {
        label: getSearchByInternalOrderIdLabel(query),
        value: {
          type: TaxiOrderSettlementListingFilterType.INTERNAL_ORDER_ID,
          value: Number(query),
        },
      };

    options.push(routeIdFilterOption);
  }

  return options;
};

const getSearchStatusOptions = (
  query: string
): TaxiOrderSettlementListingBillingStatusFilterSelectOption[] => {
  const searchFilterTranslations =
    settlementTranslationsHelper.getTaxiOrderSettlementListingTranslations()
      .filters.search;

  const allSelectOptions: TaxiOrderSettlementListingBillingStatusFilterSelectOption[] =
    Object.values(TaxiOrderSettlementListingItemBillingStatus).map((status) => {
      const label =
        searchFilterTranslations.searchByBillingStatusTemplateLabel.replace(
          "#{option}",
          taxiOrderSettlementListingHelper.getBillingStatusText(status)
        );

      return {
        label,
        value: {
          type: TaxiOrderSettlementListingFilterType.BILLING_STATUS,
          value: status,
        },
      };
    });

  if (query) {
    return allSelectOptions.filter((option) =>
      option.label.toLowerCase().includes(query.toLowerCase())
    );
  }

  return allSelectOptions;
};

const getSearchTypeOptions = (
  query: string
): TaxiOrderSettlementListingBillingTypeFilterSelectOption[] => {
  const searchFilterTranslations =
    settlementTranslationsHelper.getTaxiOrderSettlementListingTranslations()
      .filters.search;

  const allSelectOptions: TaxiOrderSettlementListingBillingTypeFilterSelectOption[] =
    Object.values(TaxiOrderSettlementListingItemBillingType).map((type) => {
      const label =
        searchFilterTranslations.searchByBillingTypeTemplateLabel.replace(
          "#{option}",
          taxiOrderSettlementListingHelper.getBillingTypeText(type)
        );

      return {
        label,
        value: {
          type: TaxiOrderSettlementListingFilterType.BILLING_TYPE,
          value: type,
        },
      };
    });

  if (query) {
    return allSelectOptions.filter((option) =>
      option.label.toLowerCase().includes(query.toLowerCase())
    );
  }

  return allSelectOptions;
};

const getSearchModelOptions = (
  query: string
): TaxiOrderSettlementListingBillingModelFilterSelectOption[] => {
  const searchFilterTranslations =
    settlementTranslationsHelper.getTaxiOrderSettlementListingTranslations()
      .filters.search;

  const allSelectOptions: TaxiOrderSettlementListingBillingModelFilterSelectOption[] =
    Object.values(BillingModel).map((type) => {
      const label =
        searchFilterTranslations.searchByBillingModelTemplateLabel.replace(
          "#{option}",
          taxiOrderSettlementListingHelper.getBillingModelText(type)
        );

      return {
        label,
        value: {
          type: TaxiOrderSettlementListingFilterType.BILLING_MODEL,
          value: type,
        },
      };
    });

  if (query) {
    return allSelectOptions.filter((option) =>
      option.label.toLowerCase().includes(query.toLowerCase())
    );
  }

  return allSelectOptions;
};

const getSelectOptionsByQuery = (
  query: string
): TaxiOrderSettlementListingFilterSelectOption[] => {
  const statusOptions = getSearchStatusOptions(query);
  const typeOptions = getSearchTypeOptions(query);
  const modelOptions = getSearchModelOptions(query);
  const queryOptions = getSearchQueryOptions(query);

  return [...statusOptions, ...typeOptions, ...modelOptions, ...queryOptions];
};

const getSelectOptions = (
  query: string,
  selectedFilters: TaxiOrderSettlementListingFilter[]
): TaxiOrderSettlementListingFilterSelectOption[] => {
  const allFilterOptions = getSelectOptionsByQuery(query);

  return allFilterOptions.filter((option) => {
    return !selectedFilters.find((selectedFilter) =>
      isEqual(selectedFilter.type, option.value.type)
    );
  });
};

const getDefaultFilters = (
  filters: TaxiOrderSettlementListingFilter[]
): TaxiOrderSettlementListingFilter[] => {
  const currentDate = new Date();
  const timezoneOffset = currentDate.getTimezoneOffset();

  const defaultFilters = [
    {
      type: TaxiOrderSettlementListingFilterType.ORDER_START_DATE,
      value: {
        from: new Date(currentDate.getFullYear(), currentDate.getMonth(), 1),
        to: new Date(
          new Date(
            currentDate.getFullYear(),
            currentDate.getMonth() + 1,
            0
          ).getTime() -
            timezoneOffset * 60 * 1000
        ),
      },
    } as TaxiOrderSettlementListingOrderStartDateFilter,
  ];

  defaultFilters.forEach((defaultFilter) => {
    const existingFilter = filters.find(
      (filter) => filter.type === defaultFilter.type
    );

    if (existingFilter) {
      return;
    }

    filters.push(defaultFilter);
  });

  return filters;
};

const taxiOrderSettlementListingFilterHelper = {
  getDefaultFilters,
  getSelectOptions,
};

export default taxiOrderSettlementListingFilterHelper;
