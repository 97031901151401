import Joi from "joi";
import FormValidationResult from "../../../../../../common/utils/validation/types/form-validation-result";
import formValidationService from "../../../../../../common/utils/validation/form-validation.service";
import { BillingsCargoTaxiContributionDiscountType } from "./types/billings-cargo-taxi-contributions-discount";
import billingsTranslationsHelper from "../../../../../../languages/billings-translations.helper";

const validateComment = (comment: string | null): FormValidationResult => {
  const validationSchema = Joi.string().trim().max(100);
  return formValidationService.validate(comment, validationSchema);
};

const validateType = (
  type: BillingsCargoTaxiContributionDiscountType | null
): FormValidationResult => {
  const translations = billingsTranslationsHelper.getBillingsTranslations();

  const validationSchema = Joi.string()
    .valid(...Object.values(BillingsCargoTaxiContributionDiscountType))
    .only()
    .messages({
      "any.only": translations.validationTypeErrorMessage,
    });

  return formValidationService.validate(type, validationSchema);
};

const validateAmount = (amount: number | null): FormValidationResult => {
  const validationSchema = Joi.number().min(0).less(10000).precision(2);
  return formValidationService.validate(amount, validationSchema);
};

const validatePercentage = (
  percentage: number | null,
  contract_discount_limit?: number
): FormValidationResult => {
  const validationSchema = Joi.number()
    .min(0)
    .max(contract_discount_limit ?? 99.99)
    .precision(2);
  return formValidationService.validate(percentage, validationSchema);
};

const billingCargoTaxiContributionsDiscountValidationService = {
  validateComment,
  validateType,
  validateAmount,
  validatePercentage,
};

export default billingCargoTaxiContributionsDiscountValidationService;
