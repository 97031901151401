export enum BillingsTaxiTaxiContributionBonusType {
  BONUS_FAVORABLE_DISTANCE = "BONUS_FAVORABLE_DISTANCE",
  BONUS_OTHER = "BONUS_OTHER",
}

export type BillingsTaxiTaxiContributionBonusFavorableDistance = {
  type: BillingsTaxiTaxiContributionBonusType.BONUS_FAVORABLE_DISTANCE | null;
  comment: string;
  distance: number | null;
  rate: number | null;
};

export type BillingsTaxiTaxiContributionBonusOther = {
  type: BillingsTaxiTaxiContributionBonusType.BONUS_OTHER | null;
  comment: string;
  amount: number | null;
};

type BillingsTaxiTaxiContributionBonus =
  | BillingsTaxiTaxiContributionBonusFavorableDistance
  | BillingsTaxiTaxiContributionBonusOther;

export default BillingsTaxiTaxiContributionBonus;
