import Joi from "joi";
import FormValidationResult from "../../../../../common/utils/validation/types/form-validation-result";
import { BillingsTaxiTaxiContributionBonusType } from "./bonus/types/billings-taxi-taxi-contributions-bonus";
import { BillingsTaxiTaxiContributionPenaltyType } from "./penalty/types/billings-taxi-taxi-contributions-penalty";
import formValidationService from "../../../../../common/utils/validation/form-validation.service";

const validateType = (
  type:
    | BillingsTaxiTaxiContributionBonusType
    | BillingsTaxiTaxiContributionPenaltyType
    | null
): FormValidationResult => {
  const validationSchema = Joi.object()
    .valid(
      ...Object.values(BillingsTaxiTaxiContributionBonusType),
      ...Object.values(BillingsTaxiTaxiContributionPenaltyType)
    )
    .only();

  return formValidationService.validate(type, validationSchema);
};

const validateDistance = (distance: number | null): FormValidationResult => {
  const validationSchema = Joi.number().integer().greater(0).less(10000);

  return formValidationService.validate(distance, validationSchema);
};

const validateRate = (rate: number | null): FormValidationResult => {
  const validationSchema = Joi.number().min(0).less(100).precision(2);

  return formValidationService.validate(rate, validationSchema);
};

const validateAmount = (amount: number | null): FormValidationResult => {
  const validationSchema = Joi.number().min(0).less(10000).precision(2);

  return formValidationService.validate(amount, validationSchema);
};

const validateComment = (comment: string): FormValidationResult => {
  const validationSchema = Joi.string().required().trim().max(100);

  return formValidationService.validate(comment, validationSchema);
};

const billingsTaxiTaxiContributionsValidationService = {
  validateType,
  validateDistance,
  validateRate,
  validateAmount,
  validateComment,
};

export default billingsTaxiTaxiContributionsValidationService;
