import billingsTranslationsHelper from "../../../../../../languages/billings-translations.helper";
import BillingsTaxiDriverContributionPenalty, {
  BillingsTaxiDriverContributionPenaltyType,
} from "./types/billings-taxi-driver-contributions-penalty";
import BillingsTaxiDriverContributionsPenaltySelectOption from "./types/billings-taxi-driver-contributions-penalty-select-option";

const getNewPenaltyContribution = (): BillingsTaxiDriverContributionPenalty => {
  return {
    amount: null,
    comment: "",
    type: null,
  };
};

const getContributionTypeTranslation = (
  contributionType: BillingsTaxiDriverContributionPenaltyType | null
) => {
  const translations =
    billingsTranslationsHelper.getTaxiDriverContributionsTranslations().penalty
      .type;

  switch (contributionType) {
    case BillingsTaxiDriverContributionPenaltyType.PENALTY_BEING_LATE:
      return translations.beingLate;
    case BillingsTaxiDriverContributionPenaltyType.PENALTY_INCOMPATIBLE_CAR:
      return translations.incompatibleCar;
    case BillingsTaxiDriverContributionPenaltyType.PENALTY_WRONG_ROUTE:
      return translations.wrongRoute;
    case BillingsTaxiDriverContributionPenaltyType.PENALTY_OTHER:
      return translations.other;
  }
};

const getContributionTypeSelectOptions =
  (): BillingsTaxiDriverContributionsPenaltySelectOption[] => {
    const translations =
      billingsTranslationsHelper.getTaxiDriverContributionsTranslations()
        .penalty.type;

    return [
      {
        label: translations.beingLate,
        value: BillingsTaxiDriverContributionPenaltyType.PENALTY_BEING_LATE,
      },
      {
        label: translations.incompatibleCar,
        value:
          BillingsTaxiDriverContributionPenaltyType.PENALTY_INCOMPATIBLE_CAR,
      },
      {
        label: translations.other,
        value: BillingsTaxiDriverContributionPenaltyType.PENALTY_OTHER,
      },
      {
        label: translations.wrongRoute,
        value: BillingsTaxiDriverContributionPenaltyType.PENALTY_WRONG_ROUTE,
      },
    ];
  };

const billingsTaxiDriverContributionsPenaltyHelper = {
  getNewPenaltyContribution,
  getContributionTypeTranslation,
  getContributionTypeSelectOptions,
};

export default billingsTaxiDriverContributionsPenaltyHelper;
