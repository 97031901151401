import BillingModel from "../../../types/billing-model";
import {
  BillingData,
  BillingDataResponseContribution,
  BillingDataResponseContributionType,
} from "../api/billing-data.response";
import BillingFormData from "../types/billing-form.data";
import BillingsNode from "../../../common/types/billings-node";
import googleMapsRouteService from "../../../../../common/utils/google-maps-route/google-maps-route.service";
import FormValidationResult from "../../../../../common/utils/validation/types/form-validation-result";
import BillingsCargoTaxiContributionsDiscountTableRow from "../../common/contributions/discount/types/billings-cargo-taxi-contributions-discount-table-row";
import billingsTranslationsHelper from "../../../../../languages/billings-translations.helper";
import billingsCargoTaxiHelper from "../billings-cargo-taxi.helper";
import SingleSelectComponent from "../../../../../common/components/form/select/single-select/single-select.component";
import FormErrorComponent from "../../../../../common/components/form/error/form-error.component";
import NumericInputComponent from "../../../../../common/components/form/input/numeric-input/numeric-input.component";
import InputComponent from "../../../../../common/components/form/input/input.component";
import TableButtonComponent from "../../../../../common/components/table/button/table-button.component";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import TabsData from "../../../../../common/components/tabs/common/types/tabs-data";
import BillingsCargoTaxiContributionsDiscountTableComponent from "../../common/contributions/discount/table/billings-cargo-taxi-contributions-discount-table.component";
import BillingsCargoTaxiContribution, {
  BillingsCargoTaxiContributionContractCorretionPercentage,
  BillingsCargoTaxiContributionDiscountType,
} from "../../common/contributions/discount/types/billings-cargo-taxi-contributions-discount";

const createBillingNodes = (data: BillingData): BillingsNode[] => {
  return data.billing_nodes.map((billingNode, index) => {
    const previousItem = index - 1 >= 0 ? data.billing_nodes[index - 1] : null;

    const url =
      previousItem !== null
        ? googleMapsRouteService.createGoogleMapsRouteUrl([
            { latitude: previousItem.lat, longitude: previousItem.lon },
            { latitude: billingNode.lat, longitude: billingNode.lon },
          ])
        : "";

    const result: BillingsNode = {
      id: billingNode.id,
      nodeId: billingNode.node_id,
      lat: billingNode.lat,
      lon: billingNode.lon,
      description: billingNode.description,
      checkoutDate: billingNode.checkout_date
        ? new Date(billingNode.checkout_date)
        : null,
      checkoutEventUuid: billingNode.checkout_event_id,
      plannedDate: billingNode.planned_date
        ? new Date(billingNode.planned_date)
        : null,
      plannedDistance: billingNode.planned_distance,
      orderId: billingNode.order_id,
      distance: billingNode.distance,
      haltingTime: billingNode.halting_time,
      haltingAmount: billingNode.halting_amount,
      highwayCharge: billingNode.highway_charge,
      isHighwayAllowed: billingNode.allow_charge,
      isEditable: billingNode.editable_coordinates,
      googleMapsUrl: url,
      position: index + 1,
    };

    return result;
  });
};

const createBillingContributionDiscountType = (
  contributionType: BillingDataResponseContributionType
): BillingsCargoTaxiContributionDiscountType | undefined => {
  switch (contributionType) {
    case BillingDataResponseContributionType.DISCOUNT_BEING_LATE:
      return BillingsCargoTaxiContributionDiscountType.DISCOUNT_BEING_LATE;
    case BillingDataResponseContributionType.DISCOUNT_INCOMPATIBLE_CAR:
      return BillingsCargoTaxiContributionDiscountType.DISCOUNT_INCOMPATIBLE_CAR;
    case BillingDataResponseContributionType.DISCOUNT_OPTIMIZATION:
      return BillingsCargoTaxiContributionDiscountType.DISCOUNT_OPTIMIZATION;
    case BillingDataResponseContributionType.DISCOUNT_OTHER:
      return BillingsCargoTaxiContributionDiscountType.DISCOUNT_OTHER;
    case BillingDataResponseContributionType.CONTRACT_CORRECTION_PERCENTAGE:
      return BillingsCargoTaxiContributionDiscountType.CONTRACT_CORRECTION_PERCENTAGE;
    default:
      return undefined;
  }
};

const createBillingContributions = (
  contributions: BillingDataResponseContribution[]
): BillingsCargoTaxiContribution[] => {
  const discountContribution = contributions
    .filter((contribution) =>
      [
        BillingDataResponseContributionType.DISCOUNT_BEING_LATE,
        BillingDataResponseContributionType.DISCOUNT_INCOMPATIBLE_CAR,
        BillingDataResponseContributionType.DISCOUNT_OPTIMIZATION,
        BillingDataResponseContributionType.DISCOUNT_OTHER,
        BillingDataResponseContributionType.CONTRACT_CORRECTION_PERCENTAGE,
      ].includes(contribution.type)
    )
    .map((contribution) => {
      switch (contribution.type) {
        case BillingDataResponseContributionType.CONTRACT_CORRECTION_PERCENTAGE:
          const percentage: BillingsCargoTaxiContribution = {
            type: createBillingContributionDiscountType(contribution.type)!,
            percentage: contribution.percentage,
          } as BillingsCargoTaxiContribution;
          return percentage;
        case BillingDataResponseContributionType.DISCOUNT_BEING_LATE:
        case BillingDataResponseContributionType.DISCOUNT_INCOMPATIBLE_CAR:
        case BillingDataResponseContributionType.DISCOUNT_OPTIMIZATION:
        case BillingDataResponseContributionType.DISCOUNT_OTHER:
          const discount: BillingsCargoTaxiContribution = {
            type: createBillingContributionDiscountType(contribution.type)!,
            comment: contribution.comment ?? "",
            amount: contribution.amount,
          } as BillingsCargoTaxiContribution;
          return discount;
      }
    });
  return [...discountContribution];
};

const createBillingData = (data: BillingData) => {
  const billingDraft: BillingFormData = {
    billingNodes: createBillingNodes(data),
    billingDiscountContributions: createBillingContributions(
      data.billing_contributions
    ),
    contractDetails: {
      companyName: data.contract.cargo_company.display_name,
      discountLimit: data.contract.discount_limit,
      haltingTimeRate: data.contract.halting_time_rate,
      distanceRate: data.contract.distance_rate,
      isHighwayAllowedDuringRide: data.contract.allow_charge_during_ride,
      isHighwayAllowedWhenApproaching:
        data.contract.allow_charge_while_approaching,
      isHighwayAllowedWhileReturning:
        data.contract.allow_charge_while_returning,
      model: data.contract.model as BillingModel,
      haltingTimeAppliedAfter: data.contract.halting_time_after_minutes,
    },
    baseAmount: data.base_amount,
    discountAmount: data.sum_of_discounts,
    haltingCost: data.amount_for_charge_haltings,
    highwayCharge: data.amount_for_charge_highways,
    distance: data.distance,
    routeDistanceCost: data.amount_for_distance,
    internalOrderId: data.cargo_order.human_id,
    discount: 0,
    total: data.all_contributions_amount,
    planEntryId: data.plan_entry_id,
    cargoOrderId: data.cargo_order.id,
    date: data.date,
    status: data.status,
    passengers: data.passengers,
    contractCorrectionPercentage: data.contract_correction_percentage,
  };

  return billingDraft;
};

const createContributionsDiscountTableRow = (
  contribution: BillingsCargoTaxiContribution,
  contributions: BillingsCargoTaxiContribution[],
  contributionIndex: number,
  onContributionTypeChange: (
    index: number,
    value: BillingsCargoTaxiContributionDiscountType | null
  ) => void,
  onAmountChange: (index: number, value: number | null) => void,
  onCommentChange: (index: number, value: string | null) => void,
  onPercentageChange: (index: number, value: number | null) => void,
  onCommentBlur: (index: number) => void,
  onAmountBlur: (index: number) => void,
  onTypeBlur: (index: number) => void,
  onPercentageBlur: (index: number) => void,
  onContributionDelete: (index: number) => void,
  discountValidationResult: {
    index: number;
    results: {
      comment: FormValidationResult;
      amount: FormValidationResult;
      type: FormValidationResult;
      percentage: FormValidationResult;
    };
  }
): BillingsCargoTaxiContributionsDiscountTableRow => {
  const translations =
    billingsTranslationsHelper.getCargoTaxiAddBillingsTranslations()
      .contributions;

  const contributionDiscountTypeSelectOptions =
    billingsCargoTaxiHelper.getContributionDiscountTypeSelectOptions();

  const contributionDiscountTypeSelectedOption =
    contributionDiscountTypeSelectOptions.find(
      (option) => option.value === contribution.type
    ) ?? null;

  switch (contribution.type) {
    case BillingsCargoTaxiContributionDiscountType.CONTRACT_CORRECTION_PERCENTAGE:
      return {
        id: `${contribution.type}-${contributionIndex}`,
        value: {
          type: (
            <div className="contributions_row">
              <SingleSelectComponent
                value={contributionDiscountTypeSelectedOption}
                onChange={(value) =>
                  onContributionTypeChange(
                    contributionIndex,
                    value?.value ?? null
                  )
                }
                onBlur={() => onTypeBlur(contributionIndex)}
                options={contributionDiscountTypeSelectOptions}
                placeholder={
                  translations.discount.form.contributionTypeSelectPlaceholder
                }
                idForTesting={`cargo-taxi-billings-add-contribution-discount-type-select-${contributionIndex}`}
                filterFunction={(option) =>
                  !contributions.find(
                    (contribution) => option.value == contribution.type
                  )
                }
                hasError={!!discountValidationResult.results.type.errorMessage}
              />
              <FormErrorComponent
                message={discountValidationResult.results.type.errorMessage}
              />
            </div>
          ),
          amount: (
            <div
              className="contributions_not_required_row"
              title={translations.discount.table.notRequiredPlaceholder}
            >
              {translations.discount.table.notRequiredPlaceholder}
            </div>
          ),
          comment: (
            <div
              className="contributions_not_required_row"
              title={translations.discount.table.notRequiredPlaceholder}
            >
              {translations.discount.table.notRequiredPlaceholder}
            </div>
          ),
          percentage: (
            <div
              className="contributions_row"
              title={String(contribution.percentage)}
            >
              <NumericInputComponent
                value={contribution.percentage}
                onChange={(value) => {
                  onPercentageChange(contributionIndex, value);
                }}
                onBlur={() => {
                  onPercentageBlur(contributionIndex);
                }}
                placeholder={
                  translations.discount.form.contributionPercentagePlaceholder
                }
                decimalPrecision={2}
                hasError={
                  !!discountValidationResult.results.percentage.errorMessage
                }
              />
              <FormErrorComponent
                message={
                  discountValidationResult.results.percentage.errorMessage
                }
              />
            </div>
          ),
          actions: (
            <div className="contributions_delete_button">
              <TableButtonComponent
                icon={faTrash}
                onClick={() => {
                  onContributionDelete(contributionIndex);
                }}
                type="danger"
                title={translations.contributionDeleteButtonTitle}
              />
            </div>
          ),
        },
      };
    case BillingsCargoTaxiContributionDiscountType.DISCOUNT_BEING_LATE:
    case BillingsCargoTaxiContributionDiscountType.DISCOUNT_INCOMPATIBLE_CAR:
    case BillingsCargoTaxiContributionDiscountType.DISCOUNT_OPTIMIZATION:
    case BillingsCargoTaxiContributionDiscountType.DISCOUNT_OTHER:
      return {
        id: `${contribution.type}-${contributionIndex}`,
        value: {
          type: (
            <div className="contributions_row">
              <SingleSelectComponent
                value={contributionDiscountTypeSelectedOption}
                onChange={(value) =>
                  onContributionTypeChange(
                    contributionIndex,
                    value?.value ?? null
                  )
                }
                onBlur={() => onTypeBlur(contributionIndex)}
                options={contributionDiscountTypeSelectOptions}
                placeholder={
                  translations.discount.form.contributionTypeSelectPlaceholder
                }
                idForTesting={`cargo-taxi-billings-add-contribution-discount-type-select-${contributionIndex}`}
                filterFunction={(option) =>
                  !contributions.find(
                    (contribution) => option.value == contribution.type
                  )
                }
                hasError={!!discountValidationResult.results.type.errorMessage}
              />
              <FormErrorComponent
                message={discountValidationResult.results.type.errorMessage}
              />
            </div>
          ),
          amount: (
            <div
              className="contributions_row"
              title={String(contribution.amount)}
            >
              <NumericInputComponent
                value={contribution.amount}
                onChange={(value) => {
                  onAmountChange(contributionIndex, value);
                }}
                onBlur={() => {
                  onAmountBlur(contributionIndex);
                }}
                placeholder={
                  translations.discount.form.contributionAmountPlaceholder
                }
                decimalPrecision={2}
                hasError={
                  !!discountValidationResult.results.amount.errorMessage
                }
              />
              <FormErrorComponent
                message={discountValidationResult.results.amount.errorMessage}
              />
            </div>
          ),
          comment: (
            <div
              className="contributions_row"
              title={String(contribution.comment)}
            >
              <InputComponent
                value={String(contribution.comment)}
                onChange={(value) => {
                  onCommentChange(contributionIndex, value);
                }}
                onBlur={() => {
                  onCommentBlur(contributionIndex);
                }}
                placeholder={
                  translations.discount.form.contributionCommentPlaceholder
                }
                hasError={
                  !!discountValidationResult.results.comment.errorMessage
                }
              />
              <FormErrorComponent
                message={discountValidationResult.results.comment.errorMessage}
              />
            </div>
          ),
          percentage: (
            <div
              className="contributions_not_required_row"
              title={translations.discount.table.notRequiredPlaceholder}
            >
              {translations.discount.table.notRequiredPlaceholder}
            </div>
          ),
          actions: (
            <div className="contributions_delete_button">
              <TableButtonComponent
                icon={faTrash}
                onClick={() => {
                  onContributionDelete(contributionIndex);
                }}
                type="danger"
                title={translations.contributionDeleteButtonTitle}
              />
            </div>
          ),
        },
      };
    default:
      return {
        id: `${contribution.type}-${contributionIndex}`,
        value: {
          type: (
            <div className="contributions_row">
              <SingleSelectComponent
                value={contributionDiscountTypeSelectedOption}
                onChange={(value) =>
                  onContributionTypeChange(
                    contributionIndex,
                    value?.value ?? null
                  )
                }
                onBlur={() => onTypeBlur(contributionIndex)}
                options={contributionDiscountTypeSelectOptions}
                placeholder={
                  translations.discount.form.contributionTypeSelectPlaceholder
                }
                idForTesting={`cargo-taxi-billings-add-contribution-discount-type-select-${contributionIndex}`}
                filterFunction={(option) =>
                  !contributions.find(
                    (contribution) => option.value == contribution.type
                  )
                }
                hasError={!!discountValidationResult.results.type.errorMessage}
              />
              <FormErrorComponent
                message={discountValidationResult.results.type.errorMessage}
              />
            </div>
          ),
          amount: <div></div>,
          comment: <div></div>,
          percentage: <div></div>,
          actions: (
            <div className="contributions_delete_button">
              <TableButtonComponent
                icon={faTrash}
                onClick={() => {
                  onContributionDelete(contributionIndex);
                }}
                type="danger"
                title={translations.contributionDeleteButtonTitle}
              />
            </div>
          ),
        },
      };
  }
};

const createTabsData = (
  contributions: BillingsCargoTaxiContribution[],
  isDataLoading: boolean,
  onContributionTypeChange: (
    index: number,
    value: BillingsCargoTaxiContributionDiscountType | null
  ) => void,
  onAmountChange: (index: number, value: number | null) => void,
  onCommentChange: (index: number, value: string | null) => void,
  onPercentageChange: (index: number, value: number | null) => void,
  onCommentBlur: (index: number) => void,
  onAmountBlur: (index: number) => void,
  onTypeBlur: (index: number) => void,
  onPercentageBlur: (index: number) => void,
  onContributionDelete: (index: number) => void,
  discountValidationResults: {
    index: number;
    results: {
      comment: FormValidationResult;
      amount: FormValidationResult;
      type: FormValidationResult;
      percentage: FormValidationResult;
    };
  }[]
): TabsData => {
  const translations =
    billingsTranslationsHelper.getCargoTaxiAddBillingsTranslations()
      .contributions.discount;

  const discountTableRows: BillingsCargoTaxiContributionsDiscountTableRow[] =
    contributions.map((contribution, index) =>
      createContributionsDiscountTableRow(
        contribution,
        contributions,
        index,
        onContributionTypeChange,
        onAmountChange,
        onCommentChange,
        onPercentageChange,
        onCommentBlur,
        onAmountBlur,
        onTypeBlur,
        onPercentageBlur,
        onContributionDelete,
        discountValidationResults[index]
      )
    );

  const DiscountTabContent = (
    <BillingsCargoTaxiContributionsDiscountTableComponent
      isLoading={isDataLoading}
      rows={discountTableRows}
    />
  );

  const tabsData: TabsData = [
    {
      label: translations.title,
      content: DiscountTabContent,
      counter: discountTableRows.length,
    },
  ];

  return tabsData;
};

const billingDataFactory = {
  createBillingData,
  createTabsData,
};

export default billingDataFactory;
