import BillingsCargoTaxiContribution from "./types/billings-cargo-taxi-contributions-discount";

const getDiscountContributionsNewContribution =
  (): BillingsCargoTaxiContribution => {
    return {
      amount: 0,
      comment: "",
      type: null,
      percentage: 0,
    };
  };

const billingsCargoTaxiContributionsDiscountHelper = {
  getDiscountContributionsNewContribution,
};

export default billingsCargoTaxiContributionsDiscountHelper;
