import billingsTranslationsHelper from "../../../../../../languages/billings-translations.helper";
import BillingsTaxiDriverContributionContractCorrection, {
  BillingsTaxiDriverContributionContractCorrectionType,
} from "./types/billings-taxi-driver-contributions-contract-correction";
import BillingsTaxiDriverContributionsContractCorrectionSelectOption from "./types/billings-taxi-driver-contributions-contract-correction-select-option";

const getNewContractCorrectionContribution =
  (): BillingsTaxiDriverContributionContractCorrection => {
    return {
      type: null,
      distance: null,
      rate: null,
      amount: null,
    };
  };

const getContributionTypeTranslation = (
  contributionType: BillingsTaxiDriverContributionContractCorrectionType | null
) => {
  const translations =
    billingsTranslationsHelper.getTaxiDriverContributionsTranslations()
      .contractCorrection.type;

  switch (contributionType) {
    case BillingsTaxiDriverContributionContractCorrectionType.BASE_AMOUNT_EXTERNAL_TAXI:
      return translations.baseAmountExternalTaxi;
    case BillingsTaxiDriverContributionContractCorrectionType.CONTRACT_CORRECTION_RATE:
      return translations.otherRate;
  }
};

const getContributionTypeSelectOptions =
  (): BillingsTaxiDriverContributionsContractCorrectionSelectOption[] => {
    const translations =
      billingsTranslationsHelper.getTaxiDriverContributionsTranslations()
        .contractCorrection.type;

    return [
      {
        label: translations.baseAmountExternalTaxi,
        value:
          BillingsTaxiDriverContributionContractCorrectionType.BASE_AMOUNT_EXTERNAL_TAXI,
      },
      {
        label: translations.otherRate,
        value:
          BillingsTaxiDriverContributionContractCorrectionType.CONTRACT_CORRECTION_RATE,
      },
    ];
  };

const billingsTaxiDriverContributionsContractContributionHelper = {
  getNewContractCorrectionContribution,
  getContributionTypeTranslation,
  getContributionTypeSelectOptions,
};

export default billingsTaxiDriverContributionsContractContributionHelper;
