import BillingsCargoTaxiContribution from "../../common/contributions/discount/types/billings-cargo-taxi-contributions-discount";
import { BillingsCargoTaxiContributionDiscountType } from "../../common/contributions/discount/types/billings-cargo-taxi-contributions-discount";
import BillingRecalculateRequest, {
  BillingRecalculateRequestBillingContribution,
  BillingRecalculateRequestBillingContributionDiscountType,
} from "../api/billing-recalculate.request";
import BillingNodeApi from "../api/types/billing-node";
import BillingFormData from "../types/billing-form.data";

const createRecalculateBillingContributionType = (
  contributionType: BillingsCargoTaxiContributionDiscountType
): BillingRecalculateRequestBillingContributionDiscountType => {
  switch (contributionType) {
    case BillingsCargoTaxiContributionDiscountType.DISCOUNT_BEING_LATE:
      return BillingRecalculateRequestBillingContributionDiscountType.DISCOUNT_BEING_LATE;
    case BillingsCargoTaxiContributionDiscountType.DISCOUNT_INCOMPATIBLE_CAR:
      return BillingRecalculateRequestBillingContributionDiscountType.DISCOUNT_INCOMPATIBLE_CAR;
    case BillingsCargoTaxiContributionDiscountType.DISCOUNT_OPTIMIZATION:
      return BillingRecalculateRequestBillingContributionDiscountType.DISCOUNT_OPTIMIZATION;
    case BillingsCargoTaxiContributionDiscountType.DISCOUNT_OTHER:
      return BillingRecalculateRequestBillingContributionDiscountType.DISCOUNT_OTHER;
    case BillingsCargoTaxiContributionDiscountType.CONTRACT_CORRECTION_PERCENTAGE:
      return BillingRecalculateRequestBillingContributionDiscountType.CONTRACT_CORRECTION_PERCENTAGE;
  }
};

const createRecalculateBillingContributions = (
  contributions: BillingsCargoTaxiContribution[]
): BillingRecalculateRequestBillingContribution[] => {
  const discountContributions = contributions
    .filter((contribution) => contribution.type !== null)
    .map((contribution) => {
      switch (contribution.type) {
        case BillingsCargoTaxiContributionDiscountType.CONTRACT_CORRECTION_PERCENTAGE:
          const percentage: BillingRecalculateRequestBillingContribution = {
            type: createRecalculateBillingContributionType(contribution.type!),
            percentage: contribution.percentage ?? 0,
          } as BillingRecalculateRequestBillingContribution;
          return percentage;

        case BillingsCargoTaxiContributionDiscountType.DISCOUNT_BEING_LATE:
        case BillingsCargoTaxiContributionDiscountType.DISCOUNT_INCOMPATIBLE_CAR:
        case BillingsCargoTaxiContributionDiscountType.DISCOUNT_OPTIMIZATION:
        case BillingsCargoTaxiContributionDiscountType.DISCOUNT_OTHER:
          const discount: BillingRecalculateRequestBillingContribution = {
            type: createRecalculateBillingContributionType(contribution.type!),
            comment: contribution.comment ?? "",
            amount: contribution.amount ?? 0,
          } as BillingRecalculateRequestBillingContribution;
          return discount;

        default:
          const empty: BillingRecalculateRequestBillingContribution = {
            type: null,
            comment: "",
            amount: 0,
            percentage: 0,
          };
          return empty;
      }
    });

  return [...discountContributions];
};

const createRecalculateRequest = (
  data: BillingFormData
): BillingRecalculateRequest => {
  const result: BillingRecalculateRequest = {
    billing_nodes: createBilligNodes(data),
    discount: 0,
    fuel_additive_rate: 0,
    billing_contributions: createRecalculateBillingContributions(
      data.billingDiscountContributions
    ),
  };
  return result;
};

const createBilligNodes = (data: BillingFormData): BillingNodeApi[] => {
  return data.billingNodes.map((specificNode) => {
    const node: BillingNodeApi = {
      id: specificNode.id,
      node_id: specificNode.nodeId,
      order_id: specificNode.orderId,
      lat: specificNode.lat,
      lon: specificNode.lon,
      distance: specificNode.distance,
      description: specificNode.description,
      checkout_date: specificNode.checkoutDate?.toJSON() ?? null,
      checkout_event_id: specificNode.checkoutEventUuid,
      planned_date: specificNode.plannedDate?.toJSON() ?? null,
      halting_amount: specificNode.haltingAmount,
      halting_time: specificNode.haltingTime,
      allow_charge: specificNode.isHighwayAllowed,
      highway_charge: specificNode.highwayCharge,
      planned_distance: specificNode.plannedDistance,
      editable_coordinates: specificNode.isEditable,
    };
    return node;
  });
};

const billingRecalculateRequestFactory = {
  createRecalculateRequest,
};

export default billingRecalculateRequestFactory;
